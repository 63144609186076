import { stringify } from "qs";
import { decamelizeKeys } from "humps";

export interface IBase {
  cacheId?: string;
  page?: number;
  cabinClass?: string;
  flightType?: string;
  valid?: boolean;
  traveler?: { numberOfAdult?: number; agesOfChildrens?: number[] };
  minPrice?: number;
  maxPrice?: number;
  maxStop?: number | null;
  departureHour1?: { from: number; to: number };
  departureHour2?: { from: number; to: number };
  departureHour3?: { from: number; to: number };
  departureHour4?: { from: number; to: number };
  departureHour5?: { from: number; to: number };
  lastDepartureHour?: { from: number; to: number };
  lastArrivalHour?: { from: number; to: number };
  minLayover?: number | null;
  maxLayover?: number | null;
  airlineCodes?: any[];
  airports?: any[];
  departureAirportCodes?: any[];
  transitAirportCodes?: any[];
  sortBy?: string;
  searchType?: string;
  dynamicPackageId?: string | null;
  searchGroupId?: string | null;
  lId?: string | null;
  qCId?: string | null;
  freeBaggageType?: string;
  notUseLcc?: boolean;
  dynamicPackagePreReservationId?: string | null;
  baggageAlert?: boolean;
}

export const defaultBase: IBase = {
  cacheId: "",
  page: 1,
  cabinClass: "",
  flightType: "",
  valid: false,
  traveler: {},
  minPrice: 0,
  maxPrice: 0,
  maxStop: null,
  departureHour1: null,
  departureHour2: null,
  departureHour3: null,
  departureHour4: null,
  departureHour5: null,
  lastDepartureHour: null,
  lastArrivalHour: null,
  minLayover: null,
  maxLayover: null,
  airlineCodes: [],
  airports: [],
  departureAirportCodes: [],
  transitAirportCodes: [],
  sortBy: "",
  searchType: "",
  dynamicPackageId: null,
  searchGroupId: null,
  lId: null,
  qCId: null,
  freeBaggageType: "",
  notUseLcc: false,
  dynamicPackagePreReservationId: null,
  baggageAlert: false,
};

const expirationSecond = 1800;

export class Base implements IBase {
  public cacheId: string;
  public page: number;
  public cabinClass: string;
  public flightType: string;
  public traveler: { numberOfAdult?: number; agesOfChildren: number[] };
  public valid: boolean;
  public minPrice: number;
  public maxPrice: number;
  public maxStop: number;
  public departureHour1: { from: number; to: number };
  public departureHour2: { from: number; to: number };
  public departureHour3: { from: number; to: number };
  public departureHour4: { from: number; to: number };
  public departureHour5: { from: number; to: number };
  public lastDepartureHour: { from: number; to: number };
  public lastArrivalHour: { from: number; to: number };
  public minLayover: number | null;
  public maxLayover: number | null;
  public airlineCodes: any[];
  public airports: any[];
  public departureAirportCodes: any[];
  public transitAirportCodes: any[];
  public sortBy: string;
  public searchType: string;
  public dynamicPackageId: string | null;
  public searchGroupId: string | null;
  public lId: string | null;
  public qCId: string | null;
  public freeBaggageType: string;
  public notUseLcc: boolean;
  public dynamicPackagePreReservationId: string | null;
  public baggageAlert: boolean;

  constructor(param: IBase) {
    Object.assign(this, defaultBase, param);
  }

  public toParam(): any {
    const param = {
      cacheId: this.cacheId,
      page: this.page,
      cabinClass: this.cabinClass,
      flightType: this.flightType,
      traveler: this.toTravelersParam(),
      searchType: this.searchType,
      dynamicPackageId: this.dynamicPackageId || null,
      searchGroupId: this.searchGroupId || null,
      lId: this.lId,
      qCId: this.qCId,
      dynamicPackagePreReservationId: this.dynamicPackagePreReservationId,
    };
    if (this.baggageAlert) {
      param["baggageAlert"] = this.baggageAlert;
    }
    return param;
  }
  public toTravelersParam(): any {
    if (this.traveler && this.traveler.agesOfChildren) {
      return {
        ...this.traveler,
        numberOfChildren:
          this.traveler.agesOfChildren.length > 0
            ? this.traveler.agesOfChildren.length
            : 0,
      };
    } else {
      return this.traveler;
    }
  }
  public toAdvancedParam(): any {
    return decamelizeKeys({
      minPrice: this.minPrice,
      maxPrice: this.maxPrice,
      maxStop: this.maxStop,
      departureHour1: this.departureHour1,
      departureHour2: this.departureHour2,
      departureHour3: this.departureHour3,
      departureHour4: this.departureHour4,
      departureHour5: this.departureHour5,
      lastDepartureHour: this.lastDepartureHour,
      lastArrivalHour: this.lastArrivalHour,
      minLayover: this.minLayover,
      maxLayover: this.maxLayover,
      airlineCodes: this.airlineCodes,
      departureAirportCodes: this.departureAirportCodes,
      transitAirportCodes: this.transitAirportCodes,
      sortBy: this.sortBy,
      freeBaggageType: this.freeBaggageType,
      notUseLcc: this.notUseLcc,
      lId: this.lId,
      qCId: this.qCId,
    });
  }

  public toUrlParam(): string {
    return stringify(decamelizeKeys(this.toParam()), {
      arrayFormat: "indices",
    });
  }

  public isExpired(): boolean {
    const cacheTimeString: string | undefined = this.cacheId.split("-").pop();
    if (cacheTimeString) {
      const cacheTime: number = parseInt(cacheTimeString, 10);
      if ((cacheTime + expirationSecond) * 1000 < new Date().getTime()) {
        return true;
      }
    }
    return false;
  }

  public itineraryCount(): number {
    return 1;
  }

  public toShortCityName(name: string): string {
    return name.replace(/ \(.*$/, "");
  }

  public shortOrigin(): string {
    return "";
  }

  public shortDestination(): string {
    return "";
  }

  public isSelectionMode(): boolean {
    return !!this.lId;
  }
}
