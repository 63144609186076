export interface ISpace {
  atom0_5x: string;
  atom: string;
  atom1_5x: string;
  atom2x: string;
  atom2_5x: string;
  atom3x: string;
  atom4x: string;
  block: string;
  block2x: string;
}

const baseSpace = 0.5; // 8sp(px)

export const space: ISpace = {
  atom0_5x: `${baseSpace / 2}rem`,
  atom: `${baseSpace}rem`,
  atom1_5x: `${(baseSpace / 2) * 3}rem`,
  atom2x: `${baseSpace * 2}rem`,
  atom2_5x: `${(baseSpace / 2) * 5}rem`,
  atom3x: `${baseSpace * 3}rem`,
  atom4x: `${baseSpace * 4}rem`,
  block: `${baseSpace}rem`,
  block2x: `${baseSpace * 2}rem`,
};

export const minHeaderFooterHeightStyle = "482px";
