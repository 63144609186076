import { Action } from "redux";
import {
  INIT_HOTELS,
  SET_HOTEL_ROOMS,
  SET_HOTEL_IMAGE,
  POLLING_HOTELS_SUCCESS,
  INIT_HOTEL,
  SET_IS_HOTEL_SHOW_MAP_VIEW,
  POLLING_HOTELS_FAILURE,
  POLLING_HOTELS_REQUEST,
  POLLING_HOTELS_FOR_MAP_REQUEST,
  POLLING_HOTELS_FOR_MAP_FAILURE,
  POLLING_HOTELS_FOR_MAP_SUCCESS,
  SET_HOTEL_ROOM_MODAL_IMAGES,
  SET_DISPLAY_HOTEL_IMAGE_MODAL,
  SET_DISPLAY_HOTEL_ROOM_IMAGE_MODAL,
  SET_HOTEL_MAP_COORDINATES,
  SET_DISPLAY_HOTEL_SEARCH_FORM,
  SET_DISPLAY_HOTEL_ROOM_DETAIL_MODAL,
  SET_HOTEL_ROOM_MODAL_DETAILS,
  SET_IS_LOADING_ROOM,
  SET_REQUEST_ROOM_ERROR,
} from "main/javascripts/constants/actionType/HotelActionType";
import { Region } from "main/javascripts/models/Region";

const MAX_UPDATED_HOTEL_COUNT: number =
  process.env.NODE_ENV === "production" ? 60 : 60;

export interface IHotelState {
  hotelId: number;
  hotelIds: number[];
  page: number;
  numPerPage: number;
  totalNum: number;
  updatedHotelCount: number;
  isPolling: boolean;
  descendingRegions: Region[];

  isShowMapView: boolean;
  hotelIdsForMap: number[];
  isPollingForMap: boolean;
  totalNumForMap: number;
  mapCenterCoordinates: any;
  isDisplayedSearchForm: boolean;

  isDisplayedImageModal: boolean;
  isDisplayedRoomImageModal: boolean;
  roomImageModalData: any;
  isDisplayedRoomDetailModal: boolean;
  roomDetailModalData: any;
  isLoadingRoom: boolean;
  requestRoomError: any;

  hotel: any;
  hotelForMap: any;
  image: any;
}

interface IInitializingHotelAction extends Action {
  actionType: string;
  payload: any;
}

export type HotelActions = IInitializingHotelAction;

const INITIAL_STATE: IHotelState = {
  hotelId: 0,
  hotelIds: [],
  page: 0,
  numPerPage: 0,
  totalNum: 0,
  updatedHotelCount: 0,
  isPolling: false,
  descendingRegions: [],

  isShowMapView: false,
  hotelIdsForMap: [],
  isPollingForMap: false,
  totalNumForMap: 0,
  mapCenterCoordinates: {},
  isDisplayedSearchForm: false,

  isDisplayedImageModal: false,
  isDisplayedRoomImageModal: false,
  roomImageModalData: {},
  isDisplayedRoomDetailModal: false,
  roomDetailModalData: {},
  isLoadingRoom: false,
  requestRoomError: null,

  // normalizr
  hotel: {},
  hotelForMap: {},
  image: {},
};

export function hotelReducer(
  state: IHotelState = INITIAL_STATE,
  action: HotelActions
): IHotelState {
  switch (action.type) {
    case INIT_HOTEL:
      return {
        ...state,
        ...action.payload.entities,
        hotelId: action.payload.result,
      };
    case POLLING_HOTELS_REQUEST:
      return {
        ...state,
        isPolling: true,
        page: action.payload.page,
        updatedHotelCount: 0,
      };
    case POLLING_HOTELS_FAILURE:
      return {
        ...state,
        isPolling: false,
        updatedHotelCount: 0,
      };
    case POLLING_HOTELS_SUCCESS: {
      const updatedHotelCount: number =
        state.updatedHotelCount > MAX_UPDATED_HOTEL_COUNT
          ? 0
          : state.updatedHotelCount + 1;
      const isPolling: boolean =
        updatedHotelCount === 0 ? false : action.payload.result.isPolling;
      return {
        ...state,
        ...action.payload.entities,
        hotelIds: action.payload.result.hotels,
        isPolling: isPolling,
        totalNum: action.payload.result.totalNum,
        updatedHotelCount: updatedHotelCount,
        descendingRegions: action.payload.result.descendingRegions,
      };
    }
    case INIT_HOTELS:
      return {
        ...state,
        ...action.payload.entities,
        hotelIds: action.payload.result.hotels,
        page: action.payload.result.page,
        numPerPage: action.payload.result.numPerPage,
        totalNum: action.payload.result.totalNum,
      };
    case SET_HOTEL_ROOMS:
      Object.keys(action.payload).map((key: any) => {
        state.hotel[key].hotelRooms = action.payload[key];
      });
      return {
        ...state,
        hotel: state.hotel,
      };
    case SET_HOTEL_IMAGE:
      Object.keys(action.payload).map((key: any) => {
        state.image[key] = action.payload[key];
      });
      return {
        ...state,
        image: state.image,
      };
    case SET_IS_HOTEL_SHOW_MAP_VIEW:
      return { ...state, isShowMapView: action.payload.showMapView };
    case POLLING_HOTELS_FOR_MAP_REQUEST:
      return { ...state, isPollingForMap: true };
    case POLLING_HOTELS_FOR_MAP_FAILURE:
      return { ...state, isPollingForMap: false };
    case POLLING_HOTELS_FOR_MAP_SUCCESS:
      return {
        ...state,
        ...action.payload.entities,
        hotelIdsForMap: action.payload.result.hotels,
        isPollingForMap: action.payload.result.isPolling,
        totalNumForMap: action.payload.result.totalNum,
      };
    case SET_HOTEL_MAP_COORDINATES:
      return { ...state, mapCenterCoordinates: action.payload };
    case SET_DISPLAY_HOTEL_SEARCH_FORM:
      return { ...state, isDisplayedSearchForm: action.payload };
    case SET_DISPLAY_HOTEL_IMAGE_MODAL:
      return { ...state, isDisplayedImageModal: action.payload };
    case SET_DISPLAY_HOTEL_ROOM_IMAGE_MODAL:
      return { ...state, isDisplayedRoomImageModal: action.payload };
    case SET_HOTEL_ROOM_MODAL_IMAGES:
      return { ...state, roomImageModalData: { images: action.payload } };
    case SET_DISPLAY_HOTEL_ROOM_DETAIL_MODAL:
      return { ...state, isDisplayedRoomDetailModal: action.payload };
    case SET_HOTEL_ROOM_MODAL_DETAILS:
      return { ...state, roomDetailModalData: action.payload };
    case SET_IS_LOADING_ROOM:
      return { ...state, isLoadingRoom: action.payload };
    case SET_REQUEST_ROOM_ERROR:
      return { ...state, requestRoomError: action.payload };
    default:
      return state;
  }
}
