import { Action } from "redux";
import { INIT_CHARGE_INFO } from "main/javascripts/constants/actionType/ChargeInfoActionType";
import { Cost } from "main/javascripts/models/Cost";

export interface IChargeInfoState {
  costs: Cost[];
}

interface IInitializingChargeInfoAction extends Action {
  actionType: string;
  payload: any;
}

export type ChargeInfoActions = IInitializingChargeInfoAction;

const INITIAL_STATE: IChargeInfoState = {
  costs: [],
};

export function chargeInfoReducer(
  state: IChargeInfoState = INITIAL_STATE,
  action: ChargeInfoActions
): IChargeInfoState {
  switch (action.type) {
    case INIT_CHARGE_INFO:
      return {
        ...state,
        ...action.payload.entities,
        costs:
          action.payload && action.payload.map((cost: any) => new Cost(cost)),
      };
    default:
      return state;
  }
}
