export interface IAccentColor {
  primaryColor: string;
  primaryDarkColor: string;
  primaryLightColor: string;
  primaryColor2: string;
  secondaryColor: string;
  secondaryDarkColor: string;
  secondaryLightColor: string;
  secondaryColor2: string;
  alertColor: string;
  starColor: string;
  lineColor: string;
  fbColor: string;
}

export const accentColor: IAccentColor = process.env.HOSTING_DOMAIN
  ? {
      primaryColor: "#2979C6",
      primaryDarkColor: "#0865B2",
      primaryLightColor: "#4596D8",
      primaryColor2: "#FFE576",
      secondaryColor: "#FA7A1B",
      secondaryDarkColor: "#D85700",
      secondaryLightColor: "#FCA265",
      secondaryColor2: "#163056",
      alertColor: "#f70000",
      starColor: "#fac83c",
      lineColor: "#06c755",
      fbColor: "#3B5998",
    }
  : {
      primaryColor: "#FC634E",
      primaryDarkColor: "#ef402e",
      primaryLightColor: "#f9796b",
      primaryColor2: "#FCBB4E",
      secondaryColor: "#FC814E",
      secondaryDarkColor: "#be2a24",
      secondaryLightColor: "#ff917a",
      secondaryColor2: "#000000",
      alertColor: "#f70000",
      starColor: "#FCBB4E",
      lineColor: "#06C755",
      fbColor: "#0866FF",
    };

export interface ITextColor {
  primaryDarkColor: string;
  secondaryDarkColor: string;
  disabledColor: string;
  primaryAccentColor: string;
  secondaryAccentColor: string;
}

export const textColor: ITextColor = {
  primaryDarkColor: "rgba(0, 0, 0, 1)",
  secondaryDarkColor: "rgba(45, 45, 45, 0.74)",
  disabledColor: "rgba(45, 45, 45, 0.38)",
  primaryAccentColor: "#FC634E",
  secondaryAccentColor: "#4E95FC",
};

export interface IBackgroundColor {
  primaryDarkColor: string;
  primaryLightColor: string;
  secondaryLightColor: string;
  subsectionColor: string;
  highlightColor: string;
  ground: string;
}

export const backgroundColor: IBackgroundColor = process.env.HOSTING_DOMAIN
  ? {
      primaryDarkColor: "#163056",
      primaryLightColor: "#FFFFFF",
      secondaryLightColor: "#F5F5F5",
      subsectionColor: "#F9F9F9",
      highlightColor: "#F9F9F9",
      ground: "#F5F5F5",
    }
  : {
      primaryDarkColor: "#000000",
      primaryLightColor: "#FFFFFF",
      secondaryLightColor: "#F8F8F8",
      subsectionColor: "#F9F9F9",
      highlightColor: "#FCCCA2",
      ground: "#F8F8F8",
    };

export interface IBorderColor {
  primaryDarkColor: string;
  primaryLightColor: string;
  secondaryLightColor: string;
  shadowColor: string;
}

export const borderColor: IBorderColor = {
  primaryDarkColor: "#AAAAAA",
  primaryLightColor: "#EEEEEE",
  secondaryLightColor: "#DDDDDD",
  shadowColor: "#EEEEEE",
};

export interface IShadowColor {
  soft: string;
}

export const shadowColor: IShadowColor = process.env.HOSTING_DOMAIN
  ? {
      soft: "rgba(41, 121, 198, 0.2)",
    }
  : {
      soft: "rgba(0, 0, 0, 0.06)",
    };

export interface IPostTextColor {
  red: string;
  blue: string;
  green: string;
}

export const postTextColor: IPostTextColor = process.env.HOSTING_DOMAIN
  ? {
      red: accentColor.secondaryColor,
      blue: accentColor.primaryColor,
      green: "#08824a",
    }
  : {
      red: accentColor.secondaryColor,
      blue: accentColor.primaryColor,
      green: "#148a6f",
    };

export function shadeColor(color: string, percent: number): string {
  let R: number = parseInt(color.substring(1, 3), 16);
  let G: number = parseInt(color.substring(3, 5), 16);
  let B: number = parseInt(color.substring(5, 7), 16);

  const maxPer = 100;
  const maxVal = 255;

  R = Math.floor((R * (maxPer + percent)) / maxPer);
  G = Math.floor((G * (maxPer + percent)) / maxPer);
  B = Math.floor((B * (maxPer + percent)) / maxPer);

  R = R < maxVal ? R : maxVal;
  G = G < maxVal ? G : maxVal;
  B = B < maxVal ? B : maxVal;

  const RR: string =
    R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
  const GG: string =
    G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
  const BB: string =
    B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);

  return `#${RR}${GG}${BB}`;
}

export const hex2rgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};
export const shadeColorLogHex = (hex, alpha, p) => {
  return shadeColorLog(hex2rgba(hex, alpha), p);
};

// https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)
export const shadeColorLog = (rgb, p) => {
  const i = parseInt;
  const r = Math.round;
  const [a, b, c, d] = rgb.split(",");
  const t = p < 0 ? 0 : p * 255 ** 2;
  const P = p < 0 ? 1 + p : 1 - p;
  return (
    "rgb" +
    (d ? "a(" : "(") +
    r((P * i(a[3] == "a" ? a.slice(5) : a.slice(4)) ** 2 + t) ** 0.5) +
    "," +
    r((P * i(b) ** 2 + t) ** 0.5) +
    "," +
    r((P * i(c) ** 2 + t) ** 0.5) +
    (d ? "," + d : ")")
  );
};
