import * as React from "react";
import { css } from "aphrodite";
import { RangeSliderStyle } from "./RangeSliderStyle";
import { RangeSliderHandle } from "main/javascripts/components/atoms/Form/RangeSlider/RangeSliderHandle";

export const RangeSliderOnChangeLeft = "RangeSliderOnChangeLeft";
export const RangeSliderOnChangeRight = "RangeSliderOnChangeRight";

interface IState {
  x_start: number;
  x_end: number;
  x_left: number;
  x_right: number;
}

interface IProps {
  input: any;
  dataNum: number;
  initialLeft: number;
  initialRight: number;
  onChange(direction: string, value: any): void;
  onClickEnd?: any;
  styles?: any;
}

export class RangeSlider extends React.Component<IProps, IState> {
  public state: IState = {
    x_start: 0,
    x_end: 0,
    x_left: 0,
    x_right: 1,
  };
  public innerElm: any;
  public componentDidMount(): void {
    this.setState({
      x_left: this.props.initialLeft,
      x_right: this.props.initialRight,
    });

    // css適用後にサイズ取得するために遅延させる
    setTimeout(() => {
      this.getSliderPosition();
      window.addEventListener("resize", this.onResizeWindow);
    }, 0);
  }
  public componentDidUpdate(prevProps: any): void {
    if (
      prevProps.initialLeft !== this.props.initialLeft ||
      prevProps.initialRight !== this.props.initialRight
    ) {
      this.setState({
        x_left:
          prevProps.initialLeft !== this.props.initialLeft
            ? this.props.initialLeft
            : this.state.x_left,
        x_right:
          prevProps.initialRight !== this.props.initialRight
            ? this.props.initialRight
            : this.state.x_right,
      });
    }
  }
  public componentWillUnmount(): void {
    this.innerElm = null;
    window.removeEventListener("resize", this.onResizeWindow);
  }
  public getSliderPosition(): void {
    if (this.innerElm) {
      this.setState({
        x_start: 0,
        x_end: this.innerElm.clientWidth,
      });
    }
  }
  public setInnerElement: (node: any) => void = (node: any) => {
    if (node) {
      this.innerElm = node;
    }
  };
  private onMoveLeft: (x: number) => void = (x: number) => {
    const bounds: any = this.innerElm.getBoundingClientRect();
    const relativeX: number = x - bounds.left;
    let posRatio: number =
      (relativeX - this.state.x_start) /
      (this.state.x_end - this.state.x_start);
    const maxPos: number = this.state.x_right - 1 / this.props.dataNum;
    posRatio = posRatio > 0 ? posRatio : 0;
    posRatio = posRatio < maxPos ? posRatio : maxPos;
    this.setState({
      x_left: posRatio,
    });
    this.props.onChange(RangeSliderOnChangeLeft, posRatio);
  };
  private onMoveRight: (x: any) => void = (x: any) => {
    const bounds: any = this.innerElm.getBoundingClientRect();
    const relativeX: number = x - bounds.left;
    let posRatio: number =
      (relativeX - this.state.x_start) /
      (this.state.x_end - this.state.x_start);
    const minPos: number = this.state.x_left + 1 / this.props.dataNum;
    posRatio = posRatio > minPos ? posRatio : minPos;
    posRatio = posRatio < 1 ? posRatio : 0.9999; // 1だと配列数の最大を超えるため制限している
    this.setState({
      x_right: posRatio,
    });
    this.props.onChange(RangeSliderOnChangeRight, posRatio);
  };

  private onResizeWindow: () => void = () => {
    this.getSliderPosition();
  };

  private onClickEndLeft: () => void = () => {
    if (this.props.onClickEnd) {
      this.props.onClickEnd(RangeSliderOnChangeLeft);
    }
  };

  private onClickEndRight: () => void = () => {
    if (this.props.onClickEnd) {
      this.props.onClickEnd(RangeSliderOnChangeRight);
    }
  };

  public render(): JSX.Element {
    const { styles = {} } = this.props;
    const { block, bar, handleLeft, handleRight } = styles;
    const barWidthPer: number = this.state.x_right - this.state.x_left;
    return (
      <div className={css(RangeSliderStyle.block, block)}>
        <div className={css(RangeSliderStyle.inner)} ref={this.setInnerElement}>
          <div
            className={css(RangeSliderStyle.bar, bar)}
            style={{
              transform: `translateX(${
                this.state.x_left * 100
              }%) scaleX(${barWidthPer})`,
            }}
          />
          <RangeSliderHandle
            key="left"
            styles={[handleLeft]}
            x={this.state.x_left}
            position="left"
            onMove={this.onMoveLeft}
            onClickEnd={this.onClickEndLeft}
          />
          <RangeSliderHandle
            key="right"
            styles={[handleRight]}
            x={this.state.x_right}
            position="right"
            onMove={this.onMoveRight}
            onClickEnd={this.onClickEndRight}
          />
        </div>
      </div>
    );
  }
}
