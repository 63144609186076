export interface ICost {
  serviceType: string;
  priceCents: number;
  priceCurrency: string;
  feeCents: number;
  feeCurrency: string;
}

const defaultParam: ICost = {
  serviceType: "",
  priceCents: 0,
  priceCurrency: "",
  feeCents: 0,
  feeCurrency: "",
};

export class Cost implements ICost {
  public serviceType: string;
  public priceCents: number;
  public priceCurrency: string;
  public feeCents: number;
  public feeCurrency: string;

  constructor(param: ICost) {
    Object.assign(this, defaultParam, param);
  }
}
