import { Action } from "redux";
import { INIT_EDITORS } from "main/javascripts/constants/actionType/EditorActionType";

export interface IEditorState {
  editorIds: number[];
  editor: any;
}

interface IInitializingEditorAction extends Action {
  actionType: string;
  payload: any;
}

export type EditorActions = IInitializingEditorAction;

const INITIAL_STATE: IEditorState = {
  editorIds: [],

  // normalizr
  editor: {},
};

export function editorReducer(
  state: IEditorState = INITIAL_STATE,
  action: EditorActions
): IEditorState {
  switch (action.type) {
    case INIT_EDITORS:
      return {
        ...state,
        ...action.payload.entities,
        editorIds: action.payload.result.editors,
      };
    default:
      return state;
  }
}
