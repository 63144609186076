import { createWrapper } from "./AxiosWrapper";

export async function createLineUserSignIn(
  params: any,
  option: any = {}
): Promise<any> {
  const { accessToken } = params;
  return createWrapper(option)
    .post("/api/line/users/sign_in.json", { access_token: accessToken })
    .then(async (response: any): Promise<any> => {
      return Promise.resolve({
        data: response.data,
      });
    })
    .catch(async (error: any): Promise<any> => {
      return Promise.reject({
        errors: error.resonse.data.errors,
        errorCode: error.response.data.error_code,
        errorMessages: error.response.data.error_messages,
      });
    });
}
