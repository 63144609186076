import { stringify } from "qs";
import { decamelizeKeys } from "humps";

export interface IHotelIndexParam {
  destination?: string;
  destinationId?: number;
  destinationType?: string;
  checkinDate?: string;
  checkoutDate?: string;
  cacheId?: string;
  valid?: boolean;
  guests?: { numberOfAdult?: number; agesOfChildrens: number[] }[];
  minPrice?: number;
  maxPrice?: number;
  minRating?: number;
  rank?: number[];
  sortBy?: string;
  bedCount?: number;
  bedSizeTypes?: string[];
  mealType?: string;
  viewType?: string;
  descendingRegionIds?: any[];
  longitude?: number;
  latitude?: number;
  searchType?: string;
  dynamicPackageId?: string | null;
  searchGroupId?: string | null;
  lId?: string | null;
  qCId?: string | null;
  dynamicPackagePreReservationId?: string | null;
}

const defaultHotelIndexParam: IHotelIndexParam = {
  destination: "",
  destinationId: 0,
  destinationType: "",
  checkinDate: "",
  checkoutDate: "",
  cacheId: "",
  valid: false,
  guests: [],
  minPrice: 0,
  maxPrice: 0,
  minRating: 0,
  rank: [],
  sortBy: "recommended",
  bedCount: 0,
  bedSizeTypes: [],
  mealType: "",
  viewType: "",
  longitude: 0,
  latitude: 0,
  descendingRegionIds: [],
  searchType: "",
  dynamicPackageId: null,
  searchGroupId: null,
  lId: null,
  qCId: null,
  dynamicPackagePreReservationId: null,
};

export class HotelIndexParam implements IHotelIndexParam {
  public destination: string;
  public destinationId: number;
  public destinationType: string;
  public checkinDate: string;
  public checkoutDate: string;
  public cacheId: string;
  public guests: { numberOfAdult?: number; agesOfChildrens: number[] }[];
  public valid: boolean;
  public minPrice: number;
  public maxPrice: number;
  public minRating: number;
  public rank: number[];
  public sortBy: string;
  public bedCount: number;
  public bedSizeTypes: string[];
  public mealType: string;
  public viewType: string;
  public descendingRegionIds: any;
  public longitude: number;
  public latitude: number;
  public searchType: string;
  public dynamicPackageId: string;
  public searchGroupId: string;
  public lId: string | null;
  public qCId: string | null;
  public dynamicPackagePreReservationId: string | null;

  constructor(param: IHotelIndexParam) {
    const minPrice = Number(param.minPrice);
    const maxPrice = Number(param.maxPrice);
    Object.assign(this, defaultHotelIndexParam, param, {
      minPrice: minPrice ? minPrice : 0,
      maxPrice: maxPrice ? maxPrice : 0,
    });
  }

  public toUrlParam(): string {
    return stringify(this.toParam(), { arrayFormat: "indices" });
  }
  public toParam(): any {
    const guestsParam: any = this.toGuestsParam();
    return decamelizeKeys({
      destination: this.destination,
      destinationId: this.destinationId,
      destinationType: this.destinationType,
      checkinDate: this.checkinDate,
      checkoutDate: this.checkoutDate,
      cacheId: this.cacheId,
      valid: this.valid,
      longitude: this.longitude,
      latitude: this.latitude,
      guests: guestsParam,
      numberOfRooms: guestsParam.length,
      searchType: this.searchType,
      dynamicPackageId: this.dynamicPackageId,
      searchGroupId: this.searchGroupId,
      lId: this.lId,
      qCId: this.qCId,
      dynamicPackagePreReservationId: this.dynamicPackagePreReservationId,
    });
  }
  public toAdvancedParam(): any {
    return decamelizeKeys({
      minPrice: this.minPrice,
      maxPrice: this.maxPrice,
      minRating: this.minRating,
      rank: this.rank,
      sortBy: this.sortBy,
      bedCount: this.bedCount,
      bedSizeTypes: this.bedSizeTypes,
      mealType: this.mealType,
      viewType: this.viewType,
      descendingRegionIds: this.descendingRegionIds,
      lId: this.lId,
      qCId: this.qCId,
    });
  }
  private toGuestsParam(): any {
    const guests: any =
      this.guests.length > 0
        ? this.guests
        : [{ numberOfAdult: 2, agesOfChildren: [] }];
    return guests.map((guest: any) => {
      return {
        ...guest,
        numberOfChildren:
          guest.agesOfChildren.length > 0 ? guest.agesOfChildren.length : 0,
      };
    });
  }
  public totalNumberOfAdult(): number {
    let sum = 0;
    this.guests.forEach((guest: any) => {
      sum += Number(guest.numberOfAdult);
    });
    return sum;
  }
  public totalNumberOfChildren(): number {
    let sum = 0;
    this.guests.forEach((guest: any) => {
      sum += Number(guest.agesOfChildren ? guest.agesOfChildren.length : 0);
    });
    return sum;
  }
  public isSelectionMode(): boolean {
    return !!this.lId;
  }
}
