import {
  createAction,
  ActionFunction1,
  ActionMeta,
  Action,
} from "redux-actions";
import { FlightPreReservationSchema } from "main/javascripts/schemas/FlightPreReservationSchema";
import { IFlightPreReservation } from "main/javascripts/models/FlightPreReservation";
import {
  FETCH_FLIGHT_PRE_RESERVATION_FAILURE,
  FETCH_FLIGHT_PRE_RESERVATION_REQUEST,
  FETCH_FLIGHT_PRE_RESERVATION_SUCCESS,
  INIT_FLIGHT_PRE_RESERVATION,
  SET_DISPLAY_FLIGHT_PRE_RESERVATION_CONFIRMATION,
  UPDATE_FLIGHT_PRE_RESERVATION_PARAMETERS_REQUEST,
} from "main/javascripts/constants/actionType/FlightPreReservationActionType";

interface IInitFlightPreReservationMeta {
  schema: typeof FlightPreReservationSchema;
}
export const initFlightPreReservation: ActionFunction1<
  IFlightPreReservation,
  ActionMeta<IFlightPreReservation, IInitFlightPreReservationMeta>
> = createAction<
  IFlightPreReservation,
  IInitFlightPreReservationMeta,
  IFlightPreReservation
>(
  INIT_FLIGHT_PRE_RESERVATION,
  (flightPreReservation: IFlightPreReservation): IFlightPreReservation => {
    return flightPreReservation;
  },
  (): IInitFlightPreReservationMeta => {
    return {
      schema: FlightPreReservationSchema,
    };
  }
);

export const fetchFlightPreReservationRequest: any = createAction(
  FETCH_FLIGHT_PRE_RESERVATION_REQUEST
);
export const fetchFlightPreReservationFailure: any = createAction(
  FETCH_FLIGHT_PRE_RESERVATION_FAILURE
);
interface IFetchFlightPreReservationMeta {
  schema: typeof FlightPreReservationSchema;
}

export const fetchFlightPreReservationSuccess: ActionFunction1<
  IFlightPreReservation,
  ActionMeta<IFlightPreReservation, IFetchFlightPreReservationMeta>
> = createAction<
  IFlightPreReservation,
  IFetchFlightPreReservationMeta,
  IFlightPreReservation
>(
  FETCH_FLIGHT_PRE_RESERVATION_SUCCESS,
  (flightPreReservation: IFlightPreReservation): IFlightPreReservation => {
    return flightPreReservation;
  },
  (): IFetchFlightPreReservationMeta => {
    return {
      schema: FlightPreReservationSchema,
    };
  }
);

export const updateFlightPreReservationParametersRequest: any = createAction(
  UPDATE_FLIGHT_PRE_RESERVATION_PARAMETERS_REQUEST
);

export const setDisplayFlightPreReservationConfirmation: ActionFunction1<
  boolean,
  Action<boolean>
> = createAction<boolean, boolean>(
  SET_DISPLAY_FLIGHT_PRE_RESERVATION_CONFIRMATION,
  (param: boolean): boolean => {
    return param;
  }
);
