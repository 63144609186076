import { SagaIterator } from "redux-saga";
import { call, put, ForkEffect, takeLatest } from "redux-saga/effects";
import { IAction } from "main/javascripts/constants/type/ReduxActions";
import {
  FETCH_DYNAMIC_PACKAGE_PRE_RESERVATION_REQUEST,
  UPDATE_DYNAMIC_PACKAGE_PRE_RESERVATION_PARAMETERS_REQUEST,
} from "main/javascripts/constants/actionType/DynamicPackagePreReservationActionType";
import {
  fetchDynamicPackagePreReservation,
  updateDynamicPackagePreReservationParameters,
} from "main/javascripts/api/DynamicPackagePreReservationApi";
import {
  fetchDynamicPackagePreReservationSuccess,
  fetchDynamicPackagePreReservationFailure,
} from "main/javascripts/actions/DynamicPackagePreReservations";

function* runFetchDynamicPackagePreReservation(
  action: IAction<any>
): SagaIterator {
  try {
    const { data } = yield call(
      fetchDynamicPackagePreReservation,
      action.payload
    );
    yield put(fetchDynamicPackagePreReservationSuccess(data));
  } catch (e) {
    yield put(
      fetchDynamicPackagePreReservationFailure({
        errorMessages: e.errorMessages,
      })
    );
  }
}

function* runUpdateDynamicPackagePreReservationParameters(
  action: IAction<any>
): SagaIterator {
  try {
    const { data } = yield call(
      updateDynamicPackagePreReservationParameters,
      action.payload.dynamicPackagePreReservationId,
      action.payload.flightPreReservationId,
      action.payload.param
    );
    yield put(fetchDynamicPackagePreReservationSuccess(data));
  } catch (e) {
    yield put(
      fetchDynamicPackagePreReservationFailure({
        errorMessages: e.errorMessages,
      })
    );
  }
}

export const dynamicPackagePreReservationSaga: ForkEffect[] = [
  takeLatest(
    FETCH_DYNAMIC_PACKAGE_PRE_RESERVATION_REQUEST,
    runFetchDynamicPackagePreReservation
  ),
  takeLatest(
    UPDATE_DYNAMIC_PACKAGE_PRE_RESERVATION_PARAMETERS_REQUEST,
    runUpdateDynamicPackagePreReservationParameters
  ),
];
