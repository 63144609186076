import { StyleSheet } from "aphrodite";
import {
  fontSize,
  lineHeight,
  fontWeight,
} from "main/javascripts/styles/base/typographyStyle";
import { accentColor } from "main/javascripts/styles/base/colorStyle";
import { space } from "main/javascripts/styles/base/spaceStyle";

export interface IStyle {
  inputError: any;
}

export const InputErrorStyle: IStyle = StyleSheet.create({
  inputError: {
    fontSize: fontSize.caption,
    lineHeight: lineHeight.caption3,
    fontWeight: fontWeight.bold,
    color: accentColor.alertColor,
    display: "inline-block",
    padding: `${space.atom} 0`, // 左右のpaddingは不要
    margin: 0,
  },
});
