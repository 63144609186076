import * as React from "react";
import { useTranslation } from "react-i18next";
import { css } from "aphrodite";
import { LabelStyle } from "./LabelStyle";

export interface ILabelProps {
  children: React.ReactNode;
  isRequired?: boolean;
  styles?: any;
}

export const Label: React.FC<ILabelProps> = (
  props: ILabelProps
): React.ReactElement<ILabelProps> => {
  const { children, isRequired, styles } = props;
  const { t } = useTranslation(["label"]);
  return (
    <label className={css(LabelStyle.label, styles)}>
      {children}
      {isRequired && (
        <span className={css(LabelStyle.required)}>{t("common.required")}</span>
      )}
    </label>
  );
};
