export function setCookie(name, value, days) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getCookie(name: string): string | null {
  const cookies = document.cookie.split("; ");
  const cookiePair = cookies.find((cookie) => cookie.startsWith(`${name}=`));

  if (cookiePair) {
    return cookiePair.split("=")[1];
  } else {
    return null;
  }
}
