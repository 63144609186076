import { createSlice, createSelector } from "@reduxjs/toolkit";
import { SearchPage } from "main/javascripts/types/searchPage";
import { searchCategories } from "main/javascripts/constants/SearchCategories";

const key = "searchPage";

const initialState: SearchPage = {
  searchCategory: searchCategories.hotel,
};

/** slice **/
export const searchPageSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    changeSearchCategory: (state, action) => {
      state.searchCategory = action.payload;
    },
  },
  // extraReducers: (builder) => {},
});

/** selector **/
const stateSelector = (state: { [key]: SearchPage }) => state[key];

export const searchCategorySelector = createSelector(
  stateSelector,
  (state) => state.searchCategory
);

/** action export **/
export const { changeSearchCategory } = searchPageSlice.actions;
