import * as React from "react";
import { css } from "aphrodite";
import { Field } from "react-final-form";
import { HotelPriceFilterBlockStyle } from "./HotelPriceFilterBlockStyle";
import { RangeSliderBlock } from "../RangeSliderBlock";
import { RangeSliderOnChangeLeft } from "main/javascripts/components/atoms/Form/RangeSlider/RangeSlider";

export interface IProps {
  values: any;
  initialPriceLeft: number | string;
  initialPriceRight: number | string;
  change(name: string, value: any): void;
  onClickEnd?: any;
  styles?: any;
}

interface IState {
  minPrice: number | string;
  maxPrice: number | string;
}

const minPrice = 1000;
const maxPrice = 30000;
const deltaPrice = 1000;
export const initialMinPrice: number | string = "min-unlimited";
export const initialMaxPrice: number | string = "max-unlimited";

const getSliderData: any = (min: number, max: number, delta: number): any => {
  const dataArray: any[] = [];
  let val: number = min;
  dataArray.push("min-unlimited");
  while (val < max) {
    dataArray.push(val);
    val += delta;
  }
  dataArray.push(max);
  dataArray.push("max-unlimited");
  return dataArray;
};
const data: any = getSliderData(minPrice, maxPrice, deltaPrice);

export class HotelPriceFilterBlock extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      minPrice:
        props.values && props.values.min_price
          ? props.values.min_price
          : props.initialPriceLeft,
      maxPrice:
        props.values && props.values.max_price
          ? props.values.max_price
          : props.initialPriceRight,
    };
  }
  public componentDidUpdate(prevProps: any): void {
    if (
      this.props.values &&
      (!prevProps.values ||
        prevProps.values.min_price !== this.props.values.min_price ||
        prevProps.values.max_price !== this.props.values.max_price)
    ) {
      this.setState({
        minPrice: this.props.values.min_price || this.props.initialPriceLeft,
        maxPrice: this.props.values.max_price || this.props.initialPriceRight,
      });
    }
  }
  private formattedPrice(price: number | string): string {
    return price && typeof price === "number"
      ? `${price.toString().replace(/(\d)(?=(\d{3})+$)/g, "$1,")}円`
      : "未設定";
  }
  private onChangePrice: (direction: string, posRatio: any) => any = (
    direction: string,
    posRatio: any
  ) => {
    const price: number | string =
      data[Math.floor(posRatio / (1 / data.length))];
    if (direction === RangeSliderOnChangeLeft) {
      this.setState({ minPrice: price });
    } else {
      this.setState({ maxPrice: price });
    }
  };
  private onClickEnd: (direction: string) => any = (direction: string) => {
    if (direction === RangeSliderOnChangeLeft) {
      this.props.change("min_price", this.state.minPrice);
    } else {
      this.props.change("max_price", this.state.maxPrice);
    }
    if (this.props.onClickEnd) {
      this.props.onClickEnd();
    }
  };
  private renderRangeSlider: (props: any) => JSX.Element = ({
    input,
    meta,
  }: any) => {
    const { values, initialPriceLeft, initialPriceRight } = this.props;
    let initialLeftValue: number | string = values.min_price
      ? values.min_price
      : initialPriceLeft;
    let initialRightValue: number | string = values.max_price
      ? values.max_price
      : initialPriceRight;
    initialLeftValue = Number(initialLeftValue)
      ? Number(initialLeftValue)
      : initialLeftValue;
    initialRightValue = Number(initialRightValue)
      ? Number(initialRightValue)
      : initialRightValue;
    const initialLeft: number = data.indexOf(initialLeftValue) / data.length;
    const initialRight: number =
      data.indexOf(initialRightValue) / data.length + 0.999 / data.length;
    const labelText = "1名1泊あたりの価格";
    return (
      <RangeSliderBlock
        label={{
          children: labelText,
        }}
        input={{
          ...input,
        }}
        initialLeft={initialLeft}
        initialRight={initialRight}
        dataNum={data.length}
        inputError={{
          meta: meta,
        }}
        valLeft={this.formattedPrice(this.state.minPrice || initialLeftValue)}
        valRight={this.formattedPrice(this.state.maxPrice || initialRightValue)}
        onChange={this.onChangePrice}
        onClickEnd={this.onClickEnd}
        styles={{
          handleLeft: HotelPriceFilterBlockStyle.handleLeft,
          handleRight: HotelPriceFilterBlockStyle.handleRight,
        }}
      />
    );
  };
  public render(): JSX.Element {
    return (
      <div className={css(HotelPriceFilterBlockStyle.block)}>
        <Field name="min_price" component="input" type="hidden" />
        <Field name="max_price" component="input" type="hidden" />
        <Field
          name="prices"
          subscription={{ error: true }} // エラー専用
          component={this.renderRangeSlider}
        />
      </div>
    );
  }
}
