import { Action } from "redux";
import {
  INIT_ACTIVITY,
  SET_ACTIVITY_IMAGE,
} from "main/javascripts/constants/actionType/ActivityActionType";

export interface IActivityState {
  activityId: number;
  activity: any;
  image: any;
}

interface IInitializingHotelAction extends Action {
  actionType: string;
  payload: any;
}

export type HotelActions = IInitializingHotelAction;

const INITIAL_STATE: IActivityState = {
  activityId: 0,

  // normalizr
  activity: {},
  image: {},
};

export function activityReducer(
  state: IActivityState = INITIAL_STATE,
  action: HotelActions
): IActivityState {
  switch (action.type) {
    case INIT_ACTIVITY:
      return {
        ...state,
        ...action.payload.entities,
        activityId: action.payload.result,
      };
    case SET_ACTIVITY_IMAGE:
      Object.keys(action.payload).map((key: any) => {
        state.image[key] = action.payload[key];
      });
      return {
        ...state,
        image: state.image,
      };
    default:
      return state;
  }
}
