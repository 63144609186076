import { schema } from "normalizr";
import { HotelArrangementPlanSchema } from "./HotelArrangementPlanSchema";

export const HotelArrangementSchema: schema.Entity = new schema.Entity(
  "hotelArrangement",
  {
    hotelArrangementPlans: [HotelArrangementPlanSchema],
  }
);

export const HotelArrangementsSchema: schema.Object = new schema.Object({
  hotelArrangements: [HotelArrangementSchema],
});
