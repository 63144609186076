import { Action } from "redux";
import {
  POLLING_FLIGHTS_FAILURE,
  POLLING_FLIGHTS_REQUEST,
  SET_DISPLAY_FLIGHT_SEARCH_FORM,
} from "../constants/actionType/FlightActionType";
import {
  INIT_FLIGHTS,
  POLLING_FLIGHTS_SUCCESS,
} from "main/javascripts/constants/actionType/FlightActionType";

const MAX_UPDATED_FLIGHT_COUNT: number =
  process.env.NODE_ENV === "production" ? 180 : 180;

export interface IFlightState {
  flightIds: number[];
  page: number;
  numPerPage: number;
  totalNum: number;
  updatedFlightCount: number;
  isPolling: boolean;
  isDisplayedSearchForm: boolean;
  airlines: { code: string; name: string }[];
  airports: { code: string; name: string }[];
  departureAirports: { code: string; name: string }[];
  transitAirports: { code: string; name: string }[];
  maxStop: number;
  maxTotalPriceCents: number;
  minTotalPriceCents: number;

  flight: any;
}

interface IInitializingFlightAction extends Action {
  actionType: string;
  payload: any;
}

export type FlightActions = IInitializingFlightAction;

const INITIAL_STATE: IFlightState = {
  flightIds: [],
  page: 0,
  numPerPage: 0,
  totalNum: 0,
  updatedFlightCount: 0,
  isPolling: false,
  isDisplayedSearchForm: false,
  airlines: [],
  airports: [],
  departureAirports: [],
  transitAirports: [],
  maxStop: 0,
  maxTotalPriceCents: 0,
  minTotalPriceCents: 0,

  // normalizr
  flight: {},
};

export function flightReducer(
  state: IFlightState = INITIAL_STATE,
  action: FlightActions
): IFlightState {
  switch (action.type) {
    case POLLING_FLIGHTS_REQUEST:
      return {
        ...state,
        isPolling: true,
        page: action.payload.page,
        updatedFlightCount: 0,
      };
    case POLLING_FLIGHTS_FAILURE:
      return {
        ...state,
        isPolling: false,
        updatedFlightCount: 0,
      };
    case POLLING_FLIGHTS_SUCCESS: {
      const updatedFlightCount: number =
        state.updatedFlightCount > MAX_UPDATED_FLIGHT_COUNT
          ? 0
          : state.updatedFlightCount + 1;
      const isPolling: boolean =
        updatedFlightCount === 0 ? false : action.payload.result.isPolling;
      return {
        ...state,
        ...action.payload.entities,
        flightIds: action.payload.result.flights,
        isPolling: isPolling,
        totalNum: action.payload.result.totalNum,
        airlines: action.payload.result.airlines,
        airports: action.payload.result.airports,
        departureAirports: action.payload.result.departureAirports,
        transitAirports: action.payload.result.transitAirports,
        maxStop: action.payload.result.maxStop,
        maxTotalPriceCents: action.payload.result.maxTotalPriceCents,
        minTotalPriceCents: action.payload.result.minTotalPriceCents,
        updatedFlightCount: updatedFlightCount,
      };
    }
    case INIT_FLIGHTS:
      return {
        ...state,
        ...action.payload.entities,
        flightIds: action.payload.result.flights,
        page: action.payload.result.page,
        numPerPage: action.payload.result.numPerPage,
        totalNum: action.payload.result.totalNum,
      };
    case SET_DISPLAY_FLIGHT_SEARCH_FORM:
      return {
        ...state,
        isDisplayedSearchForm: action.payload,
      };
    default:
      return state;
  }
}
