import {
  createSlice,
  createSelector,
  createAsyncThunk,
  PayloadAction,
  createEntityAdapter,
  current,
} from "@reduxjs/toolkit";
import { stringify } from "qs";
import { camelizeKeys, decamelizeKeys } from "humps";
import isEqual from "react-fast-compare";
import { createWrapper } from "main/javascripts/api/AxiosWrapper";
import { ErrorResponse } from "main/javascripts/types/errorResponse";
import {
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from "main/javascripts/utils/sliceUtil";
import { RootState } from "main/javascripts/store";
import { Hotel } from "main/javascripts/types/hotel";
import { Image } from "main/javascripts/types/image";
import { Region } from "main/javascripts/types/region";
import { removeEmpty } from "main/javascripts/utils/ObjectUtil";
import {
  hotelShowParamHotelPlanIdSelector,
  hotelShowParamHotelRoomIdSelector,
} from "main/javascripts/slices/paramSlice";
import { HotelIndexParam } from "main/javascripts/types/hotelIndexParam";
import { DynamicPackagePreReservation } from "main/javascripts/models/DynamicPackagePreReservation";
import { HotelShowParam } from "main/javascripts/models/HotelShowParam";
import { clone } from "../../../../manager/javascripts/utils/ObjectUtil";

const key = "hotel";
const adapter = createEntityAdapter<Hotel.List>();
const adapterForMap = createEntityAdapter<Hotel.List>();

const MAX_UPDATED_HOTEL_COUNT: number =
  process.env.NODE_ENV === "production" ? 60 : 60;

const initialState = adapter.getInitialState({
  hotelsForMap: adapterForMap.getInitialState(),
  hotel: null,
  hotelId: 0,
  page: 0,
  numPerPage: 0,
  totalNum: 0,
  updatedHotelCount: 0,
  isPolling: false,
  isPollingCompleted: false,
  pollingCondition: null,
  descendingRegions: null, // 空の場合があるため未取得はnullにする

  isShowMapView: false,
  hotelIdsForMap: [],
  isPollingForMap: false,
  totalNumForMap: 0,
  mapCenterCoordinates: {},
  isDisplayedSearchForm: false,

  isDisplayedImageModal: false,
  isDisplayedRoomImageModal: false,
  roomImageModalData: {},
  isDisplayedRoomDetailModal: false,
  roomDetailModalData: {},
  isLoadingRoom: false,
  requestRoomError: null,

  loading: false,
  errors: null,
});

/** Async **/
export const fetchHotelsIndexPage = createAsyncThunk<
  {
    param: HotelIndexParam;
    hotels: Hotel.List[];
    numPerPage: number;
    page: number;
    totalNum: number;
    dynamicPackagePreReservation?: DynamicPackagePreReservation;
    facebookAppId: string;
  },
  {
    params: any;
    isServerSide?: boolean;
    headers?: any;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/fetchHotelsIndexPage`, async (args, { rejectWithValue }) => {
  try {
    const { params, isServerSide = false, headers } = args;
    const param: string = stringify(decamelizeKeys(params), {
      arrayFormat: "brackets",
    });
    const url = `/api/page/hotels.json?${param}`;
    const result = await createWrapper({ isServerSide }).get(url, {
      headers,
    });
    return {
      param: result.data.param,
      hotels: result.data.hotels,
      numPerPage: result.data.numPerPage,
      page: result.data.page,
      totalNum: result.data.totalNum,
      dynamicPackagePreReservation: result.data.dynamicPackagePreReservation,
      facebookAppId: result.data.facebookAppId || "",
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

export const fetchHotelsShowPage = createAsyncThunk<
  {
    hotel: Hotel.Detail;
    param: HotelShowParam;
    facebookAppId: string;
  },
  {
    id: string;
    params: any;
    isServerSide?: boolean;
    headers?: any;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/fetchHotelsShowPage`, async (args, { rejectWithValue }) => {
  try {
    const { id, params, isServerSide = false, headers } = args;
    const param: string = stringify(decamelizeKeys(params), {
      arrayFormat: "brackets",
    });
    const url = `/api/page/hotels/${id}.json?${param}`;
    const result = await createWrapper({ isServerSide }).get(url, {
      headers,
    });
    return {
      hotel: result.data.hotel,
      param: result.data.param,
      facebookAppId: result.data.facebookAppId || "",
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

// ポーリングする場合は必ず事前にsetPollingConditionを実施すること
export const fetchHotels = createAsyncThunk<
  {
    hotels: Hotel.List[];
    isPolling: boolean;
    page: number;
    numPerPage: number;
    totalNum: number;
    descendingRegions: Region.Region[];
    isPollingRequest: boolean;
    params: any;
  },
  {
    params: any;
    isPollingRequest?: boolean;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/fetchHotels`, async (args, { rejectWithValue }) => {
  try {
    const { params, isPollingRequest = false } = args;
    const param: string = stringify(decamelizeKeys(params), {
      arrayFormat: "brackets",
    });
    const url = `/api/hotels.json?${param}`;
    const result = await createWrapper().get(url);
    return {
      hotels: result.data.hotels,
      isPolling: result.data.isPolling,
      page: result.data.page,
      numPerPage: result.data.numPerPage,
      totalNum: result.data.totalNum,
      descendingRegions: result.data.descendingRegions,
      isPollingRequest: isPollingRequest,
      params: params,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

export const fetchHotelsForMap = createAsyncThunk<
  {
    hotels: Hotel.List[];
    isPolling: boolean;
    page: number;
    numPerPage: number;
    totalNum: number;
    descendingRegions: Region.Region[];
    isPollingRequest: boolean;
    params: any;
  },
  {
    params: any;
    isPollingRequest?: boolean;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/fetchHotelsForMap`, async (args, { rejectWithValue }) => {
  try {
    const { params, isPollingRequest } = args;
    const param: string = stringify(decamelizeKeys(params), {
      arrayFormat: "brackets",
    });
    const url = `/api/hotels.json?${param}`;
    const result = await createWrapper().get(url);
    return {
      hotels: result.data.hotels,
      isPolling: result.data.isPolling,
      page: result.data.page,
      numPerPage: result.data.numPerPage,
      totalNum: result.data.totalNum,
      descendingRegions: result.data.descendingRegions,
      isPollingRequest: isPollingRequest,
      params: params,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

export const fetchHotelRooms = createAsyncThunk<
  {
    hotelRooms: Hotel.Room[];
    roomCacheId: string;
    hotelId: number;
  },
  {
    hotelId: number;
    param: string;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/fetchHotelRooms`, async (args, { rejectWithValue }) => {
  try {
    const { hotelId, param = false } = args;
    const url = `/api/hotels/${hotelId}/hotel_rooms.json?${param}`;
    const result = await createWrapper().get(url);
    return {
      hotelRooms: result.data.hotelRooms,
      roomCacheId: result.data.roomCacheId,
      hotelId: hotelId,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

/** slice **/
export const hotelSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    initHotel: (state, action: PayloadAction<Hotel.Detail>) => {
      state.hotelId = action.payload.id;
      state.hotel = action.payload;
    },
    initHotels: (state, action) => {
      adapter.setAll(state, action.payload.hotels || []);
      state.page = action.payload.page;
      state.numPerPage = action.payload.numPerPage;
      state.totalNum = action.payload.totalNum;
    },
    setHotelRooms: (
      state,
      action: PayloadAction<{ hotelId: number; hotelRooms: Hotel.Room[] }>
    ) => {
      state.hotelId = action.payload.hotelId;
      const hotel = state.hotel;
      hotel.hotelRooms = action.payload.hotelRooms;
      state.hotel = hotel;
    },
    setIsHotelMapView: (state, action) => {
      state.isShowMapView = action.payload;
    },
    setHotelMapCoordinates: (state, action) => {
      state.mapCenterCoordinates = action.payload;
    },
    setDisplayHotelSearchForm: (state, action) => {
      state.isDisplayedSearchForm = action.payload;
    },
    setDisplayHotelImageModal: (state, action) => {
      state.isDisplayedImageModal = action.payload;
    },
    setDisplayHotelRoomImageModal: (state, action) => {
      state.isDisplayedRoomImageModal = action.payload;
    },
    setDisplayHotelRoomDetailModal: (state, action) => {
      state.isDisplayedRoomDetailModal = action.payload;
    },
    setHotelRoomModalDetails: (state, action) => {
      state.roomDetailModalData = action.payload;
    },
    setIsLoadingRoom: (state, action) => {
      state.isLoadingRoom = action.payload;
    },
    setRequestRoomError: (state, action) => {
      state.requestRoomError = action.payload;
    },
    setPollingCondition: (state, action) => {
      state.pollingCondition = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHotels.pending, (state) => {
        state.isPolling = true;
        state.isPollingCompleted = false;
        state.updatedHotelCount = 0;
      })
      .addCase(fetchHotels.fulfilled, (state, action) => {
        // ポーリングの場合paramsが異なるアクションは無視する
        const isPollingRequest = action.payload.isPollingRequest;
        const currentCondition = removeEmpty(current(state.pollingCondition));
        const params = removeEmpty(action.payload.params);
        if (isPollingRequest && !isEqual(currentCondition, params)) {
          return;
        }

        adapter.setAll(state, action.payload.hotels || []);
        const updatedHotelCount: number =
          state.updatedHotelCount > MAX_UPDATED_HOTEL_COUNT
            ? 0
            : state.updatedHotelCount + 1;
        const isPolling: boolean =
          updatedHotelCount === 0 ? false : action.payload.isPolling;
        state.isPolling = isPolling;
        state.isPollingCompleted = !isPolling;
        state.page = action.payload.page;
        // state.numPerPage = action.payload.numPerPage;
        state.totalNum = action.payload.totalNum;
        state.descendingRegions = action.payload.descendingRegions;
        state.updatedHotelCount = updatedHotelCount;
      })
      .addCase(fetchHotels.rejected, (state, _action) => {
        state.isPolling = false;
        state.isPollingCompleted = true;
        state.page = 1;
      })
      .addCase(fetchHotelsForMap.pending, (state) => {
        state.isPollingForMap = true;
      })
      .addCase(fetchHotelsForMap.fulfilled, (state, action) => {
        // ポーリングの場合paramsが異なるアクションは無視する
        const isPollingRequest = action.payload.isPollingRequest;
        const currentCondition = removeEmpty(current(state.pollingCondition));
        const params = removeEmpty(action.payload.params);
        if (isPollingRequest && !isEqual(currentCondition, params)) {
          return;
        }

        adapterForMap.setAll(state.hotelsForMap, action.payload.hotels);
        state.isPollingForMap = action.payload.isPolling;
        state.page = action.payload.page;
        state.totalNumForMap = action.payload.totalNum;
        state.descendingRegions = action.payload.descendingRegions;
      })
      .addCase(fetchHotelRooms.fulfilled, (state, action) => {
        state.hotelId = action.payload.hotelId;
        const hotel = state.hotel;
        hotel.hotelRooms = action.payload.hotelRooms;
        state.hotel = hotel;
      })
      // 一旦slice単位で共通化
      .addMatcher(isPendingAction(key), (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addMatcher(isFulfilledAction(key), (state) => {
        state.loading = false;
      })
      .addMatcher(isRejectedAction(key), (state, action) => {
        state.errors = action.payload;
        state.loading = false;
      });
  },
});

/** selector **/
export const {
  selectById: hotelByIdSelector,
  selectIds: hotelIdsSelector,
  selectEntities: hotelEntitiesSelector,
  selectAll: hotelsSelector,
  selectTotal: totalHotelsSelector,
} = adapter.getSelectors((state: RootState) => state.hotel);

export const { selectAll: hotelsForMapSelector } = adapterForMap.getSelectors(
  (state: RootState) => state.hotel.hotelsForMap
);

const stateSelector = (state: { [key]: Hotel.HotelEntityState }) => state[key];

export const hotelSelector = createSelector(
  stateSelector,
  (state) => state.hotel
);

export const hotelIdSelector = createSelector(
  stateSelector,
  (state) => state.hotelId
);

export const hotelImagesSelector = createSelector(
  stateSelector,
  (state) => state.hotel.hotelImages
);

export const pageSelector = createSelector(
  stateSelector,
  (state) => state.page
);

export const numPerPageSelector = createSelector(
  stateSelector,
  (state) => state.numPerPage
);

export const totalNumSelector = createSelector(
  stateSelector,
  (state) => state.totalNum
);

export const isPollingSelector = createSelector(
  stateSelector,
  (state) => state.isPolling
);

export const isPollingCompletedSelector = createSelector(
  stateSelector,
  (state) => state.isPollingCompleted
);

export const descendingRegionsSelector = createSelector(
  stateSelector,
  (state) => state.descendingRegions
);

export const isShowMapViewSelector = createSelector(
  stateSelector,
  (state) => state.isShowMapView
);

export const isDisplayedSearchFormSelector = createSelector(
  stateSelector,
  (state) => state.isDisplayedSearchForm
);

export const isDisplayedImageModalSelector = createSelector(
  stateSelector,
  (state) => state.isDisplayedImageModal
);

export const roomImageModalDataSelector = createSelector(
  stateSelector,
  (state) => state.roomImageModalData
);

export const isDisplayedRoomImageModalSelector = createSelector(
  stateSelector,
  (state) => state.isDisplayedRoomImageModal
);

export const roomDetailModalDataSelector = createSelector(
  stateSelector,
  (state) => state.roomDetailModalData
);

export const isDisplayedRoomDetailModalSelector = createSelector(
  stateSelector,
  (state) => state.isDisplayedRoomDetailModal
);

export const isLoadingRoomSelector = createSelector(
  stateSelector,
  (state) => state.isLoadingRoom
);

export const requestRoomErrorSelector = createSelector(
  stateSelector,
  (state) => state.requestRoomError
);

export const errorsSelector = createSelector(
  stateSelector,
  (state) => state.errors
);

export const loadingSelector = createSelector(
  stateSelector,
  (state) => state.loading
);

export const selectedHotelRoomSelector = createSelector(
  hotelSelector,
  hotelShowParamHotelRoomIdSelector,
  (hotel: Hotel.Detail, hotelRoomId: string): Hotel.Room | undefined => {
    if (!hotel) {
      return;
    }
    // hotelRoom.idはstringで扱う
    return hotel.hotelRooms?.find(
      (hotelRoom: Hotel.Room) => hotelRoom.id === hotelRoomId
    );
  }
);

export const selectedHotelPlanSelector = createSelector(
  selectedHotelRoomSelector,
  hotelShowParamHotelPlanIdSelector,
  (hotelRoom: Hotel.Room, hotelPlanId: string): Hotel.Plan | undefined => {
    if (!hotelRoom) {
      return;
    }
    // hotelPlan.idはstringで扱う
    return hotelRoom.hotelPlans?.find(
      (hotelPlan: Hotel.Plan) => String(hotelPlan.id) === hotelPlanId
    );
  }
);

/** action export **/
export const {
  initHotel,
  initHotels,
  setHotelRooms,
  setIsHotelMapView,
  setHotelMapCoordinates,
  setDisplayHotelSearchForm,
  setDisplayHotelImageModal,
  setDisplayHotelRoomImageModal,
  setDisplayHotelRoomDetailModal,
  setHotelRoomModalDetails,
  setIsLoadingRoom,
  setRequestRoomError,
  setPollingCondition,
} = hotelSlice.actions;

// Hotel.Room
export const defaultHotelRoomImage = (
  hotelRoom: Hotel.Room
): Image | undefined => {
  const defaultImage: Image | undefined = hotelRoom.hotelRoomImages.find(
    (img: Image) => {
      return img.isDefault;
    }
  );
  return defaultImage ? defaultImage : hotelRoom.hotelRoomImages[0];
};

export const convertHotelSearchFormValuesToParams = (data: any) => {
  const params = clone(data);
  if (params.guests?.length > 0) {
    params.guests.map((guest, index) => {
      if (Number(guest.number_of_children) > 0) {
        params.guests[index].ages_of_children = guest.ages_of_children.map(
          (age) => age.age
        );
      } else {
        delete params.guests[index].ages_of_children;
      }
    });
  }
  return params;
};
