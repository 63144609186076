import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { stringify } from "qs";
import { camelizeKeys, decamelizeKeys } from "humps";
import { createWrapper } from "main/javascripts/api/AxiosWrapper";
import { ErrorResponse } from "main/javascripts/types/errorResponse";
import { Search } from "main/javascripts/types/search";
import {
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from "main/javascripts/utils/sliceUtil";

const key = "search";

const initialState: Search = {
  searchFormParams: null,
  loading: false,
  errors: null,
};

/** Async **/
export const fetchSearchFormParams = createAsyncThunk<
  {
    searchFormParams: any;
  },
  {
    params: {
      dynamicPackageId?: string;
      searchCacheId?: string;
    };
    isServerSide?: boolean;
    headers?: any;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/fetchSearchFormParams`, async (args, { rejectWithValue }) => {
  try {
    const { params, isServerSide = false, headers } = args;
    const param: string = stringify(decamelizeKeys(params), {
      arrayFormat: "brackets",
    });
    const url = `/api/search_form_params.json?${param}`;
    const result = await createWrapper({ isServerSide }).get(url, {
      headers,
    });
    return {
      searchFormParams: result.data.formParams,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

/** slice **/
export const searchSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    initSearchFormParams: (state, action) => {
      state.searchFormParams = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSearchFormParams.fulfilled, (state, action) => {
        state.searchFormParams = action.payload.searchFormParams;
      })
      // 一旦slice単位で共通化
      .addMatcher(isPendingAction(key), (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addMatcher(isFulfilledAction(key), (state) => {
        state.loading = false;
      })
      .addMatcher(isRejectedAction(key), (state, action) => {
        state.errors = action.payload;
        state.loading = false;
      });
  },
});

/** selector **/
const stateSelector = (state: { [key]: Search }) => state[key];

export const searchFormParamsSelector = createSelector(
  stateSelector,
  (state) => state.searchFormParams
);

export const errorsSelector = createSelector(
  stateSelector,
  (state) => state.errors
);

export const loadingSelector = createSelector(
  stateSelector,
  (state) => state.loading
);

/** action export **/
export const { initSearchFormParams } = searchSlice.actions;
