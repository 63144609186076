import * as React from "react";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import {
  fontSize,
  fontWeight,
  lineHeight,
} from "main/javascripts/styles/base/typographyStyle";
import {
  accentColor,
  textColor,
} from "main/javascripts/styles/base/colorStyle";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { moreThanBreakpoint } from "main/javascripts/styles/base/responsiveStyle";

export interface ILabelProps {
  namespace: string;
  label: string;
  showLabel?: boolean;
  annotation?: string;
  isRequired?: boolean;
  colorStyleKey?: keyof IColorStyle;
}

export const Label: React.FC<ILabelProps> = (
  props: ILabelProps
): React.ReactElement<ILabelProps> => {
  const {
    namespace,
    label,
    annotation,
    isRequired,
    showLabel,
    colorStyleKey = "default",
  } = props;
  const { t } = useTranslation(["label"]);
  let localizedLabel = t(`${namespace}.${label}`);
  if (showLabel || localizedLabel.indexOf(`${namespace}.${label}`) === 0) {
    localizedLabel = label;
  }
  return (
    <label css={[labelStyle, colorStyle[colorStyleKey]]}>
      {localizedLabel}
      {annotation}
      {isRequired && <span css={requiredStyle}>{t("common.required")}</span>}
    </label>
  );
};

export interface IColorStyle {
  default: any;
  light: any;
  tabletLight: any;
  primary: any;
  textSecondary: any;
}

const labelStyle = css`
  font-size: ${fontSize.caption};
  line-height: ${lineHeight.caption3};
  font-weight: ${fontWeight.bold};
  color: ${textColor.primaryDarkColor};
  display: flex;
  align-items: flex-start;
  margin: 0;
  padding: 0 0 ${space.atom};
`;
const requiredStyle = css`
  display: inline-block;
  color: ${accentColor.alertColor};
  font-size: ${fontSize.smallest};
  font-weight: ${fontWeight.regular};
  border-left: 1px solid;
  margin: 0.0625rem 0 0 ${space.atom1_5x};
  line-height: 1;
  padding: 0.125rem 0 0.125rem ${space.atom1_5x};
  white-space: nowrap;
`;

const colorStyle: IColorStyle = {
  default: css``,
  light: css`
    color: #fff;
  `,
  tabletLight: css`
    ${moreThanBreakpoint("tablet")} {
      color: #fff;
    }
  `,
  primary: css`
    color: ${accentColor.primaryColor};
  `,
  textSecondary: css`
    color: ${textColor.secondaryDarkColor};
  `,
};
