export const INIT_FLIGHT_PRE_RESERVATION = "INIT_FLIGHT_PRE_RESERVATION";
export const FETCH_FLIGHT_PRE_RESERVATION_REQUEST =
  "FETCH_FLIGHT_PRE_RESERVATION_REQUEST";
export const FETCH_FLIGHT_PRE_RESERVATION_FAILURE =
  "FETCH_FLIGHT_PRE_RESERVATION_FAILURE";
export const FETCH_FLIGHT_PRE_RESERVATION_SUCCESS =
  "FETCH_FLIGHT_PRE_RESERVATION_SUCCESS";
export const UPDATE_FLIGHT_PRE_RESERVATION_PARAMETERS_REQUEST =
  "UPDATE_FLIGHT_PRE_RESERVATION_PARAMETERS_REQUEST";
export const SET_DISPLAY_FLIGHT_PRE_RESERVATION_CONFIRMATION =
  "SET_DISPLAY_FLIGHT_PRE_RESERVATION_CONFIRMATION";
