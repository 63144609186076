import { handleActions } from "redux-actions";
import { FETCHING_HOTEL_DESTINATION_SUGGESTS_SUCCESS } from "main/javascripts/constants/actionType/DestinationSuggestActionType";
import { DestinationSuggestion } from "main/javascripts/models/DestinationSuggestion";

export interface IDestinationSuggestState {
  destinationHotelSuggestions: DestinationSuggestion[];
}

const INITIAL_STATE: IDestinationSuggestState = {
  destinationHotelSuggestions: [],
};

export const destinationSuggestReducer: any = handleActions(
  {
    [FETCHING_HOTEL_DESTINATION_SUGGESTS_SUCCESS]: (
      _: IDestinationSuggestState,
      action: any
    ): IDestinationSuggestState => {
      return { destinationHotelSuggestions: action.payload };
    },
  },
  INITIAL_STATE
);
