import { handleActions } from "redux-actions";
import { searchCategories } from "main/javascripts/constants/SearchCategories";
import { CHANGE_SEARCH_CATEGORY } from "main/javascripts/constants/actionType/SearchCategoryType";

export interface ISearchPageState {
  searchCategory: string;
}

const INITIAL_STATE: ISearchPageState = {
  searchCategory: searchCategories.hotel,
};

export const searchPageReducer: any = handleActions(
  {
    [CHANGE_SEARCH_CATEGORY]: (
      _: ISearchPageState,
      action: any
    ): ISearchPageState => {
      return {
        searchCategory: action.payload,
      };
    },
  },
  INITIAL_STATE
);
