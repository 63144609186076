import React, { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import { RangeSliderHandle } from "main/javascripts/components/form/rangeSlider/RangeSliderHandle";
import { space } from "main/javascripts/styles/base/spaceStyle";
import {
  accentColor,
  textColor,
} from "main/javascripts/styles/base/colorStyle";

export const RangeSliderOnChangeLeft = "RangeSliderOnChangeLeft";
export const RangeSliderOnChangeRight = "RangeSliderOnChangeRight";

interface IProps {
  dataNum: number;
  initialLeft: number;
  initialRight: number;
  onChange(direction: string, value: any): void;
  onClickEnd?: any;
  styles?: any;
}

export const RangeSlider: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const {
    dataNum,
    initialLeft,
    initialRight,
    onChange,
    onClickEnd,
    styles = {},
  } = props;

  const [xStart, setXStart] = useState(0);
  const [xEnd, setXEnd] = useState(0);
  const [xLeft, setXLeft] = useState(initialLeft);
  const [xRight, setXRight] = useState(initialRight);

  const innerElmRef = useRef<HTMLDivElement>();

  useEffect(() => {
    getSliderPosition();
    window.addEventListener("resize", onResizeWindow);
    return () => {
      innerElmRef.current = null;
      window.removeEventListener("resize", onResizeWindow);
    };
  }, []);

  useEffect(() => {
    setXLeft(initialLeft);
    setXRight(initialRight);
  }, [initialLeft, initialRight]);

  const getSliderPosition = (): void => {
    if (innerElmRef.current) {
      setXStart(0);
      setXEnd(innerElmRef.current.clientWidth);
    }
  };

  const onMoveLeft: (x: number) => void = (x: number) => {
    const bounds = innerElmRef.current.getBoundingClientRect();
    const relativeX = x - bounds.left;
    let posRatio = (relativeX - xStart) / (xEnd - xStart);
    const maxPos = xRight - 1 / dataNum;
    posRatio = posRatio > 0 ? posRatio : 0;
    posRatio = posRatio < maxPos ? posRatio : maxPos;
    setXLeft(posRatio);
    onChange(RangeSliderOnChangeLeft, posRatio);
  };

  const onMoveRight: (x: any) => void = (x: any) => {
    const bounds = innerElmRef.current.getBoundingClientRect();
    const relativeX = x - bounds.left;
    let posRatio = (relativeX - xStart) / (xEnd - xStart);
    const minPos = xLeft + 1 / dataNum;
    posRatio = posRatio > minPos ? posRatio : minPos;
    posRatio = posRatio < 1 ? posRatio : 0.9999; // 1だと配列数の最大を超えるため制限している
    setXRight(posRatio);
    onChange(RangeSliderOnChangeRight, posRatio);
  };

  const onResizeWindow: () => void = () => {
    getSliderPosition();
  };

  const onClickEndLeft: () => void = () => {
    if (onClickEnd) {
      onClickEnd(RangeSliderOnChangeLeft);
    }
  };

  const onClickEndRight: () => void = () => {
    if (onClickEnd) {
      onClickEnd(RangeSliderOnChangeRight);
    }
  };

  const { block, bar } = styles;
  const barWidthPer = xRight - xLeft;
  return (
    <div css={[blockStyle, block]}>
      <div css={innerStyle} ref={innerElmRef}>
        <div
          css={[barStyle, bar]}
          style={{
            transform: `translateX(${xLeft * 100}%) scaleX(${barWidthPer})`,
          }}
        />
        <RangeSliderHandle
          key="left"
          x={xLeft}
          position="left"
          onMove={onMoveLeft}
          onClickEnd={onClickEndLeft}
        />
        <RangeSliderHandle
          key="right"
          x={xRight}
          position="right"
          onMove={onMoveRight}
          onClickEnd={onClickEndRight}
        />
      </div>
    </div>
  );
};

const blockStyle = css`
  position: relative;
  height: 40px;
`;
const innerStyle = css`
  position: relative;
  height: 100%;
  width: calc(100% - (${space.atom2x} * 2));
  margin: 0 auto;
  &:before {
    content: "";
    width: calc(100% + (${space.atom2x} * 2));
    height: 4px;
    position: absolute;
    top: 50%;
    left: -${space.atom2x};
    margin-top: -2px;
    background: ${textColor.disabledColor};
  }
`;
const barStyle = css`
  position: absolute;
  top: 50%;
  height: 4px;
  background: ${accentColor.primaryColor2};
  width: 100%;
  margin-top: -2px;
  transform-origin: left;
`;
