import { Action } from "redux";
import {
  INIT_DYNAMIC_PACKAGE_RESERVATION,
  INIT_DYNAMIC_PACKAGE_RESERVATIONS,
} from "main/javascripts/constants/actionType/DynamicPackageReservationActionType";

export interface IDynamicPackageReservationState {
  dynamicPackageReservationId: number;
  dynamicPackageReservationIds: number[];

  dynamicPackageReservation: any;
  flightReservation: any;
  flightArrangementItinerary: any;
  traveler: any;
  hotelReservation: any;
  hotelArrangement: any;
  hotelArrangementPlan: any;
}

interface IInitDynamicPackageReservationAction extends Action {
  actionType: string;
  payload: any;
}

export type DynamicPackageReservationActions =
  IInitDynamicPackageReservationAction;

export const dynamicPackageReservationTestData: any = {
  id: "1",
  email: "test@example.com",
  status: "arranged",
  totalPrice: "99,999円",
  flight_reservation: {
    id: "49561537945841",
    phone_number: "09011111111",
    email: "test@example.com",
    total_price: "87,100円",
    flight_arrangement_itineraries: [
      {
        id: 41,
        duration: 395,
        valid_travelers: [
          {
            id: 31,
            first_name: "JIRO",
            last_name: "SUGIMORI",
          },
          {
            id: 32,
            first_name: "TARO",
            last_name: "SUGIMORI",
          },
        ],
        flight_arrangement_details: [
          {
            departure_date: "2018-10-12",
            departure_time: "06:10",
            departure_airport_code: "HND",
            departure_airport_short_name: "羽田空港",
            departure_airport_name: "東京 (HND-羽田空港)",
            departure_terminal: "I",
            arrival_date: "2018-10-12",
            arrival_time: "08:35",
            arrival_airport_code: "ICN",
            arrival_airport_name: "ソウル (ICN-仁川国際空港)",
            arrival_airport_short_name: "仁川国際空港",
            arrival_terminal: "1",
            flight_number: "177",
            cabin_class: "economy_standard",
            duration: 145,
            marketing_airline_code: "OZ",
            marketing_airline_name: "アシアナ航空",
            operating_airline_code: "OZ",
            operating_airline_name: "アシアナ航空",
          },
          {
            departure_date: "2018-10-12",
            departure_time: "10:45",
            departure_airport_code: "ICN",
            departure_airport_short_name: "仁川国際空港",
            departure_airport_name: "ソウル (ICN-仁川国際空港)",
            departure_terminal: "1",
            arrival_date: "2018-10-12",
            arrival_time: "11:45",
            arrival_airport_code: "PVG",
            arrival_airport_name: "上海 (PVG-浦東空港)",
            arrival_airport_short_name: "上海浦東国際空港",
            arrival_terminal: "2",
            flight_number: "363",
            cabin_class: "economy_standard",
            duration: 120,
            marketing_airline_code: "OZ",
            marketing_airline_name: "アシアナ航空",
            operating_airline_code: "OZ",
            operating_airline_name: "アシアナ航空",
          },
        ],
      },
      {
        id: 42,
        duration: 430,
        valid_travelers: [
          {
            id: 31,
            first_name: "JIRO",
            last_name: "SUGIMORI",
          },
          {
            id: 32,
            first_name: "TARO",
            last_name: "SUGIMORI",
          },
        ],
        flight_arrangement_details: [
          {
            departure_date: "2018-10-13",
            departure_time: "13:00",
            departure_airport_code: "PVG",
            departure_airport_short_name: "上海浦東国際空港",
            departure_airport_name: "上海 (PVG-浦東空港)",
            departure_terminal: "2",
            arrival_date: "2018-10-13",
            arrival_time: "15:55",
            arrival_airport_code: "ICN",
            arrival_airport_name: "ソウル (ICN-仁川国際空港)",
            arrival_airport_short_name: "仁川国際空港",
            arrival_terminal: "1",
            flight_number: "364",
            cabin_class: "economy_standard",
            duration: 115,
            marketing_airline_code: "OZ",
            marketing_airline_name: "アシアナ航空",
            operating_airline_code: "OZ",
            operating_airline_name: "アシアナ航空",
          },
          {
            departure_date: "2018-10-13",
            departure_time: "18:50",
            departure_airport_code: "ICN",
            departure_airport_short_name: "仁川国際空港",
            departure_airport_name: "ソウル (ICN-仁川国際空港)",
            departure_terminal: "1",
            arrival_date: "2018-10-13",
            arrival_time: "21:10",
            arrival_airport_code: "NRT",
            arrival_airport_name: "東京 (NRT-成田国際空港)",
            arrival_airport_short_name: "成田国際空港",
            arrival_terminal: "1",
            flight_number: "108",
            cabin_class: "economy_standard",
            duration: 140,
            marketing_airline_code: "OZ",
            marketing_airline_name: "アシアナ航空",
            operating_airline_code: "OZ",
            operating_airline_name: "アシアナ航空",
          },
        ],
      },
    ],
    travelers: [
      {
        id: 31,
        first_name: "JIRO",
        last_name: "SUGIMORI",
        birthday: "1972-06-06",
        sex: "male",
        status: "arranged",
        price: "39,550円",
        arrange_fee: "4,000円",
        arrange_fee_cents: 4000,
        total_price: "43,550円",
        flight_arrangement_traveler_prices: [
          {
            price_type: "base_fare",
            price: "26,000円",
          },
          {
            price_type: "tax_and_surcharge",
            price: "13,550円",
          },
        ],
      },
      {
        id: 32,
        first_name: "TARO",
        last_name: "SUGIMORI",
        birthday: "1970-10-09",
        sex: "male",
        status: "arranged",
        price: "39,550円",
        arrange_fee: "4,000円",
        arrange_fee_cents: 4000,
        total_price: "43,550円",
        flight_arrangement_traveler_prices: [
          {
            price_type: "base_fare",
            price: "26,000円",
          },
          {
            price_type: "tax_and_surcharge",
            price: "13,550円",
          },
        ],
      },
    ],
  },
  hotel_reservation: {
    id: "67921537946280",
    email: "test@example.com",
    first_name: "TARO",
    last_name: "SUGIMORI",
    hotel_arrangement: {
      id: 10,
      checkin_date: "2018-09-29",
      checkout_date: "2018-09-30",
      checkin: {
        end_time: "11:30 PM",
        begin_time: "3:00 PM",
        instructions:
          "施設のポリシーにしたがって、追加ゲスト料金がかかる場合があります。<br />" +
          "チェックイン時に政府発行の写真付き身分証明書、および現地での付随費用精算のためのクレジットカードまたは現金でのデポジットが必要です。<br />" +
          "スペシャルリクエストは、チェックイン時の状況によりご希望に添えない場合があります。また、リクエストによっては追加料金が発生する場合があります。" +
          "スペシャルリクエストは確約ではございませんのでご了承ください。<br />",
        special_instructions:
          "この施設のエレベーター 2 基のうち1 基は、2018 年 1 月 4 日から 2 月 28 日までご利用いただけません。" +
          "詳細は施設までお問い合わせください。電話番号は予約確認通知メールに記載されています。 " +
          "2018 年 2 月 27 日の午前 1 時から午前 4 時まで停電が予定されています。詳細は施設までお問い合わせください。" +
          "連絡先は予約手続完了後に送信される予約確認通知に記載されています。",
      },
      checkout: "11:00 AM",
      hotel_name: "ホテル京阪 京橋 グランデ",
      default_hotel_image:
        "https://i.travelapi.com/hotels/5000000/4630000/4626700/4626691/259fa00d_z.jpg",
      hotel_id: 385,
      hotel_address: "大阪府大阪市都島区東野田町2-1-38",
      hotel_phone_number: "81-06-6353-0321",
      total_price: "14,800円",
      hotel_arrangement_plans: [
        {
          id: 15,
          room_name: "エコノミー ツインルーム 喫煙可",
          meal_type: "nothing",
          number_of_adult: 2,
          ages_of_children: [],
          smoking: false,
          status: "arranged",
          first_name: "TARO",
          last_name: "SUGIMORI",
          price: "14,800円",
          is_refundable: true,
          arrange_fee: "0円",
          total_price: "14,800円",
          hotel_arrangement_plan_fees: [
            {
              feeType: "mandatory_tax",
              price: "300円",
            },
          ],
          hotel_arrangement_plan_amenities: [
            {
              name: "無料 WiFi",
            },
            {
              name: "高速インターネット (無料)",
            },
          ],
          hotel_arrangement_plan_prices: [
            {
              price_type: "base_rate",
              price: "12,458円",
            },
            {
              price_type: "tax_and_service_fee",
              price: "2,342円",
            },
          ],
          can_cancel: true,
          hotel_arrangement_cancellation: null,
        },
      ],
    },
    status: "arranged",
  },
};

const INITIAL_STATE: IDynamicPackageReservationState = {
  dynamicPackageReservationId: 0,
  dynamicPackageReservationIds: [],

  // normalizr
  dynamicPackageReservation: {},
  flightReservation: {},
  flightArrangementItinerary: {},
  traveler: {},
  hotelReservation: {},
  hotelArrangement: {},
  hotelArrangementPlan: {},
};

export function dynamicPackageReservationReducer(
  state: IDynamicPackageReservationState = INITIAL_STATE,
  action: DynamicPackageReservationActions
): IDynamicPackageReservationState {
  switch (action.type) {
    case INIT_DYNAMIC_PACKAGE_RESERVATION:
      return {
        ...state,
        ...action.payload.entities,
        dynamicPackageReservationId: action.payload.result,
      };
    case INIT_DYNAMIC_PACKAGE_RESERVATIONS:
      return {
        ...state,
        ...action.payload.entities,
        dynamicPackageReservationIds:
          action.payload.result.dynamicPackageReservationIds || [],
      };
    default:
      return state;
  }
}
