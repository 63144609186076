import { Action } from "redux";
import { INIT_CONSULTATION } from "main/javascripts/constants/actionType/ConsultationActionType";

export interface IConsultationState {
  consultationId: number;
  consultation: any;
}

interface IInitConsultation extends Action {
  actionType: string;
  payload: any;
}

export type ConsultationActions = IInitConsultation;

const INITIAL_STATE: IConsultationState = {
  consultationId: 0,
  consultation: null,
};

export function consultationReducer(
  state: IConsultationState = INITIAL_STATE,
  action: ConsultationActions
): IConsultationState {
  switch (action.type) {
    case INIT_CONSULTATION:
      return {
        ...state,
        ...action.payload.entities,
        consultationId: action.payload.result,
      };
    default:
      return state;
  }
}
