import { handleActions } from "redux-actions";
import { INIT_PARAM } from "main/javascripts/constants/actionType/ParamActionType";
import { HotelShowParam } from "main/javascripts/models/HotelShowParam";
import { HotelIndexParam } from "main/javascripts/models/HotelIndexParam";
import * as FlightIndexParam from "main/javascripts/models/FlightIndexParam";
import { ConsultationNewParam } from "main/javascripts/models/ConsultationNewParam";
import { ConsultationConditionEditParam } from "main/javascripts/models/ConsultationConditionEditParam";
import { ConsultationConditionShowParam } from "main/javascripts/models/ConsultationConditionShowParam";
import { FlightShowParam } from "main/javascripts/models/FlightShowParam";

export interface IParamState {
  hotelShowParam: HotelShowParam;
  hotelIndexParam: HotelIndexParam;
  flightIndexParam: FlightIndexParam.Base;
  flightShowParam: FlightShowParam;
  consultationNewParam: ConsultationNewParam;
  consultationConditionEditParam: ConsultationConditionEditParam;
  consultationConditionShowParam: ConsultationConditionShowParam;
}

const INITIAL_STATE: IParamState = {
  hotelShowParam: new HotelShowParam({}),
  hotelIndexParam: new HotelIndexParam({}),
  flightIndexParam: new FlightIndexParam.OneWay({}),
  flightShowParam: new FlightShowParam({}),
  consultationNewParam: null,
  consultationConditionEditParam: null,
  consultationConditionShowParam: null,
};

export const paramReducer: any = handleActions(
  {
    [INIT_PARAM]: (state: IParamState, action: any): IParamState => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  INITIAL_STATE
);
