import { Action } from "redux";
import {
  INIT_HOTEL_RESERVATIONS,
  INIT_HOTEL_RESERVATION,
  FETCH_HOTEL_RESERVATION_SUCCESS,
} from "main/javascripts/constants/actionType/HotelReservationActionType";

export interface IHotelReservationState {
  hotelReservationId: number;
  hotelReservationIds: number[];

  hotelReservation: any;
  hotelArrangement: any;
  hotelArrangementPlan: any;
}

interface IInitializingHotelReservationAction extends Action {
  actionType: string;
  payload: any;
}

export type HotelReservationActions = IInitializingHotelReservationAction;

const INITIAL_STATE: IHotelReservationState = {
  hotelReservationId: 0,
  hotelReservationIds: [],

  // normalizr
  hotelReservation: {},
  hotelArrangement: {},
  hotelArrangementPlan: {},
};

export function hotelReservationReducer(
  state: IHotelReservationState = INITIAL_STATE,
  action: HotelReservationActions
): IHotelReservationState {
  switch (action.type) {
    case INIT_HOTEL_RESERVATION:
      return {
        ...state,
        ...action.payload.entities,
        hotelReservationId: action.payload.result,
      };
    case FETCH_HOTEL_RESERVATION_SUCCESS:
      return {
        ...state,
        ...action.payload.entities,
        hotelReservationId: action.payload.result,
      };
    case INIT_HOTEL_RESERVATIONS:
      return {
        ...state,
        ...action.payload.entities,
        hotelReservationIds: action.payload.result.hotelReservations || [],
      };
    default:
      return state;
  }
}
