import { Action } from "redux";
import { INIT_CAROUSEL_FLIGHT } from "main/javascripts/constants/actionType/CarouselFlightActionType";
import { FETCHING_FLIGHT_INFO_SUCCESS } from "main/javascripts/constants/actionType/FlightActionType";

export interface ICarouselFlightState {
  carouselFlightId: number;
  carouselFlight: any;
}

interface IInitCarouselFlightAction extends Action {
  actionType: string;
  payload: any;
}

export type CarouselFlightActions = IInitCarouselFlightAction;

const INITIAL_STATE: ICarouselFlightState = {
  carouselFlightId: 0,
  carouselFlight: null,
};

export function carouselFlightReducer(
  state: ICarouselFlightState = INITIAL_STATE,
  action: CarouselFlightActions
): ICarouselFlightState {
  switch (action.type) {
    case INIT_CAROUSEL_FLIGHT:
      return {
        ...state,
        ...action.payload.entities,
        carouselFlightId: action.payload.result,
      };
    case FETCHING_FLIGHT_INFO_SUCCESS:
      if (!action.payload.entities || !action.payload.result) {
        return state;
      }
      return {
        ...state,
        carouselFlight: action.payload.entities.flight,
      };
    default:
      return state;
  }
}
