import { StyleSheet } from "aphrodite";
import {
  accentColor,
  borderColor,
  textColor,
} from "main/javascripts/styles/base/colorStyle";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { boxShadow } from "../../../../styles/base/shadowStyle";

export interface IStyle {
  block: any;
  inner: any;
  innerOneHandle: any;
  bar: any;
  handleBlock: any;
  handleBlockLeft: any;
  handle: any;
  handleLeft: any;
  error: any;
}

export const RangeSliderStyle: IStyle = StyleSheet.create({
  block: {
    position: "relative",
    height: "40px",
  },
  inner: {
    position: "relative",
    height: "100%",
    width: `calc(100% - (${space.atom2x} * 2))`,
    margin: "0 auto",
    ":before": {
      content: '""',
      width: `calc(100% + (${space.atom2x} * 2))`,
      height: "4px",
      position: "absolute",
      top: "50%",
      left: `-${space.atom2x}`,
      marginTop: "-2px",
      background: textColor.disabledColor,
    },
  },
  innerOneHandle: {
    position: "relative",
    height: "100%",
    width: `calc(100% - (${space.atom2x}))`,
    margin: "0 auto 0 0",
    ":before": {
      content: '""',
      width: `calc(100% + (${space.atom2x}))`,
      height: "4px",
      position: "absolute",
      top: "50%",
      left: 0,
      marginTop: "-2px",
      background: textColor.disabledColor,
    },
  },
  bar: {
    position: "absolute",
    top: "50%",
    height: "4px",
    background: accentColor.primaryColor2,
    width: "100%",
    marginTop: "-2px",
    transformOrigin: "left",
  },
  handleBlock: {
    width: "40px",
    height: "40px",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    marginLeft: "-20px",
  },
  handleBlockLeft: {
    width: "50px",
    marginLeft: "-30px",
    padding: "0 0 0 10px",
    boxSizing: "border-box",
  },
  handle: {
    width: "40px",
    height: "40px",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    marginLeft: "-20px",
    borderRadius: "50%",
    background: "#fff",
    border: `1px solid ${borderColor.shadowColor}`,
    boxShadow: boxShadow.interface,
    userSelect: "none",
  },
  handleLeft: {
    ":before": {
      content: '""',
      width: "10px",
      height: "100%",
      position: "absolute",
      top: 0,
      left: "-10px",
    },
  },
  error: {
    borderBottomColor: `${accentColor.alertColor}`,
  },
});
