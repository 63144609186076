import { useDispatch, TypedUseSelectorHook, useSelector } from "react-redux";
import logger from "redux-logger";
// import { createWrapper } from "next-redux-wrapper";
import {
  combineReducers,
  configureStore,
  Middleware,
  // getDefaultMiddleware,
} from "@reduxjs/toolkit";

import { rootReducer as reducers } from "./rootReducer";
import { normalizrMiddleware } from "./middleware/normalizrMiddleware";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";

const rootReducer = combineReducers({
  ...reducers,
});

const normalizr = normalizrMiddleware();
const sagaMiddleware: any = createSagaMiddleware();

// loggerにMiddleware型を指定しないとdispatch時に型エラーが発生する
const createStore = () => {
  return configureStore({
    reducer: rootReducer,
    // 完全にredux toolkitに移行できたらnormalizrを削除する
    // TODO: RTKに完全移行した後serializableCheckとimmutableCheckの設定を削除する
    middleware: (getDefaultMiddleware) =>
      process.env.NODE_ENV === "production"
        ? getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false,
          }).concat([normalizr, sagaMiddleware])
        : getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false,
          }).concat([logger as Middleware, normalizr, sagaMiddleware]),
  });
};
export const store = createStore();
sagaMiddleware.run(rootSaga);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppStore = ReturnType<typeof createStore>;
// export const wrapper = createWrapper<AppStore>(createStore);
