import { createAction, ActionFunction1, Action } from "redux-actions";
import {
  CREATE_LINE_AUTO_SIGN_IN_RQUEST,
  CREATE_LINE_AUTO_SIGN_IN_SUCCESS,
  CREATE_LINE_AUTO_SIGN_IN_FAILURE,
} from "main/javascripts/constants/actionType/LineAutoSignInActionType";

export const createLineAutoSignIn: ActionFunction1<
  any,
  Action<any>
> = createAction<any, any>(
  CREATE_LINE_AUTO_SIGN_IN_RQUEST,
  (params: any): any => params
);

export const createLineAutoSignInSuccess: any = createAction(
  CREATE_LINE_AUTO_SIGN_IN_SUCCESS
);

export const createLineAutoSignInFailure: any = createAction(
  CREATE_LINE_AUTO_SIGN_IN_FAILURE
);
