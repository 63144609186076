import { SagaIterator } from "redux-saga";
import { call, put, ForkEffect, takeLatest } from "redux-saga/effects";
import { IAction } from "main/javascripts/constants/type/ReduxActions";
import { CANCEL_HOTEL_ARRANGEMENT_PLAN_REQUEST } from "main/javascripts/constants/actionType/HotelArrangementPlanActionType";
import { cancelHotelArrangementPlan } from "main/javascripts/api/HotelArrangementPlanApi";
import { fetchReservation } from "main/javascripts/api/ReservationApi";
import {
  cancelHotelArrangementPlanSuccess,
  cancelHotelArrangementPlanFailure,
} from "main/javascripts/actions/HotelArrangementPlans";
import {
  fetchReservationSuccess,
  fetchReservationFailure,
} from "main/javascripts/actions/Reservations";

function* runRequestCancel(action: IAction<any>): SagaIterator {
  try {
    const param: any = {
      unique_id: action.payload.reservationId,
      token: action.payload.token,
    };
    const { data } = yield call(
      cancelHotelArrangementPlan,
      Number(action.payload.hotelArrangementPlanId),
      param
    );
    yield put(cancelHotelArrangementPlanSuccess(data));
    yield call(requestFetchReservation, action);
  } catch (e) {
    yield put(cancelHotelArrangementPlanFailure({ errors: e.errors }));
  }
}

function* requestFetchReservation(action: IAction<any>): SagaIterator {
  try {
    const { data } = yield call(
      fetchReservation,
      Number(action.payload.reservationId),
      action.payload.token
    );
    yield put(fetchReservationSuccess(data));
  } catch (e) {
    yield put(fetchReservationFailure({ errors: e.errros }));
  }
}

export const hotelArrangementPlanSaga: ForkEffect[] = [
  takeLatest(CANCEL_HOTEL_ARRANGEMENT_PLAN_REQUEST, runRequestCancel),
];
