import { createAction, ActionFunction1, Action } from "redux-actions";
import {
  CREATE_FACEBOOK_MESSENGER_AUTO_SIGN_IN_RQUEST,
  CREATE_FACEBOOK_MESSENGER_AUTO_SIGN_IN_SUCCESS,
  CREATE_FACEBOOK_MESSENGER_AUTO_SIGN_IN_FAILURE,
} from "main/javascripts/constants/actionType/FacebookMessengerAutoSignInActionType";

export const createFacebookMessengerAutoSignIn: ActionFunction1<
  any,
  Action<any>
> = createAction<any, any>(
  CREATE_FACEBOOK_MESSENGER_AUTO_SIGN_IN_RQUEST,
  (params: any): any => params
);

export const createFacebookMessengerAutoSignInSuccess: any = createAction(
  CREATE_FACEBOOK_MESSENGER_AUTO_SIGN_IN_SUCCESS
);

export const createFacebookMessengerAutoSignInFailure: any = createAction(
  CREATE_FACEBOOK_MESSENGER_AUTO_SIGN_IN_FAILURE
);
