import { createWrapper } from "./AxiosWrapper";

export async function createFacebookUserSignIn(param: any): Promise<any> {
  // return createWrapper({ isCsrf: true })
  return createWrapper()
    .post("/api/facebook/users/sign_in.json", param)
    .then(async (response: any): Promise<any> => {
      return Promise.resolve({
        data: response.data,
      });
    })
    .catch(async (error: any): Promise<any> => {
      return Promise.reject({
        errors: error.resonse.data.errors,
        errorCode: error.response.data.error_code,
        errorMessages: error.response.data.error_messages,
      });
    });
}
