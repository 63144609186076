export interface IAirport {
  id: number;
  name: string;
  shortName: string;
  code: string;
  cityId: number;
  cityName: string;
  latitude: number;
  longitude: number;
  accessToCity: string;
}

const defaultAirport: IAirport = {
  id: 0,
  name: "",
  shortName: "",
  code: "",
  cityId: 0,
  cityName: "",
  latitude: 0,
  longitude: 0,
  accessToCity: "",
};

export class Airport implements IAirport {
  public id: number;
  public name: string;
  public shortName: string;
  public code: string;
  public cityId: number;
  public cityName: string;
  public latitude: number;
  public longitude: number;
  public accessToCity: string;
  constructor(param: IAirport) {
    Object.assign(this, defaultAirport, param);
  }
}
