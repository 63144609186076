import { createSlice, createSelector } from "@reduxjs/toolkit";
import { stringify } from "qs";
import { decamelizeKeys } from "humps";
import { Param } from "../types/param";
import { HotelIndexParam } from "../types/hotelIndexParam";
import { HotelShowParam } from "../types/hotelShowParam";
import { FlightIndexParam } from "../types/flightIndexParam";
import { FlightShowParam } from "../types/flightShowParam";
import { FlightTypes } from "../constants/FlightTypes";
import { SearchTypes } from "../constants/SearchTypes";

const key = "param";

const initialState: Param = {
  hotelIndexParam: null,
  hotelShowParam: null,
  flightIndexParam: null,
  flightShowParam: null,
  isTrackingParameterStored: false,
};

/** slice **/
export const paramSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    initHotelIndexParam: (state, action) => {
      state.hotelIndexParam = action.payload;
    },
    initHotelShowParam: (state, action) => {
      state.hotelShowParam = action.payload;
    },
    initFlightIndexParam: (state, action) => {
      state.flightIndexParam = action.payload;
    },
    initFlightShowParam: (state, action) => {
      state.flightShowParam = action.payload;
    },
    setIsTrackingParameterStored: (state, action) => {
      state.isTrackingParameterStored = action.payload;
    },
  },
  // extraReducers: (builder) => {},
});

/** selector **/
const stateSelector = (state: { [key]: Param }) => state[key];

export const hotelIndexParamSelector = createSelector(
  stateSelector,
  (state) => state.hotelIndexParam
);

export const hotelShowParamSelector = createSelector(
  stateSelector,
  (state) => state.hotelShowParam
);

export const hotelShowParamHotelRoomIdSelector = createSelector(
  stateSelector,
  (state) => state.hotelShowParam.hotelRoomId
);

export const hotelShowParamHotelPlanIdSelector = createSelector(
  stateSelector,
  (state) => state.hotelShowParam.hotelPlanId
);

export const flightIndexParamSelector = createSelector(
  stateSelector,
  (state) => state.flightIndexParam
);

export const flightShowParamSelector = createSelector(
  stateSelector,
  (state) => state.flightShowParam
);

export const isTrackingParameterStoredSelector = createSelector(
  stateSelector,
  (state) => state.isTrackingParameterStored
);

// HotelIndexParam
export const hotelIndexParamToSearchFormValues = (
  param: HotelIndexParam
): any => {
  const guestsParam: any = guestsToFormValues(param.guests);
  return decamelizeKeys({
    destination: param.destination,
    destinationId: param.destinationId,
    destinationType: param.destinationType,
    checkinDate: param.checkinDate,
    checkoutDate: param.checkoutDate,
    cacheId: param.cacheId,
    valid: param.valid,
    longitude: param.longitude,
    latitude: param.latitude,
    guests: guestsParam,
    numberOfRooms: guestsParam.length,
    searchType: param.searchType,
    dynamicPackageId: param.dynamicPackageId,
    searchGroupId: param.searchGroupId,
    lId: param.lId,
    qCId: param.qCId,
    dynamicPackagePreReservationId: param.dynamicPackagePreReservationId,
  });
};
const guestsToFormValues = (paramGuests: any[]): any => {
  const guests: any =
    paramGuests.length > 0
      ? paramGuests.map((guest) => {
          return {
            ...guest,
            agesOfChildren: guest.agesOfChildren?.map((age) => {
              return { age: age };
            }),
          };
        })
      : [{ numberOfAdult: 2, agesOfChildren: [] }];
  return guests.map((guest: any) => {
    return {
      ...guest,
      numberOfChildren:
        guest.agesOfChildren?.length > 0 ? guest.agesOfChildren.length : 0,
    };
  });
};
export const hotelIndexParamToUrlParam = (param: HotelIndexParam): string => {
  return stringify(hotelIndexParamToParam(param), { arrayFormat: "indices" });
};
export const hotelIndexParamToParam = (param: HotelIndexParam): any => {
  const guestsParam: any = hotelIndexParamToGuestsParam(param);
  return decamelizeKeys({
    destination: param.destination,
    destinationId: param.destinationId,
    destinationType: param.destinationType,
    checkinDate: param.checkinDate,
    checkoutDate: param.checkoutDate,
    cacheId: param.cacheId,
    valid: param.valid,
    longitude: param.longitude,
    latitude: param.latitude,
    guests: guestsParam,
    numberOfRooms: guestsParam.length,
    searchType: param.searchType,
    dynamicPackageId: param.dynamicPackageId,
    searchGroupId: param.searchGroupId,
    lId: param.lId,
    qCId: param.qCId,
    dynamicPackagePreReservationId: param.dynamicPackagePreReservationId,
  });
};
export const hotelIndexParamToAdvancedParam = (param: HotelIndexParam): any => {
  const minPrice = isNaN(Number(param.minPrice))
    ? param.minPrice
    : Number(param.minPrice);
  const maxPrice = isNaN(Number(param.maxPrice))
    ? param.maxPrice
    : Number(param.maxPrice);
  return decamelizeKeys({
    minPrice: minPrice,
    maxPrice: maxPrice,
    minRating: param.minRating,
    rank: param.rank,
    sortBy: param.sortBy,
    bedCount: param.bedCount,
    bedSizeTypes: param.bedSizeTypes,
    mealType: param.mealType,
    viewType: param.viewType,
    descendingRegionIds: param.descendingRegionIds,
    lId: param.lId,
    qCId: param.qCId,
  });
};
const hotelIndexParamToGuestsParam = (param: HotelIndexParam): any => {
  const guests: any =
    param.guests?.length > 0
      ? param.guests
      : [{ numberOfAdult: 2, agesOfChildren: [] }];
  return guests.map((guest: any) => {
    return {
      ...guest,
      numberOfChildren:
        guest.agesOfChildren?.length > 0 ? guest.agesOfChildren.length : 0,
    };
  });
};
export const hotelIndexParamTotalNumberOfAdult = (
  param: HotelIndexParam
): number => {
  let sum = 0;
  param.guests.forEach((guest: any) => {
    sum += Number(guest.numberOfAdult);
  });
  return sum;
};
export const hotelIndexParamTotalNumberOfChildren = (
  param: HotelIndexParam
): number => {
  let sum = 0;
  param.guests.forEach((guest: any) => {
    sum += Number(guest.agesOfChildren ? guest.agesOfChildren.length : 0);
  });
  return sum;
};
export const hotelIndexParamIsSelectionMode = (
  param: HotelIndexParam
): boolean => {
  return !!param.lId;
};

// HotelShowParam
export const hotelShowParamToFormValues = (param: HotelShowParam): any => {
  const guestsParam: any = guestsToFormValues(param.guests);
  return decamelizeKeys({
    ...param,
    guests: guestsParam,
    numberOfRooms: guestsParam.length,
  });
};
export const hotelShowParamToUrlParam = (param: HotelShowParam): string => {
  return stringify(decamelizeKeys(hotelShowParamToParam(param)), {
    arrayFormat: "indices",
  });
};
export const hotelShowParamToApiQueryParam = (
  param: HotelShowParam
): string => {
  return stringify(decamelizeKeys(hotelShowParamToParam(param)), {
    arrayFormat: "brackets",
  });
};
export const hotelShowParamToParam = (param: HotelShowParam): any => {
  const guestsParam: any = hotelShowParamToGuestsParam(param);
  return decamelizeKeys({
    ...param,
    guests: guestsParam,
    numberOfRooms: guestsParam.length,
  });
};
export const hotelShowParamToGuestsParam = (param: HotelShowParam): any => {
  const guests: any =
    param.guests.length > 0
      ? param.guests
      : [{ numberOfAdult: 2, agesOfChildren: [] }];
  return guests.map((guest: any) => {
    return {
      ...guest,
      numberOfChildren:
        guest.agesOfChildren.length > 0 ? guest.agesOfChildren.length : 0,
    };
  });
};
export const hotelShowParamTotalNumberOfAdult = (
  param: HotelShowParam
): number => {
  let sum = 0;
  param.guests.forEach((guest: any) => {
    sum += Number(guest.numberOfAdult);
  });
  return sum;
};
export const hotelShowParamTotalNumberOfChildren = (
  param: HotelShowParam
): number => {
  let sum = 0;
  param.guests.forEach((guest: any) => {
    sum += Number(guest.agesOfChildren ? guest.agesOfChildren.length : 0);
  });
  return sum;
};
export const hotelShowParamIsSelectionMode = (
  param: HotelShowParam
): boolean => {
  return !!param.lId;
};

// FlightIndexParam
const expirationSecond = 1800;
export const flightIndexParamToSearchFormValues = (
  param: FlightIndexParam
): any => {
  const baseParam = {
    cacheId: param.cacheId,
    page: param.page,
    cabinClass: param.cabinClass,
    flightType: param.flightType,
    traveler: travelerToFormValues(param.traveler),
    guests: flightGuestsToFormValues(param.guests),
    searchType: param.searchType,
    dynamicPackageId: param.dynamicPackageId || null,
    searchGroupId: param.searchGroupId || null,
    lId: param.lId,
    qCId: param.qCId,
    dynamicPackagePreReservationId: param.dynamicPackagePreReservationId,
  };

  if (!baseParam.traveler) {
    delete baseParam.traveler;
  }
  if (!baseParam.guests) {
    delete baseParam.guests;
  }

  if (param.baggageAlert) {
    baseParam["baggageAlert"] = param.baggageAlert;
  }

  switch (param.flightType) {
    case FlightTypes.oneWay:
      return decamelizeKeys({
        ...baseParam,
        departureDate: param.departureDate,
        destination: param.destination,
        destinationId: param.destinationId,
        destinationType: param.destinationType,
        origin: param.origin,
        originId: param.originId,
        originType: param.originType,
      });
    case FlightTypes.roundTrip:
      return decamelizeKeys({
        ...baseParam,
        departureDate: param.departureDate,
        returnDate: param.returnDate,
        destination: param.destination,
        destinationId: param.destinationId,
        destinationType: param.destinationType,
        origin: param.origin,
        originId: param.originId,
        originType: param.originType,
      });
    case FlightTypes.multiCity:
      return decamelizeKeys({
        ...baseParam,
        itineraries: param.itineraries,
      });
  }
  return baseParam;
};
const travelerToFormValues = (travelerParam?: any): any => {
  if (!travelerParam?.numberOfAdult) {
    return null;
  }
  if (travelerParam && travelerParam.agesOfChildren) {
    const agesOfChildren = travelerParam.agesOfChildren?.map((age) => {
      return { age: age };
    });
    return {
      ...travelerParam,
      agesOfChildren: agesOfChildren,
      numberOfChildren:
        travelerParam.agesOfChildren.length > 0
          ? travelerParam.agesOfChildren.length
          : 0,
    };
  } else {
    return travelerParam;
  }
};
const flightGuestsToFormValues = (guestsParam?: any): any => {
  if (!guestsParam?.length) {
    return null;
  }
  const guests: any =
    guestsParam.length > 0
      ? guestsParam.map((guest) => {
          return {
            ...guest,
            agesOfChildren: guest.agesOfChildren?.map((age) => {
              return { age: age };
            }),
          };
        })
      : [{ numberOfAdult: 2, agesOfChildren: [] }];
  return guests.map((guest: any) => {
    return {
      ...guest,
      numberOfChildren:
        guest.agesOfChildren?.length > 0 ? guest.agesOfChildren.length : 0,
    };
  });
};
export const flightIndexParamToParam = (param: FlightIndexParam): any => {
  // DPの場合guestsの形式を使用するため
  // travelerとguests両方の形式に対応している
  const baseParam = {
    cacheId: param.cacheId,
    page: param.page,
    cabinClass: param.cabinClass,
    flightType: param.flightType,
    traveler: flightIndexParamToTravelersParam(param),
    guests: flightIndexParamToGuestsParam(param),
    searchType: param.searchType,
    dynamicPackageId: param.dynamicPackageId || null,
    searchGroupId: param.searchGroupId || null,
    lId: param.lId,
    qCId: param.qCId,
    dynamicPackagePreReservationId: param.dynamicPackagePreReservationId,
  };

  if (!baseParam.traveler) {
    delete baseParam.traveler;
  }
  if (!baseParam.guests) {
    delete baseParam.guests;
  }

  if (param.baggageAlert) {
    baseParam["baggageAlert"] = param.baggageAlert;
  }

  switch (param.flightType) {
    case FlightTypes.oneWay:
      return decamelizeKeys({
        ...baseParam,
        departureDate: param.departureDate,
        destination: param.destination,
        destinationId: param.destinationId,
        destinationType: param.destinationType,
        origin: param.origin,
        originId: param.originId,
        originType: param.originType,
      });
    case FlightTypes.roundTrip:
      return decamelizeKeys({
        ...baseParam,
        departureDate: param.departureDate,
        returnDate: param.returnDate,
        destination: param.destination,
        destinationId: param.destinationId,
        destinationType: param.destinationType,
        origin: param.origin,
        originId: param.originId,
        originType: param.originType,
      });
    case FlightTypes.multiCity:
      return decamelizeKeys({
        ...baseParam,
        itineraries: param.itineraries,
      });
  }
  return baseParam;
};
export const flightIndexParamToTravelersParam = (
  param: FlightIndexParam
): any => {
  if (!param.traveler?.numberOfAdult) {
    return null;
  }
  if (param.traveler && param.traveler.agesOfChildren) {
    return {
      ...param.traveler,
      numberOfChildren:
        param.traveler.agesOfChildren.length > 0
          ? param.traveler.agesOfChildren.length
          : 0,
    };
  } else {
    return param.traveler;
  }
};
export const flightIndexParamToGuestsParam = (param: FlightIndexParam): any => {
  if (!param.guests?.length) {
    return null;
  }
  const guests: any =
    param.guests?.length > 0
      ? param.guests
      : [{ numberOfAdult: 2, agesOfChildren: [] }];
  return guests.map((guest: any) => {
    return {
      ...guest,
      numberOfChildren:
        guest.agesOfChildren?.length > 0 ? guest.agesOfChildren.length : 0,
    };
  });
};
export const flightIndexParamToAdvancedParam = (
  param: FlightIndexParam
): any => {
  const initialDepartureHour: any = { from: 0, to: 24 };
  return decamelizeKeys({
    minPrice: param.minPrice,
    maxPrice: param.maxPrice,
    maxStop: param.maxStop,
    departureHour1: param.departureHour1 || initialDepartureHour,
    departureHour2: param.departureHour2 || initialDepartureHour,
    departureHour3: param.departureHour3 || initialDepartureHour,
    departureHour4: param.departureHour4 || initialDepartureHour,
    departureHour5: param.departureHour5 || initialDepartureHour,
    lastDepartureHour: param.lastDepartureHour || initialDepartureHour,
    lastArrivalHour: param.lastArrivalHour || initialDepartureHour,
    minLayover: param.minLayover,
    maxLayover: param.maxLayover,
    airlineCodes: param.airlineCodes,
    departureAirportCodes: param.departureAirportCodes,
    transitAirportCodes: param.transitAirportCodes,
    sortBy: param.sortBy,
    freeBaggageType: param.freeBaggageType,
    notUseLcc: param.notUseLcc,
    lId: param.lId,
    qCId: param.qCId,
  });
};
export const flightIndexParamToUrlParam = (param: FlightIndexParam): string => {
  return stringify(decamelizeKeys(flightIndexParamToParam(param)), {
    arrayFormat: "indices",
  });
};
export const flightIndexParamTotalNumberOfAdult = (
  param: FlightIndexParam
): number => {
  const isDynamicPackage = param.searchType === SearchTypes.dynamicPackage;
  if (isDynamicPackage) {
    let sum = 0;
    param.guests?.forEach((guest: any) => {
      sum += Number(guest.numberOfAdult);
    });
    return sum;
  } else {
    return Number(param.traveler?.numberOfAdult || 0);
  }
};
export const flightIndexParamTotalNumberOfChildren = (
  param: FlightIndexParam
): number => {
  const isDynamicPackage = param.searchType === SearchTypes.dynamicPackage;
  if (isDynamicPackage) {
    let sum = 0;
    param.guests?.forEach((guest: any) => {
      sum += Number(guest.agesOfChildren ? guest.agesOfChildren.length : 0);
    });
    return sum;
  } else {
    return param.traveler.agesOfChildren?.length;
  }
};
export const isCacheIdExpired = (cacheId: string): boolean => {
  if (!cacheId) {
    return true;
  }
  const cacheTimeString: string | undefined = cacheId.split("-").pop();
  if (cacheTimeString) {
    const cacheTime: number = parseInt(cacheTimeString, 10);
    if ((cacheTime + expirationSecond) * 1000 < new Date().getTime()) {
      return true;
    }
  }
  return false;
};
export const itineraryCount = (param: FlightIndexParam): number => {
  switch (param.flightType) {
    case FlightTypes.oneWay:
      return 1;
    case FlightTypes.roundTrip:
      return 2;
    case FlightTypes.multiCity:
      return param.itineraries.length;
  }
  return 1;
};
const toShortCityName = (name: string): string => {
  return name.replace(/ \(.*$/, "");
};
export const shortOrigin = (param: FlightIndexParam): string => {
  switch (param.flightType) {
    case FlightTypes.oneWay:
      return toShortCityName(param.origin);
    case FlightTypes.roundTrip:
      return toShortCityName(param.origin);
    case FlightTypes.multiCity:
      if (itineraryCount(param) > 0) {
        return toShortCityName(param.itineraries[0].destination);
      } else {
        return "";
      }
  }
  return "";
};
export const shortDestination = (param: FlightIndexParam): string => {
  switch (param.flightType) {
    case FlightTypes.oneWay:
      return toShortCityName(param.destination);
    case FlightTypes.roundTrip:
      return toShortCityName(param.destination);
    case FlightTypes.multiCity:
      if (itineraryCount(param) > 0) {
        return toShortCityName(
          param.itineraries[itineraryCount(param) - 1].destination
        );
      } else {
        return "";
      }
  }
  return "";
};
export const isFlightIndexParamSelectionMode = (
  param: FlightIndexParam
): boolean => {
  return !!param.lId;
};

// FlightShowParam
export const isFlightShowParamSelectionMode = (
  param: FlightShowParam
): boolean => {
  return !!param.lId;
};

/** action export **/
export const {
  initHotelIndexParam,
  initHotelShowParam,
  initFlightIndexParam,
  initFlightShowParam,
  setIsTrackingParameterStored,
} = paramSlice.actions;
