import { Action } from "redux";
import { camelizeKeys } from "humps";
import {
  INIT_CONSULTATION_CONDITION,
  INIT_CONSULTATION_CONDITION_DESCENDING_REGIONS,
} from "main/javascripts/constants/actionType/ConsultationConditionActionType";
import { Region } from "main/javascripts/models/Region";

export interface IConsultationConditionState {
  consultationConditionId: number;
  consultationCondition: any;
  descendingRegions: Region[];
}

interface IInitConsultationCondition extends Action {
  actionType: string;
  payload: any;
}

export type ConsultationConditionActions = IInitConsultationCondition;

const INITIAL_STATE: IConsultationConditionState = {
  consultationConditionId: 0,
  consultationCondition: null,
  descendingRegions: [],
};

export function consultationConditionReducer(
  state: IConsultationConditionState = INITIAL_STATE,
  action: ConsultationConditionActions
): IConsultationConditionState {
  switch (action.type) {
    case INIT_CONSULTATION_CONDITION:
      return {
        ...state,
        ...action.payload.entities,
        consultationConditionId: action.payload.result,
      };
    case INIT_CONSULTATION_CONDITION_DESCENDING_REGIONS: {
      const descendingRegions: Region[] = action.payload.map((region: any) => {
        return new Region(camelizeKeys(region));
      });
      return {
        ...state,
        descendingRegions: descendingRegions,
      };
    }
    default:
      return state;
  }
}
