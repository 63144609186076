import Axios from "axios";
import { camelizeKeys } from "humps";
// import https from "https";

export function createWrapper({
  isMultipart = false,
  isServerSide = false,
  // isCsrf = false,
} = {}): any {
  const config: any = isServerSide
    ? {
        headers: {
          Accept: "application/json",
          "Content-Type": isMultipart
            ? "multipart/form-data"
            : "application/json",
          // 'X-CSRF-Token': isCsrf ? getCsrfToken() : "",
          // "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
        },
        baseURL: process.env.API_BASE_URL,
        timeout: 300000,
        withCredentials: true,
        // httpsAgent: new https.Agent({
        //   rejectUnauthorized: false,
        // }),
      }
    : {
        headers: {
          Accept: "application/json",
          "Content-Type": isMultipart
            ? "multipart/form-data"
            : "application/json",
          // 'X-CSRF-Token': isCsrf ? getCsrfToken() : "",
        },
        baseURL: null, // process.env.BASE_URL,
        // baseURL: 'https://xxxxxxx.ngrok.io', // When testing line liff
        timeout: 300000,
        withCredentials: true,
        // httpsAgent: new https.Agent({
        //   rejectUnauthorized: false,
        // }),
      };

  const wrapper: any = Axios.create(config);

  wrapper.interceptors.response.use(
    (response: any): any => {
      return {
        ...response,
        data: camelizeKeys(response.data),
      };
    },
    (error: any): any => {
      if (error.code === "ECONNABORTED") {
        return Promise.reject({
          ...error,
          response: {
            data: {
              errors: {
                base: ["timeout"],
              },
            },
          },
        });
      } else if (!error.response) {
        return Promise.reject({
          ...error,
          response: {
            data: {
              errors: {
                base: ["error", JSON.stringify(error)],
              },
            },
          },
        });
      }
      return Promise.reject(error);
    }
  );
  return wrapper;
}
