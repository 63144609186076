import * as React from "react";
import { css } from "@emotion/react";
import { FieldError } from "react-hook-form";
import { Label, ILabelProps } from "main/javascripts/components/form/Label";
import { RangeSlider } from "main/javascripts/components/form/rangeSlider/RangeSlider";
import { InputError } from "main/javascripts/components/form/InputError";
import { space } from "main/javascripts/styles/base/spaceStyle";
import {
  fontSize,
  lineHeight,
} from "main/javascripts/styles/base/typographyStyle";

export interface IProps {
  name: string;
  namespace: string;
  label: ILabelProps;
  dataNum: number;
  initialLeft: number; // 位置(割合)
  initialRight: number; // 位置(割合)
  valLeft: any;
  valRight: any;
  onChange(direction: string, value: any): void;
  onClickEnd?: any;
  error?: FieldError;
}

export const RangeSliderBlock: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const {
    name,
    namespace,
    label,
    dataNum,
    initialLeft,
    initialRight,
    valLeft,
    valRight,
    onChange,
    onClickEnd,
    error,
  } = props;
  return (
    <div css={blockStyle}>
      <Label {...label} />
      <div css={valueStyle}>
        {valLeft} - {valRight}
      </div>
      <RangeSlider
        initialLeft={initialLeft}
        initialRight={initialRight}
        dataNum={dataNum}
        onChange={onChange}
        onClickEnd={onClickEnd}
      />
      {error && (
        <InputError
          namespace={namespace}
          errorMessage={error.message}
          label={name}
        />
      )}
    </div>
  );
};

const blockStyle = css`
  width: 100%;
  padding: calc(${space.atom} - (${lineHeight.body1} - ${fontSize.body}) / 2)
    ${space.atom} ${space.atom2x};
  box-sizing: border-box;
`;
const valueStyle = css`
  padding-bottom: ${space.atom0_5x};
  font-size: ${fontSize.body};
  min-height: 28px;
`;
