import { handleActions } from "redux-actions";
import { SET_ACTIVE_BREAKPOINT } from "main/javascripts/constants/actionType/BreakpointActionType";
import { IBreakpointValue } from "main/javascripts/styles/base/responsiveStyle";

export interface IBreakpointState {
  breakpoint: keyof IBreakpointValue;
  isSetBreakpoint: boolean;
}

const INITIAL_STATE: IBreakpointState = {
  breakpoint: "phone",
  isSetBreakpoint: false,
};

export const breakpointReducer: any = handleActions(
  {
    [SET_ACTIVE_BREAKPOINT]: (
      state: IBreakpointState,
      action: any
    ): IBreakpointState => {
      return {
        ...state,
        breakpoint: action.payload,
        isSetBreakpoint: true,
      };
    },
  },
  INITIAL_STATE
);
