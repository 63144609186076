import * as React from "react";
import { css } from "aphrodite";
import { InputErrorStyle } from "./InputErrorStyle";

export interface IInputErrorProps {
  meta: any;
  isIndependent?: boolean; // form要素と1対1ではない場合にtrueにする
  styles?: any;
}

const InputErrorComponent: React.FC<IInputErrorProps> = (
  props: IInputErrorProps
): React.ReactElement<IInputErrorProps> | null => {
  const { meta } = props;
  let error: boolean = meta.touched && meta.error;
  if (props.isIndependent) {
    error = meta.error;
  }
  const submitError: boolean = meta.submitFailed && meta.submitError;
  if (error) {
    return (
      <span className={css(InputErrorStyle.inputError, props.styles)}>
        {meta.error}
      </span>
    );
  }
  if (submitError) {
    return (
      <span className={css(InputErrorStyle.inputError, props.styles)}>
        {meta.submitError}
      </span>
    );
  }
  return null;
};
export const InputError: any = React.memo(InputErrorComponent);
