export function getPropertyObject(object: any): any {
  const newObject: any = {};
  Object.getOwnPropertyNames(object).map((key: string) => {
    newObject[key] = object[key];
  });
  return newObject;
}

export function applyMixins(derivedCtor: any, baseCtors: any[]): any {
  baseCtors.forEach((baseCtor: any) => {
    Object.getOwnPropertyNames(baseCtor.prototype).forEach((name: any) => {
      derivedCtor.prototype[name] = baseCtor.prototype[name];
    });
  });
}

export function isObjectEmpty(object: any): boolean {
  return Object.keys(object).length === 0;
}

export function objectToFormData(obj: any, form: any, namespace: string): any {
  const fd: any = form || new FormData();
  let formKey: string;

  Object.keys(obj).forEach((property: string) => {
    if (Object.prototype.hasOwnProperty.call(obj, property)) {
      if (namespace) {
        formKey = `${namespace}[${property}]`;
      } else {
        formKey = property;
      }

      if (
        typeof obj[property] === "object" &&
        !(obj[property] instanceof File)
      ) {
        objectToFormData(obj[property], fd, property);
      } else {
        // if it's a string or a File object
        fd.append(formKey, obj[property]);
      }
    }
  });

  return fd;
}

export function clone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function removeEmpty(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => v != null && v != "")
  );
}
