import { Action } from "redux";
import {
  FETCHING_POSTS_SUCCESS,
  INIT_POST,
  INIT_POSTS,
  INIT_REGION,
} from "main/javascripts/constants/actionType/PostActionType";
import { Region } from "main/javascripts/models/Region";
import { Post } from "../models/Post";

export interface IPostState {
  postId: number | undefined;
  postIds: number[];
  page: number;
  numPerPage: number;
  totalNum: number;
  post: any;
  region: Region | null;
  tag?: { id: number; name: string };
  rankings: Post[];
  popularTags: string[];
}

interface IInitializingPostAction extends Action {
  actionType: string;
  payload: any;
}

export type PostActions = IInitializingPostAction;

const INITIAL_STATE: IPostState = {
  postId: undefined,
  postIds: [],
  page: 0,
  numPerPage: 0,
  totalNum: 0,
  region: null,
  tag: null,
  rankings: [],
  popularTags: [],
  // normalizr
  post: {},
};

export function postReducer(
  state: IPostState = INITIAL_STATE,
  action: PostActions
): IPostState {
  switch (action.type) {
    case INIT_POST:
      return {
        ...state,
        ...action.payload.entities,
        postId: action.payload.result,
      };
    case INIT_POSTS:
      return {
        ...state,
        ...action.payload.entities,
        postIds: action.payload.result.posts,
        page: action.payload.result.page,
        numPerPage: action.payload.result.numPerPage,
        totalNum: action.payload.result.totalNum,
        tag: action.payload.result.tag,
        rankings: action.payload.result.rankings,
        popularTags: action.payload.result.popularTags,
      };
    case FETCHING_POSTS_SUCCESS:
      return {
        ...state,
        ...action.payload.entities,
        postIds: action.payload.result.posts,
        page: action.payload.result.page,
        numPerPage: action.payload.result.numPerPage,
        totalNum: action.payload.result.totalNum,
        tag: action.payload.result.tag,
        rankings: action.payload.result.rankings,
        popularTags: action.payload.result.popularTags,
      };
    case INIT_REGION:
      return {
        ...state,
        ...action.payload.entities,
        region: action.payload,
      };
    default:
      return state;
  }
}
