import { SagaIterator } from "redux-saga";
import { call, put, ForkEffect, takeLatest } from "redux-saga/effects";
import { IAction } from "main/javascripts/constants/type/ReduxActions";
import {
  FETCH_FLIGHT_PRE_RESERVATION_REQUEST,
  UPDATE_FLIGHT_PRE_RESERVATION_PARAMETERS_REQUEST,
} from "main/javascripts/constants/actionType/FlightPreReservationActionType";
import {
  fetchFlightPreReservation,
  updateFlightPreReservationParameters,
} from "main/javascripts/api/FlightPreReservationApi";
import {
  fetchFlightPreReservationSuccess,
  fetchFlightPreReservationFailure,
} from "main/javascripts/actions/FlightPreReservations";

function* runFetchFlightPreReservation(action: IAction<any>): SagaIterator {
  try {
    const { data } = yield call(fetchFlightPreReservation, action.payload);
    yield put(fetchFlightPreReservationSuccess(data));
  } catch (e) {
    yield put(
      fetchFlightPreReservationFailure({ errorMessages: e.errorMessages })
    );
  }
}

function* runUpdateFlightPreReservationParameters(
  action: IAction<any>
): SagaIterator {
  try {
    const { data } = yield call(
      updateFlightPreReservationParameters,
      action.payload.flightPreReservationId,
      action.payload.param
    );
    yield put(fetchFlightPreReservationSuccess(data));
  } catch (e) {
    yield put(
      fetchFlightPreReservationFailure({ errorMessages: e.errorMessages })
    );
  }
}

export const flightPreReservationSaga: ForkEffect[] = [
  takeLatest(
    FETCH_FLIGHT_PRE_RESERVATION_REQUEST,
    runFetchFlightPreReservation
  ),
  takeLatest(
    UPDATE_FLIGHT_PRE_RESERVATION_PARAMETERS_REQUEST,
    runUpdateFlightPreReservationParameters
  ),
];
