export interface IFlightShowParam {
  lId?: string | null;
  qCId?: string | null;
}

const defaultFlightShowParam: IFlightShowParam = {
  lId: null,
  qCId: null,
};

export class FlightShowParam implements IFlightShowParam {
  public lId: string | null;
  public qCId: string;

  constructor(param: IFlightShowParam) {
    Object.assign(this, defaultFlightShowParam, param);
  }
  public isSelectionMode(): boolean {
    return !!this.lId;
  }
}
