import { Action } from "redux";
import {
  INIT_RESTAURANT,
  SET_RESTAURANT_IMAGE,
} from "main/javascripts/constants/actionType/RestaurantActionType";

export interface IRestaurantState {
  restaurantId: number;
  restaurant: any;
  image: any;
}

interface IInitializingRestaurantAction extends Action {
  actionType: string;
  payload: any;
}

export type RestaurantActions = IInitializingRestaurantAction;

const INITIAL_STATE: IRestaurantState = {
  restaurantId: 0,
  // normalizr
  restaurant: {},
  image: {},
};

export function restaurantReducer(
  state: IRestaurantState = INITIAL_STATE,
  action: RestaurantActions
): IRestaurantState {
  switch (action.type) {
    case INIT_RESTAURANT:
      return {
        ...state,
        ...action.payload.entities,
        restaurantId: action.payload.result,
      };
    case SET_RESTAURANT_IMAGE:
      Object.keys(action.payload).map((key: any) => {
        state.image[key] = action.payload[key];
      });
      return {
        ...state,
        image: state.image,
      };
    default:
      return state;
  }
}
