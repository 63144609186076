import { normalize } from "normalizr";
import { camelizeKeys } from "humps";
import { ReduxState } from "../reducers";

export function normalizrMiddleware(): any {
  return (_: ReduxState): any =>
    (next: any): any =>
    (action: any): any => {
      const schema: any = action.meta && action.meta.schema;

      let newAction: any = action;
      if (schema && action.payload && !action.error) {
        const normalized: any = normalize(camelizeKeys(action.payload), schema);
        newAction = { ...action, payload: normalized };
      }

      return next(newAction);
    };
}
