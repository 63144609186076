import { createWrapper } from "./AxiosWrapper";
import { PATH_SCOPE } from "../constants/Constants";

export async function fetchFlightOriginDestinationSuggestions(
  params: any
): Promise<any> {
  const {
    text,
    included_code,
    excluded_code,
    exclude_airport,
    include_railway,
    with_country,
  } = params;
  // return createWrapper({ isCsrf: true })
  return createWrapper()
    .get(`${PATH_SCOPE}/api/flights/origin_destinations/suggests`, {
      params: {
        q: text,
        included_code,
        excluded_code,
        exclude_airport,
        include_railway,
        with_country,
      },
    })
    .then(async (response: any): Promise<any> => {
      return Promise.resolve({
        data: response.data,
      });
    })
    .catch(async (error: any): Promise<any> => {
      return Promise.reject({
        errors: error.response.data.errors,
      });
    });
}
