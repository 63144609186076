export interface IFontSize {
  large: string;
  title: string;
  headline: string;
  mediumHeading: string;
  subHeading: string;
  form: string;
  body: string;
  caption: string;
  smallest: string;
}

export const fontSize: IFontSize = {
  // heading
  large: "2.125rem", // 34px(34sp)
  title: "1.5rem", // 24px(24sp)
  headline: "1.3125rem", // 21px(21sp)
  mediumHeading: "1.0625rem", // 17px(sp)
  subHeading: "0.9375rem", // 15px(sp)
  form: "16px", // iOSでfocus時に自動ズームされるのを防ぐために最小の16px指定
  body: "0.9375rem", // 15px(sp)
  caption: "0.8125rem", // 13px(sp)
  smallest: "0.6875rem", // 11px(sp)
};

export interface ILineHeight {
  large: string;
  title: string;
  headline1: string;
  headline2: string;
  mediumHeadline1: string;
  mediumHeadline2: string;
  subHeadline1: string;
  subHeadline2: string;
  body1: string;
  body2: string;
  caption1: string;
  caption2: string;
  caption3: string;
  label: string;
  noSpace: string;
}

export const lineHeight: ILineHeight = {
  // heading
  large: "3.0rem", // 48px(sp)
  title: "2.125rem", // 34px(sp)
  headline1: "1.875rem", // 30px(sp)
  headline2: "1.625rem", // 26px(sp)
  mediumHeadline1: "1.875rem", // 30px(sp)
  mediumHeadline2: "1.625rem", // 26px(sp)
  subHeadline1: "1.875rem", // 30px(sp)
  subHeadline2: "1.625rem", // 26px(sp)
  body1: "1.625rem", // 26px(sp)
  body2: "1.4375rem", // 23px(sp)
  caption1: "1.625rem", // 26px(sp)
  caption2: "1.4375rem", // 23px(sp)
  caption3: "1rem", // 16px(sp)
  label: "1.2rem", // 18px(sp)
  noSpace: "1", // same as font-size
};

export interface IFontWeight {
  thin: 200;
  light: 300;
  regular: 400;
  medium: 600;
  bold: 700;
}

export const fontWeight: IFontWeight = {
  thin: 200,
  light: 300,
  regular: 400,
  medium: 600,
  bold: 700,
};

export interface ILetterSpacing {
  text: string;
  title: string;
}

export const letterSpacing: ILetterSpacing = {
  text: "0.05em",
  title: "0.1em",
};
