import { schema } from "normalizr";
import { HotelArrangementSchema } from "main/javascripts/schemas/HotelArrangementSchema";
import { FlightArrangementItinerarySchema } from "./FlightArrangementItinerarySchema";
import { TravelerSchema } from "./TravelerSchema";

export const ReservationSchema: schema.Entity = new schema.Entity(
  "reservation",
  {
    hotelArrangements: [HotelArrangementSchema],
    flightArrangementItineraries: [FlightArrangementItinerarySchema],
    travelers: [TravelerSchema],
  }
);

export const ReservationsSchema: schema.Object = new schema.Object({
  reservations: [ReservationSchema],
});
