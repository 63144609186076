import {
  createAction,
  ActionFunction1,
  ActionMeta,
  Action,
} from "redux-actions";
import { DynamicPackagePreReservationSchema } from "main/javascripts/schemas/DynamicPackagePreReservationSchema";
import { IDynamicPackagePreReservation } from "main/javascripts/models/DynamicPackagePreReservation";
import {
  INIT_DYNAMIC_PACKAGE_PRE_RESERVATION,
  FETCH_DYNAMIC_PACKAGE_PRE_RESERVATION_FAILURE,
  FETCH_DYNAMIC_PACKAGE_PRE_RESERVATION_REQUEST,
  FETCH_DYNAMIC_PACKAGE_PRE_RESERVATION_SUCCESS,
  SET_DISPLAY_DYNAMIC_PACKAGE_PRE_RESERVATION_CONFIRMATION,
  UPDATE_DYNAMIC_PACKAGE_PRE_RESERVATION_PARAMETERS_REQUEST,
} from "main/javascripts/constants/actionType/DynamicPackagePreReservationActionType";

interface IInitDynamicPackagePreReservationMeta {
  schema: typeof DynamicPackagePreReservationSchema;
}
export const initDynamicPackagePreReservation: ActionFunction1<
  IDynamicPackagePreReservation,
  ActionMeta<
    IDynamicPackagePreReservation,
    IInitDynamicPackagePreReservationMeta
  >
> = createAction<
  IDynamicPackagePreReservation,
  IInitDynamicPackagePreReservationMeta,
  IDynamicPackagePreReservation
>(
  INIT_DYNAMIC_PACKAGE_PRE_RESERVATION,
  (
    dynamicPackagePreReservation: IDynamicPackagePreReservation
  ): IDynamicPackagePreReservation => {
    return dynamicPackagePreReservation;
  },
  (): IInitDynamicPackagePreReservationMeta => {
    return {
      schema: DynamicPackagePreReservationSchema,
    };
  }
);

export const fetchDynamicPackagePreReservationRequest: any = createAction(
  FETCH_DYNAMIC_PACKAGE_PRE_RESERVATION_REQUEST
);
export const fetchDynamicPackagePreReservationFailure: any = createAction(
  FETCH_DYNAMIC_PACKAGE_PRE_RESERVATION_FAILURE
);
interface IFetchDynamicPackagePreReservationMeta {
  schema: typeof DynamicPackagePreReservationSchema;
}

export const fetchDynamicPackagePreReservationSuccess: ActionFunction1<
  IDynamicPackagePreReservation,
  ActionMeta<
    IDynamicPackagePreReservation,
    IFetchDynamicPackagePreReservationMeta
  >
> = createAction<
  IDynamicPackagePreReservation,
  IFetchDynamicPackagePreReservationMeta,
  IDynamicPackagePreReservation
>(
  FETCH_DYNAMIC_PACKAGE_PRE_RESERVATION_SUCCESS,
  (
    dynamicPackagePreReservation: IDynamicPackagePreReservation
  ): IDynamicPackagePreReservation => {
    return dynamicPackagePreReservation;
  },
  (): IFetchDynamicPackagePreReservationMeta => {
    return {
      schema: DynamicPackagePreReservationSchema,
    };
  }
);

export const updateDynamicPackagePreReservationParametersRequest: any =
  createAction(UPDATE_DYNAMIC_PACKAGE_PRE_RESERVATION_PARAMETERS_REQUEST);

export const setDisplayDynamicPackagePreReservationConfirmation: ActionFunction1<
  boolean,
  Action<boolean>
> = createAction<boolean, boolean>(
  SET_DISPLAY_DYNAMIC_PACKAGE_PRE_RESERVATION_CONFIRMATION,
  (param: boolean): boolean => {
    return param;
  }
);
