import { Action } from "redux";
import {
  SET_SEARCH_HOTEL_CONDITION_VALUES,
  SET_SEARCH_HOTEL_ADVANCED_CONDITION_VALUES,
  SET_SEARCH_HOTEL_SORT_CONDITION_VALUES,
} from "main/javascripts/constants/actionType/SearchHotelActionType";

export interface ISearchHotelState {
  conditionValues: any;
  advancedConditionValues: any;
  sortConditionValues: any;
}

interface IInitializingSearchHotelAction extends Action {
  actionType: string;
  payload: any;
}

export type SearchHotelActions = IInitializingSearchHotelAction;

const INITIAL_STATE: ISearchHotelState = {
  conditionValues: {},
  advancedConditionValues: {},
  sortConditionValues: {},
};

export function searchHotelReducer(
  state: ISearchHotelState = INITIAL_STATE,
  action: SearchHotelActions
): ISearchHotelState {
  switch (action.type) {
    case SET_SEARCH_HOTEL_CONDITION_VALUES:
      return {
        ...state,
        conditionValues: action.payload,
      };
    case SET_SEARCH_HOTEL_ADVANCED_CONDITION_VALUES:
      return {
        ...state,
        advancedConditionValues: action.payload,
      };
    case SET_SEARCH_HOTEL_SORT_CONDITION_VALUES:
      return {
        ...state,
        sortConditionValues: action.payload,
      };
    default:
      return state;
  }
}
