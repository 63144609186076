export interface IRequestState {
  isLoading: boolean;
  errors: string[];
  errorMessages: string[];
}

const INITIAL_STATE: IRequestState = {
  isLoading: false,
  errors: [],
  errorMessages: [],
};

export function requestReducer(
  state: IRequestState = INITIAL_STATE,
  action: any
): IRequestState {
  if (action.type.match(/.+_REQUEST/)) {
    return {
      ...state,
      isLoading: true,
      errors: [],
      errorMessages: [],
    };
  } else if (action.type.match(/.+_SUCCESS/)) {
    return {
      ...state,
      isLoading: false,
      errors: [],
      errorMessages: [],
    };
  } else if (action.type.match(/.+_FAILURE/)) {
    return {
      ...state,
      isLoading: false,
      errors: action.errors || [],
      errorMessages: action.errorMessages || [],
    };
  }
  return state;
}
