import { createSlice, createSelector } from "@reduxjs/toolkit";
import { Locale } from "main/javascripts/types/locale";

const key = "locale";

const initialState: Locale = {
  locale: "ja",
};

/** slice **/
export const localeSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    setLocale: (state, action) => {
      state.locale = action.payload;
    },
  },
});

/** selector **/
const stateSelector = (state: { [key]: Locale }) => state[key];

export const localeSelector = createSelector(
  stateSelector,
  (state) => state.locale
);

// action export
export const { setLocale } = localeSlice.actions;
