import { handleActions } from "redux-actions";
import { INIT_CONSTANTS } from "main/javascripts/constants/actionType/ConstantActionType";

export interface IConstantState {
  facebookAppId: string;
}

const INITIAL_STATE: IConstantState = {
  facebookAppId: "",
};

export const constantReducer: any = handleActions(
  {
    [INIT_CONSTANTS]: (state: IConstantState, action: any): IConstantState => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  INITIAL_STATE
);
