import {
  createSlice,
  createSelector,
  createAsyncThunk,
  PayloadAction,
  createEntityAdapter,
  current,
} from "@reduxjs/toolkit";
import { stringify } from "qs";
import { camelizeKeys, decamelizeKeys, camelize } from "humps";
import isEqual from "react-fast-compare";
import { createWrapper } from "main/javascripts/api/AxiosWrapper";
import { ErrorResponse } from "main/javascripts/types/errorResponse";
import {
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from "main/javascripts/utils/sliceUtil";
import { RootState } from "main/javascripts/store";
import { Flight } from "main/javascripts/types/flight";
import { isObjectEmpty, removeEmpty } from "main/javascripts/utils/ObjectUtil";
import { Airline } from "main/javascripts/types/airline";
import { Airport } from "main/javascripts/types/airport";
import { differenceInMinutes } from "date-fns";
import { parseDate } from "main/javascripts/utils/DateUtil";
import { FlightShowParam } from "../../models/FlightShowParam";
import { FlightIndexParam } from "../../types/flightIndexParam";
// import {
//   flightShowParamFlightPlanIdSelector,
//   flightShowParamFlightRoomIdSelector,
// } from "main/javascripts/slices/paramSlice";

// 旧carouselFlightReducerもflightSliceと統合
const key = "flight";
const adapter = createEntityAdapter<Flight.List>();

const MAX_UPDATED_FLIGHT_COUNT: number =
  process.env.NODE_ENV === "production" ? 180 : 180;

const initialState = adapter.getInitialState({
  flight: null,
  flightId: "",
  page: 0,
  numPerPage: 0,
  totalNum: 0,
  updatedFlightCount: 0,
  isPolling: false,
  isPollingCompleted: false,
  pollingCondition: null,
  airlines: [],
  airports: [],
  departureAirports: [],
  transitAirports: [],
  maxStop: 0,
  maxTotalPriceCents: 0,
  minTotalPriceCents: 0,

  isDisplayedSearchForm: false,
  isDisplayedFlightDetailModal: false,
  isPushedFlightDetailModalUrl: false,

  loading: false,
  errors: null,
});

/** Async **/
export const fetchFlightsIndexPage = createAsyncThunk<
  {
    param: FlightIndexParam;
    flights: Flight.List[];
    numPerPage: number;
    page: number;
    totalNum: number;
    facebookAppId: string;
  },
  {
    params: any;
    isServerSide?: boolean;
    headers?: any;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/fetchFlightsIndexPage`, async (args, { rejectWithValue }) => {
  try {
    const { params, isServerSide = false, headers } = args;
    const param: string = stringify(decamelizeKeys(params), {
      arrayFormat: "brackets",
    });
    const url = `/api/page/flights.json?${param}`;
    const result = await createWrapper({ isServerSide }).get(url, {
      headers,
    });
    return {
      param: result.data.param || {},
      flights: result.data.flights || [],
      numPerPage: result.data.numPerPage || 20,
      page: result.data.page || 1,
      totalNum: result.data.totalNum || 0,
      facebookAppId: result.data.facebookAppId || "",
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

export const fetchFlightsShowPage = createAsyncThunk<
  {
    carouselFlight: Flight.List;
    param: FlightShowParam;
    facebookAppId: string;
  },
  {
    id: string;
    params: any;
    isServerSide?: boolean;
    headers?: any;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/fetchFlightsShowPage`, async (args, { rejectWithValue }) => {
  try {
    const { id, params, isServerSide = false, headers } = args;
    const param: string = stringify(decamelizeKeys(params), {
      arrayFormat: "brackets",
    });
    const url = `/api/page/flights/${id}.json?${param}`;
    const result = await createWrapper({ isServerSide }).get(url, {
      headers,
    });
    return {
      carouselFlight: result.data.carouselFlight,
      param: result.data.param,
      facebookAppId: result.data.facebookAppId || "",
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

export const fetchCarouselFlightsShowPage = createAsyncThunk<
  {
    carouselFlight: Flight.List;
  },
  {
    id: string;
    params: any;
    isServerSide?: boolean;
    headers?: any;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/fetchCarouselFlightsShowPage`, async (args, { rejectWithValue }) => {
  try {
    const { id, params, isServerSide = false, headers } = args;
    const param: string = stringify(decamelizeKeys(params), {
      arrayFormat: "brackets",
    });
    const url = `/api/carousel_flights/${id}.json?${param}`;
    const result = await createWrapper({ isServerSide }).get(url, {
      headers,
    });
    return {
      carouselFlight: result.data.carouselFlight,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

// ポーリングする場合は必ず事前にsetPollingConditionを実施すること
export const fetchFlights = createAsyncThunk<
  {
    flights: Flight.List[];
    isPolling: boolean;
    page: number;
    numPerPage: number;
    totalNum: number;
    airlines: Airline[];
    airports: Airport[];
    departureAirports: Airport[];
    transitAirports: Airport[];
    maxStop: number;
    maxTotalPriceCents: number;
    minTotalPriceCents: any;
    isPollingRequest: boolean;
    params: any;
  },
  {
    params: any;
    isPollingRequest?: boolean;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/fetchFlights`, async (args, { rejectWithValue }) => {
  try {
    const { params, isPollingRequest = false } = args;
    const param: string = stringify(decamelizeKeys(params), {
      arrayFormat: "brackets",
    });
    const url = `/api/flights.json?${param}`;
    const result = await createWrapper().get(url);
    return {
      flights: result.data.flights,
      isPolling: result.data.isPolling,
      page: result.data.page,
      numPerPage: result.data.numPerPage,
      totalNum: result.data.totalNum,
      airlines: result.data.airlines,
      airports: result.data.airports,
      departureAirports: result.data.departureAirports,
      transitAirports: result.data.transitAirports,
      maxStop: result.data.maxStop,
      maxTotalPriceCents: result.data.maxTotalPriceCents,
      minTotalPriceCents: result.data.minTotalPriceCents,
      isPollingRequest: isPollingRequest,
      params: params,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

export const fetchFlightInfo = createAsyncThunk<
  {
    flight: Flight.List;
  },
  {
    flightId: string;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/fetchFlightInfo`, async (args, { rejectWithValue }) => {
  try {
    const { flightId } = args;
    const url = `/api/flights/${flightId}/flight_info.json`;
    const result = await createWrapper().get(url);
    return {
      flight: result.data,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

/** slice **/
export const flightSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    initFlight: (state, action: PayloadAction<Flight.List>) => {
      state.flightId = action.payload.id;
      state.flight = action.payload;
    },
    initFlightById: (state, action) => {
      const flight = state.entities[action.payload];
      state.flightId = flight.id;
      state.flight = flight;
    },
    initFlights: (state, action) => {
      adapter.setAll(state, action.payload.flights || []);
      state.page = action.payload.page;
      state.numPerPage = action.payload.numPerPage;
      state.totalNum = action.payload.totalNum;
    },
    setDisplayFlightSearchForm: (state, action) => {
      state.isDisplayedSearchForm = action.payload;
    },
    setDisplayFlightDetailModal: (state, action) => {
      state.isDisplayedFlightDetailModal = action.payload;
    },
    setIsPushedFlightDetailModalUrl: (state, action) => {
      state.isPushedFlightDetailModalUrl = action.payload;
    },
    setPollingCondition: (state, action) => {
      state.pollingCondition = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFlights.pending, (state) => {
        state.isPolling = true;
        state.isPollingCompleted = false;
        state.updatedFlightCount = 0;
      })
      .addCase(fetchFlights.fulfilled, (state, action) => {
        // ポーリングの場合paramsが異なるアクションは無視する
        const isPollingRequest = action.payload.isPollingRequest;
        const currentCondition = removeEmpty(current(state.pollingCondition));
        const params = removeEmpty(action.payload.params);
        if (isPollingRequest && !isEqual(currentCondition, params)) {
          return;
        }

        adapter.setAll(state, action.payload.flights || []);
        const updatedFlightCount: number =
          state.updatedFlightCount > MAX_UPDATED_FLIGHT_COUNT
            ? 0
            : state.updatedFlightCount + 1;
        const isPolling: boolean =
          updatedFlightCount === 0 ? false : action.payload.isPolling;
        state.isPolling = isPolling;
        state.isPollingCompleted = !isPolling;
        state.page = action.payload.page;
        // state.numPerPage = action.payload.numPerPage;
        state.totalNum = action.payload.totalNum;
        state.airlines = action.payload.airlines;
        state.airports = action.payload.airports;
        state.departureAirports = action.payload.departureAirports;
        state.transitAirports = action.payload.transitAirports;
        state.maxStop = action.payload.maxStop;
        state.maxTotalPriceCents = action.payload.maxTotalPriceCents;
        state.minTotalPriceCents = action.payload.minTotalPriceCents;
        state.updatedFlightCount = updatedFlightCount;
      })
      .addCase(fetchFlights.rejected, (state, _action) => {
        state.isPolling = false;
        state.isPollingCompleted = true;
        state.page = 1;
      })
      .addCase(fetchFlightInfo.fulfilled, (state, action) => {
        const newFlight = action.payload.flight;
        if (newFlight && !isObjectEmpty(newFlight)) {
          state.flight = newFlight;
        }
      })
      // 一旦slice単位で共通化
      .addMatcher(isPendingAction(key), (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addMatcher(isFulfilledAction(key), (state) => {
        state.loading = false;
      })
      .addMatcher(isRejectedAction(key), (state, action) => {
        state.errors = action.payload;
        state.loading = false;
      });
  },
});

/** selector **/
export const {
  selectById: flightByIdSelector,
  selectIds: flightIdsSelector,
  selectEntities: flightEntitiesSelector,
  selectAll: flightsSelector,
  selectTotal: totalFlightsSelector,
} = adapter.getSelectors((state: RootState) => state.flight);

const stateSelector = (state: { [key]: Flight.FlightEntityState }) =>
  state[key];

export const flightSelector = createSelector(
  stateSelector,
  (state) => state.flight
);

export const flightIdSelector = createSelector(
  stateSelector,
  (state) => state.flightId
);

export const pageSelector = createSelector(
  stateSelector,
  (state) => state.page
);

export const numPerPageSelector = createSelector(
  stateSelector,
  (state) => state.numPerPage
);

export const totalNumSelector = createSelector(
  stateSelector,
  (state) => state.totalNum
);

export const isPollingSelector = createSelector(
  stateSelector,
  (state) => state.isPolling
);

export const isPollingCompletedSelector = createSelector(
  stateSelector,
  (state) => state.isPollingCompleted
);

export const airlinesSelector = createSelector(
  stateSelector,
  (state) => state.airlines
);

export const airportsSelector = createSelector(
  stateSelector,
  (state) => state.airports
);

export const departureAirportsSelector = createSelector(
  stateSelector,
  (state) => state.departureAirports
);

export const transitAirportsSelector = createSelector(
  stateSelector,
  (state) => state.transitAirports
);

export const maxStopSelector = createSelector(
  stateSelector,
  (state) => state.maxStop
);

export const maxTotalPriceCentsSelector = createSelector(
  stateSelector,
  (state) => state.maxTotalPriceCents
);

export const minTotalPriceCentsSelector = createSelector(
  stateSelector,
  (state) => state.minTotalPriceCents
);

export const isDisplayedSearchFormSelector = createSelector(
  stateSelector,
  (state) => state.isDisplayedSearchForm
);

export const isDisplayedFlightDetailModalSelector = createSelector(
  stateSelector,
  (state) => state.isDisplayedFlightDetailModal
);

export const isPushedFlightDetailModalUrlSelector = createSelector(
  stateSelector,
  (state) => state.isPushedFlightDetailModalUrl
);

export const errorsSelector = createSelector(
  stateSelector,
  (state) => state.errors
);

export const loadingSelector = createSelector(
  stateSelector,
  (state) => state.loading
);

/** action export **/
export const {
  initFlight,
  initFlightById,
  initFlights,
  setDisplayFlightSearchForm,
  setDisplayFlightDetailModal,
  setIsPushedFlightDetailModalUrl,
  setPollingCondition,
} = flightSlice.actions;

// Flight
export const hasAdditionalBaggage = (flight: Flight.List): boolean => {
  return !!flight.additionalBaggageItems.find(
    (baggageOption: Flight.BaggageOption) =>
      !isBaggageOptionEmpty(baggageOption)
  );
};

// Flight.Itinerary
export const isTotalDurationOver1Hour = (
  itinerary: Flight.Itinerary
): boolean => {
  return itinerary.duration >= 60;
};
export const totalDurationMinutes = (itinerary: Flight.Itinerary): number => {
  return itinerary.duration % 60;
};
export const totalDurationHours = (itinerary: Flight.Itinerary): number => {
  return Math.floor(itinerary.duration / 60);
};
export const firstFlightDetail = (
  itinerary: Flight.Itinerary
): Flight.Detail => {
  return itinerary.flightDetails[0];
};
export const lastFlightDetail = (
  itinerary: Flight.Itinerary
): Flight.Detail => {
  return itinerary.flightDetails[itinerary.flightDetails.length - 1];
};
export const getNumberOfConnections = (itinerary: Flight.Itinerary): number => {
  return getFlightDetails(itinerary).length - 1;
};
export const getFlightDetails = (
  itinerary: Flight.Itinerary
): Flight.Detail[] => {
  return itinerary.flightDetails.reduce(
    (array: Flight.Detail[], detail: Flight.Detail) => {
      const newDetails: Flight.Detail[] = parseTechnicalStops(detail);
      return array.concat(newDetails);
    },
    []
  );
};

// Flight.Detail
export const camelizedCabinClass = (flightDetail: Flight.Detail): string => {
  return camelize(flightDetail.cabinClass);
};
export const isCodeshare = (flightDetail: Flight.Detail): boolean => {
  return (
    flightDetail.marketingAirlineCode !== flightDetail.operatingAirlineCode
  );
};
export const isDurationOver1Hour = (flightDetail: Flight.Detail): boolean => {
  return flightDetail.duration >= 60;
};
export const durationMinutes = (flightDetail: Flight.Detail): number => {
  return flightDetail.duration % 60;
};
export const durationHours = (flightDetail: Flight.Detail): number => {
  return Math.floor(flightDetail.duration / 60);
};
export function parseTechnicalStops(detail: Flight.Detail): Flight.Detail[] {
  const parsedDetails: Flight.Detail[] = [];
  const technicalStops: any[] = detail.technicalStops;
  if (!technicalStops || technicalStops.length === 0) {
    parsedDetails.push(detail);
    return parsedDetails;
  }
  const stopCount: number = technicalStops.length;
  technicalStops.map((technicalStop: any, index: number) => {
    if (index === 0) {
      // first
      parsedDetails.push({
        ...detail,
        arrivalAirportName: technicalStop.airportName,
        arrivalAirportShortName: technicalStop.airportShortName,
        arrivalAirportCode: technicalStop.airportCode,
        arrivalDate: technicalStop.arrivalDate,
        arrivalTime: technicalStop.arrivalTime,
        technicalStops: [],
        // duration: differenceInMinutes(
        //   `${technicalStop.arrivalDate} ${technicalStop.arrivalTime}`,
        //   `${detail.departureDate} ${detail.departureTime}`,
        // ),
        duration: 0,
      });
    }
    if (index > 0 && stopCount > 1) {
      // others
      const prevStop: any = technicalStops[index - 1];
      const layover: number = differenceInMinutes(
        parseDate(`${prevStop.departureDate} ${prevStop.departureTime}`),
        parseDate(`${prevStop.arrivalDate} ${prevStop.arrivalTime}`)
      );
      parsedDetails.push({
        ...detail,
        departureAirportName: prevStop.airportName,
        departureAirportShortName: prevStop.airportShortName,
        departureAirportCode: prevStop.airportCode,
        departureDate: prevStop.departureDate,
        departureTime: prevStop.departureTime,
        arrivalAirportName: technicalStop.airportName,
        arrivalAirportShortName: technicalStop.airportShortName,
        arrivalAirportCode: technicalStop.airportCode,
        arrivalDate: technicalStop.arrivalDate,
        arrivalTime: technicalStop.arrivalTime,
        technicalStops: [],
        layover,
        // duration: differenceInMinutes(
        //   `${technicalStop.arrivalDate} ${technicalStop.arrivalTime}`,
        //   `${prevStop.departureDate} ${prevStop.departureTime}`,
        // ),
        duration: 0,
      });
    }
    if (index === stopCount - 1) {
      // last
      const layover: number = differenceInMinutes(
        parseDate(
          `${technicalStop.departureDate} ${technicalStop.departureTime}`
        ),
        parseDate(`${technicalStop.arrivalDate} ${technicalStop.arrivalTime}`)
      );
      parsedDetails.push({
        ...detail,
        departureAirportName: technicalStop.airportName,
        departureAirportShortName: technicalStop.airportShortName,
        departureAirportCode: technicalStop.airportCode,
        departureDate: technicalStop.departureDate,
        departureTime: technicalStop.departureTime,
        technicalStops: [],
        layover,
        // duration: differenceInMinutes(
        //   `${detail.arrivalDate} ${detail.arrivalTime}`,
        //   `${technicalStop.departureDate} ${technicalStop.departureTime}`,
        // ),
        duration: 0,
      });
    }
    return technicalStop;
  });
  return parsedDetails;
}

// Flight.FreeBaggage
export const isFreeBaggageEmpty = (
  freeBaggage: Flight.FreeBaggage
): boolean => {
  return !!freeBaggage.text;
};
export const itineraryIndexText = (freeBaggage: Flight.FreeBaggage): string => {
  return Array.isArray(freeBaggage.itineraryIndex)
    ? freeBaggage.itineraryIndex.join(" + ")
    : String(freeBaggage.itineraryIndex);
};
export function parseFreeBaggage(
  freeBaggageContent: Flight.FreeBaggageContent,
  t: any
): any {
  if (
    !freeBaggageContent ||
    isFreeBaggageContentEmpty(freeBaggageContent) ||
    (Object.keys(freeBaggageContent).length === 0 &&
      freeBaggageContent.constructor === Object)
  ) {
    return t("label:flight.noFreeBaggage");
  } else if (freeBaggageContent.notice === "no_information") {
    return t("label:flight.noFreeBaggageInformation");
  } else if (freeBaggageContent.detailType === "weight") {
    return t("label:flight.weightFreeBaggagePerPerson", {
      weight: freeBaggageContent.weight,
      weightUnit: freeBaggageContent.weightUnit,
    });
  } else if (freeBaggageContent.detailType === "piece_weight") {
    return t("label:flight.quantityWeightFreeBaggagePerPerson", {
      weight: freeBaggageContent.weight,
      weightUnit: freeBaggageContent.weightUnit,
      quantity: freeBaggageContent.quantity,
    });
  }
  return t("label:flight.quantityFreeBaggagePerPerson", {
    quantity: freeBaggageContent.quantity,
  });
}

// Flight.FreeBaggageContent
export const isFreeBaggageContentEmpty = (
  freeBaggageContent: Flight.FreeBaggageContent
): boolean => {
  return !freeBaggageContent.detailType && !freeBaggageContent.notice;
};

// Flight.BaggageOption
export const isBaggageOptionEmpty = (
  baggageOption: Flight.BaggageOption
): boolean => {
  return !baggageOption.options || baggageOption.options.length === 0;
};
