import React, { useEffect, useRef } from "react";
import { css } from "@emotion/react";
import { moreThanBreakpoint } from "main/javascripts/styles/base/responsiveStyle";
import { boxShadow } from "../../../styles/base/shadowStyle";
import { borderColor } from "../../../styles/base/colorStyle";

interface IProps extends React.AllHTMLAttributes<HTMLInputElement> {
  onMove: any;
  onClickEnd?: any;
  x: number;
  position?: string;
}

export const RangeSliderHandle = (props: IProps): React.ReactElement => {
  const { onMove, x, onClickEnd, position } = props;

  const selfElmRef = useRef<HTMLDivElement>();

  useEffect(() => {
    return () => {
      if (selfElmRef.current) {
        selfElmRef.current.removeEventListener("mousedown", onMouseDown);
        selfElmRef.current.removeEventListener("touchstart", onTouchStart);
      }
    };
  }, []);

  const onMouseDown = (e: any) => {
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
    e.preventDefault();
  };

  const onMouseUp = (e: any) => {
    document.removeEventListener("mousemove", onMouseMove);
    document.removeEventListener("mouseup", onMouseUp);
    e.preventDefault();
    if (onClickEnd) {
      onClickEnd();
    }
  };

  const onMouseMove = (e: any) => {
    onMove(e.clientX);
    e.preventDefault();
  };

  const onTouchStart = (e: any) => {
    e.preventDefault();
    document.addEventListener("touchmove", onTouchMove, {
      passive: false,
    });
    document.addEventListener("touchend", onTouchEnd, { passive: false });
  };

  const onTouchMove = (e: any) => {
    if (e.cancelable) {
      e.preventDefault();
    }
    onMove(e.touches[0].clientX);
  };

  const onTouchEnd: () => void = () => {
    document.removeEventListener("touchmove", onTouchMove);
    document.removeEventListener("touchend", onTouchEnd);
    if (onClickEnd) {
      onClickEnd();
    }
  };

  const blockStyles: any =
    position === "left" ? [handleStyle, handleLeftStyle] : handleStyle;
  return (
    <div
      css={blockStyles}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      style={{
        left: `${x * 100}%`,
      }}
      ref={selfElmRef}
    />
  );
};

const handleStyle = css`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: -20px;
  border-radius: 50%;
  background: #fff;
  box-shadow: ${boxShadow.interface};
  border: 1px solid ${borderColor.shadowColor};
  user-select: none;
  cursor: pointer;
  ${moreThanBreakpoint("desktop")} {
    width: 30px;
    height: 30px;
    margin-left: -12px;
  }
`;
const handleLeftStyle = css`
  &:before {
    content: "";
    width: 10px;
    height: 100%;
    position: absolute;
    top: 0;
    left: -10px;
  }
  ${moreThanBreakpoint("desktop")} {
    width: 30px;
    height: 30px;
    margin-left: -18px;
  }
`;
