import { decamelizeKeys } from "humps";
import { IBase, Base, defaultBase } from "./Base";
import { Itinerary } from "./Itinerary";

export interface IMultiCity extends IBase {
  itineraries: Itinerary[];
}

const defaultMultiCity: IMultiCity = {
  ...defaultBase,
  itineraries: [],
};

export class MultiCity extends Base implements IMultiCity {
  public itineraries: Itinerary[];
  constructor(param: IMultiCity) {
    super(param);
    Object.assign(this, defaultMultiCity, param);
  }

  public toParam(): any {
    return decamelizeKeys({
      ...super.toParam(),
      itineraries: this.itineraries,
    });
  }

  public itineraryCount(): number {
    return this.itineraries.length;
  }

  public shortOrigin(): string {
    if (this.itineraryCount() > 0) {
      return super.toShortCityName(this.itineraries[0].destination);
    } else {
      return "";
    }
  }

  public shortDestination(): string {
    if (this.itineraryCount() > 0) {
      return super.toShortCityName(
        this.itineraries[this.itineraryCount() - 1].destination
      );
    } else {
      return "";
    }
  }
}
