import * as React from "react";
import { css } from "aphrodite";
import { RangeSliderStyle } from "./RangeSliderStyle";

interface IProps extends React.AllHTMLAttributes<HTMLInputElement> {
  onMove: any;
  onClickEnd?: any;
  x: number;
  position?: string;
  styles?: any;
}

export class RangeSliderHandle extends React.Component<IProps> {
  public selfElm: any;
  public componentWillUnmount(): void {
    if (this.selfElm) {
      this.selfElm.removeEventListener("mousedown", this.onMouseDown);
      this.selfElm.removeEventListener("touchstart", this.onTouchStart);
    }
  }

  private onMouseDown: (e: any) => void = (e: any) => {
    document.addEventListener("mousemove", this.onMouseMove);
    document.addEventListener("mouseup", this.onMouseUp);
    e.preventDefault();
  };

  private onMouseUp: (e: any) => void = (e: any) => {
    document.removeEventListener("mousemove", this.onMouseMove);
    document.removeEventListener("mouseup", this.onMouseUp);
    if (this.props.onClickEnd) {
      this.props.onClickEnd();
    }
    e.preventDefault();
  };

  private onMouseMove: (e: any) => void = (e: any) => {
    this.props.onMove(e.clientX);
    e.preventDefault();
  };

  private onTouchStart: (e: any) => void = (e: any) => {
    e.preventDefault();
    document.addEventListener("touchmove", this.onTouchMove, {
      passive: false,
    });
    document.addEventListener("touchend", this.onTouchEnd, { passive: false });
  };

  private onTouchMove: (e: any) => void = (e: any) => {
    if (e.cancelable) {
      e.preventDefault();
    }
    this.props.onMove(e.touches[0].clientX);
  };

  private onTouchEnd: () => void = () => {
    document.removeEventListener("touchmove", this.onTouchMove);
    document.removeEventListener("touchend", this.onTouchEnd);
    if (this.props.onClickEnd) {
      this.props.onClickEnd();
    }
  };

  public setSelfElement: (node: any) => void = (node: any) => {
    if (node) {
      this.selfElm = node;
      this.selfElm.addEventListener("mousedown", this.onMouseDown, {
        passive: false,
      });
      this.selfElm.addEventListener("touchstart", this.onTouchStart, {
        passive: false,
      });
    }
  };

  public render(): JSX.Element {
    const { position, styles } = this.props;
    const blockStyle: any =
      position === "left"
        ? [RangeSliderStyle.handle, RangeSliderStyle.handleLeft]
        : RangeSliderStyle.handle;
    return (
      <div
        className={css(blockStyle, styles)}
        style={{
          left: `${this.props.x * 100}%`,
        }}
        ref={this.setSelfElement}
      />
    );
  }
}
