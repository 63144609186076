import { handleActions } from "redux-actions";
import { SET_LOCALE } from "main/javascripts/constants/actionType/LocaleActionType";

export interface ILocaleState {
  locale: string;
}

const INITIAL_STATE: ILocaleState = {
  locale: "ja",
};

export const localeReducer: any = handleActions(
  {
    [SET_LOCALE]: (state: ILocaleState, action: any): ILocaleState => {
      return {
        ...state,
        locale: action.payload,
      };
    },
  },
  INITIAL_STATE
);
