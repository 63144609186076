export function isObjectEmpty(object) {
  return Object.keys(object).length === 0;
}

export function objectToFormData(
  obj,
  form,
  namespace,
  arrayFormat = "indices"
) {
  const fd = form || new FormData();
  let formKey;

  if (!obj) {
    return fd;
  }

  Object.keys(obj).forEach((property) => {
    if (Object.hasOwnProperty.call(obj, property)) {
      if (namespace) {
        formKey = `${namespace}[${property}]`;
      } else {
        formKey = property;
      }
      if (obj[property] instanceof Date) {
        fd.append(formKey, obj[property].toISOString());
      } else if (obj[property] instanceof Array) {
        obj[property].forEach((element, index) => {
          const tempFormKey =
            arrayFormat === "brackets"
              ? `${formKey}[]`
              : `${formKey}[${index}]`;
          if (typeof element === "object" && !(element instanceof File)) {
            objectToFormData(element, fd, tempFormKey);
          } else {
            fd.append(tempFormKey, element);
          }
        });
      } else if (
        typeof obj[property] === "object" &&
        !(obj[property] instanceof File)
      ) {
        objectToFormData(obj[property], fd, formKey);
      } else {
        // if it's a string or a File object
        fd.append(formKey, obj[property]);
      }
    }
  });

  return fd;
}

export function clone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function removeEmpty(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => v != null && v != "")
  );
}
