import {
  createAction,
  ActionFunction1,
  ActionFunctionAny,
  Action,
} from "redux-actions";
import {
  FETCHING_FLIGHT_ORIGIN_DESTINATION_SUGGESTS_REQUEST,
  FETCHING_FLIGHT_ORIGIN_DESTINATION_SUGGESTS_SUCCESS,
  FETCHING_FLIGHT_ORIGIN_DESTINATION_SUGGESTS_FAILURE,
} from "main/javascripts/constants/actionType/FlightOriginDestinationSuggestActionType";

export const fetchFlightOriginDestinationSuggest: ActionFunction1<
  any,
  Action<any>
> = createAction<any, any>(
  FETCHING_FLIGHT_ORIGIN_DESTINATION_SUGGESTS_REQUEST,
  (params: any): any => {
    return params;
  }
);
export const fetchFlightOriginDestinationSuggestSuccess: ActionFunctionAny<
  Action<any>
> = createAction<any, any>(
  FETCHING_FLIGHT_ORIGIN_DESTINATION_SUGGESTS_SUCCESS,
  (flightSuggestions: any): any => {
    return flightSuggestions;
  }
);
export const fetchFlightOriginDestinationSuggestFailure: ActionFunction1<
  any,
  Action<void>
> = createAction<any>(FETCHING_FLIGHT_ORIGIN_DESTINATION_SUGGESTS_FAILURE);

export type DestinationHotelActions =
  | typeof fetchFlightOriginDestinationSuggest
  | typeof fetchFlightOriginDestinationSuggestSuccess
  | typeof fetchFlightOriginDestinationSuggestFailure;
