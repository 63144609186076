import { decamelizeKeys } from "humps";
import { IBase, Base, defaultBase } from "./Base";

export interface IRoundTrip extends IBase {
  departureDate?: string;
  returnDate?: string;
  destination?: string;
  destinationId?: number;
  destinationType?: string;
  origin?: string;
  originId?: number;
  originType?: string;
}

const defaultRoundTrip: IRoundTrip = {
  ...defaultBase,
  departureDate: "",
  returnDate: "",
  destination: "",
  destinationId: 0,
  destinationType: "",
  origin: "",
  originId: 0,
  originType: "",
};

export class RoundTrip extends Base implements IRoundTrip {
  public departureDate: string;
  public returnDate: string;
  public destination: string;
  public destinationId: number;
  public destinationType: string;
  public origin: string;
  public originId: number;
  public originType: string;
  constructor(param: IRoundTrip) {
    super(param);
    Object.assign(this, defaultRoundTrip, param);
  }

  public toParam(): any {
    return decamelizeKeys({
      ...super.toParam(),
      departureDate: this.departureDate,
      returnDate: this.returnDate,
      destination: this.destination,
      destinationId: this.destinationId,
      destinationType: this.destinationType,
      origin: this.origin,
      originId: this.originId,
      originType: this.originType,
    });
  }

  public itineraryCount(): number {
    return 2;
  }

  public shortOrigin(): string {
    return super.toShortCityName(this.origin);
  }

  public shortDestination(): string {
    return super.toShortCityName(this.destination);
  }
}
