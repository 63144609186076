import {
  createSlice,
  createEntityAdapter,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { camelizeKeys } from "humps";
import { createWrapper } from "main/javascripts/api/AxiosWrapper";
import { ErrorResponse } from "main/javascripts/types/errorResponse";
import {
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from "main/javascripts/utils/sliceUtil";
import { Post } from "main/javascripts/types/post";
import { RootState } from "main/javascripts/store";
import { PATH_SCOPE } from "main/javascripts/constants/Constants";
import { Region } from "../../types/region";

const key = "post";
const adapter = createEntityAdapter<Post.Post>();

const initialState: Post.PostEntityState = adapter.getInitialState({
  errors: null,
  post: null,
  postId: undefined,
  page: 0,
  numPerPage: 0,
  totalNum: 0,
  isLastPage: false,
  tag: null,
  region: null,
  rankings: [],
  popularTags: [],
  loading: false,
});

/** Async **/
export const fetchPostsIndexPage = createAsyncThunk<
  {
    posts: Post.Post[];
    totalNum: number;
    page: number;
    numPerPage: number;
    tag: Post.Tag | null;
    rankings: Post.Post[];
    popularTags: Post.Tag[];
    region?: Region.Region;
  },
  {
    param?: string;
    isServerSide?: boolean;
    headers?: any;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/fetchPostsIndexPage`, async (args, { rejectWithValue }) => {
  try {
    const { param, isServerSide = true, headers } = args;
    const url = `${PATH_SCOPE}/api/page/posts.json?${param}`;
    const result = await createWrapper({ isServerSide }).get(url, {
      headers,
    });
    return {
      posts: result.data.posts,
      totalNum: result.data.totalNum,
      page: result.data.page,
      numPerPage: result.data.numPerPage,
      tag: result.data.tag,
      rankings: result.data.rankings,
      popularTags: result.data.popularTags,
      region: result.data.region,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

export const fetchPostsShowPage = createAsyncThunk<
  {
    post: Post.Post;
  },
  {
    id: number;
    param: string;
    isServerSide?: boolean;
    headers?: any;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/fetchPostsShowPage`, async (args, { rejectWithValue }) => {
  try {
    const { id, param, isServerSide = true, headers } = args;
    const url = `${PATH_SCOPE}/api/page/posts/${id}.json?${param}`;
    const result = await createWrapper({ isServerSide }).get(url, {
      headers,
    });
    return {
      post: result.data.post,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

export const putPostAccess = createAsyncThunk<
  {
    data: any;
  },
  {
    id: number;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/putPostAccess`, async (args, { rejectWithValue }) => {
  try {
    const { id } = args;
    const url = `${PATH_SCOPE}/api/posts/${id}/access.json`;
    const result = await createWrapper().put(url);
    return {
      data: result.data,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

/** slice **/
export const postSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    initPosts: (state, action) => {
      adapter.setAll(state, action.payload.posts);
      state.totalNum = action.payload.totalNum;
      state.page = action.payload.page;
      state.numPerPage = action.payload.numPerPage;
      state.tag = action.payload.tag;
      state.rankings = action.payload.rankings;
      state.popularTags = action.payload.popularTags;
      state.region = action.payload.region;
    },
    initPost: (state, action) => {
      state.post = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPostsIndexPage.fulfilled, (state, action) => {
        adapter.setAll(state, action.payload.posts);
        state.totalNum = action.payload.totalNum;
        state.page = action.payload.page;
        state.numPerPage = action.payload.numPerPage;
        state.tag = action.payload.tag;
        state.rankings = action.payload.rankings;
        state.popularTags = action.payload.popularTags;
        state.region = action.payload.region;
      })
      .addCase(fetchPostsShowPage.fulfilled, (state, action) => {
        state.post = action.payload.post;
      })
      // 一旦slice単位で共通化
      .addMatcher(isPendingAction(key), (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addMatcher(isFulfilledAction(key), (state) => {
        state.loading = false;
      })
      .addMatcher(isRejectedAction(key), (state, action) => {
        state.errors = action.payload;
        state.loading = false;
      });
  },
});

/** selector **/
export const {
  selectById: postByIdSelector,
  selectIds: postIdsSelector,
  selectEntities: postEntitiesSelector,
  selectAll: postsSelector,
  selectTotal: totalPostsSelector,
} = adapter.getSelectors((state: RootState) => state.post);

const stateSelector = (state: { [key]: Post.PostEntityState }) => state[key];

export const postSelector = createSelector(
  stateSelector,
  (state) => state.post
);

export const totalNumSelector = createSelector(
  stateSelector,
  (state) => state.totalNum
);

export const pageSelector = createSelector(
  stateSelector,
  (state) => state.page
);

export const numPerPageSelector = createSelector(
  stateSelector,
  (state) => state.numPerPage
);

export const regionSelector = createSelector(
  stateSelector,
  (state) => state.region
);

export const rankingsSelector = createSelector(
  stateSelector,
  (state) => state.rankings
);

export const popularTagsSelector = createSelector(
  stateSelector,
  (state) => state.popularTags
);

export const tagSelector = createSelector(stateSelector, (state) => state.tag);

export const errorsSelector = createSelector(
  stateSelector,
  (state) => state.errors
);

export const loadingSelector = createSelector(
  stateSelector,
  (state) => state.loading
);

/** action export **/
export const { initPosts, initPost } = postSlice.actions;
