import {
  createSlice,
  createSelector,
  createAsyncThunk,
  PayloadAction,
} from "@reduxjs/toolkit";
import { stringify } from "qs";
import { camelizeKeys } from "humps";
import { createWrapper } from "main/javascripts/api/AxiosWrapper";
import { ErrorResponse } from "main/javascripts/types/errorResponse";
import {
  debounced,
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from "main/javascripts/utils/sliceUtil";
// import { RootState } from "main/javascripts/store";
import { FlightSuggestion } from "main/javascripts/types/flightSuggestion";
import { FlightDestinationTypes } from "main/javascripts/constants/FlightDestinationTypes";
import { PATH_SCOPE } from "main/javascripts/constants/Constants";
import { Suggestion } from "../../types/suggestion";

const key = "flightSuggestion";

const initialState: FlightSuggestion.FlightSuggestionEntityState = {
  flightOriginSuggestions: [],
  flightOriginInputData: null,
  flightDestinationSuggestions: [],
  flightDestinationInputData: null,
  flightItineraryOriginSuggestions: {},
  flightItineraryOriginInputData: {},
  flightItineraryDestinationSuggestions: {},
  flightItineraryDestinationInputData: {},
  loading: false,
  errors: null,
};

/** Async **/
export const fetchFlightDestinationSuggestions = createAsyncThunk<
  {
    suggestions: Suggestion[];
    type: (typeof FlightDestinationTypes)[keyof typeof FlightDestinationTypes];
    index: number;
  },
  {
    params: any;
    type: (typeof FlightDestinationTypes)[keyof typeof FlightDestinationTypes];
    index?: number;
  },
  {
    rejectValue: ErrorResponse;
  }
>(
  `${key}/fetchFlightDestinationSuggestions`,
  async (args, { rejectWithValue }) => {
    try {
      const { params, type, index } = args;
      const { text, included_code, excluded_code, exclude_airport } = params;
      const paramString = stringify(
        { q: text, included_code, excluded_code, exclude_airport },
        { arrayFormat: "repeat" }
      );
      const url = `${PATH_SCOPE}/api/flights/origin_destinations/suggests.json?${paramString}`;
      const result = await createWrapper().get(url);
      return {
        suggestions: result.data,
        type: type,
        index: index,
      };
    } catch (err) {
      return rejectWithValue(camelizeKeys(err.response.data));
    }
  }
);
export const debouncedFetchFlightDestinationSuggestions = (arg) => (dispatch) =>
  debounced(fetchFlightDestinationSuggestions, arg, dispatch);

/** slice **/
export const flightSuggestionSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    setFlightOriginInputData: (state, action) => {
      state.flightOriginInputData = action.payload;
      // clear
      if (!action.payload.text) {
        state.flightOriginSuggestions = [];
      }
    },
    setFlightDestinationInputData: (state, action) => {
      state.flightDestinationInputData = action.payload;
      // clear
      if (!action.payload.text) {
        state.flightDestinationSuggestions = [];
      }
    },
    setFlightItineraryOriginInputData: (
      state,
      action: PayloadAction<{ index: number; data: FlightSuggestion.InputData }>
    ) => {
      const index = action.payload.index;
      state.flightItineraryOriginInputData[index] = action.payload.data;
      // clear
      if (!action.payload.data.text) {
        if (state.flightItineraryOriginSuggestions[index]) {
          state.flightItineraryOriginSuggestions[index] = [];
        }
      }
    },
    setFlightItineraryDestinationInputData: (
      state,
      action: PayloadAction<{ index: number; data: FlightSuggestion.InputData }>
    ) => {
      const index = action.payload.index;
      state.flightItineraryDestinationInputData[index] = action.payload.data;
      // clear
      if (!action.payload.data.text) {
        if (state.flightItineraryDestinationSuggestions[index]) {
          state.flightItineraryDestinationSuggestions[index] = [];
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFlightDestinationSuggestions.fulfilled, (state, action) => {
        const index = action.payload.index;
        if (action.payload.type === FlightDestinationTypes.origin) {
          if (index === undefined) {
            state.flightOriginSuggestions = action.payload.suggestions;
          } else {
            state.flightItineraryOriginSuggestions[index] =
              action.payload.suggestions;
          }
        } else {
          if (index === undefined) {
            state.flightDestinationSuggestions = action.payload.suggestions;
          } else {
            state.flightItineraryDestinationSuggestions[index] =
              action.payload.suggestions;
          }
        }
      })
      // 一旦slice単位で共通化
      .addMatcher(isPendingAction(key), (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addMatcher(isFulfilledAction(key), (state) => {
        state.loading = false;
      })
      .addMatcher(isRejectedAction(key), (state, action) => {
        state.errors = action.payload;
        state.loading = false;
      });
  },
});

/** selector **/
const stateSelector = (state: {
  [key]: FlightSuggestion.FlightSuggestionEntityState;
}) => state[key];

export const flightOriginSuggestionsSelector = createSelector(
  stateSelector,
  (state) => state.flightOriginSuggestions
);

export const flightDestinationSuggestionsSelector = createSelector(
  stateSelector,
  (state) => state.flightDestinationSuggestions
);

export const flightOriginInputDataSelector = createSelector(
  stateSelector,
  (state) => state.flightOriginInputData
);

export const flightDestinationInputDataSelector = createSelector(
  stateSelector,
  (state) => state.flightDestinationInputData
);

export const flightItineraryOriginSuggestionsSelector = createSelector(
  [stateSelector, (state, index: number) => index],
  (state, index) => state.flightItineraryOriginSuggestions[index]
);

export const flightItineraryDestinationSuggestionsSelector = createSelector(
  [stateSelector, (state, index: number) => index],
  (state, index) => state.flightItineraryDestinationSuggestions[index]
);

export const flightItineraryOriginInputDataSelector = createSelector(
  [stateSelector, (state, index: number) => index],
  (state, index) => state.flightItineraryOriginInputData?.[index]
);

export const flightItineraryDestinationInputDataSelector = createSelector(
  [stateSelector, (state, index: number) => index],
  (state, index) => state.flightItineraryDestinationInputData?.[index]
);

export const errorsSelector = createSelector(
  stateSelector,
  (state) => state.errors
);

export const loadingSelector = createSelector(
  stateSelector,
  (state) => state.loading
);

/** action export **/
export const {
  setFlightOriginInputData,
  setFlightDestinationInputData,
  setFlightItineraryOriginInputData,
  setFlightItineraryDestinationInputData,
} = flightSuggestionSlice.actions;
