import { Action } from "redux";
import { INIT_REGIONS } from "main/javascripts/constants/actionType/RegionActionType";
import { Country } from "main/javascripts/models/Country";

export interface IRegionState {
  regionId: number | undefined;
  regionIds: number[];
  country: Country | undefined;
  page: number;
  numPerPage: number;
  totalNum: number;
  region: any;
}

interface IInitializingRegionAction extends Action {
  actionType: string;
  payload: any;
}

export type RegionActions = IInitializingRegionAction;

const INITIAL_STATE: IRegionState = {
  regionId: undefined,
  regionIds: [],
  country: undefined,
  page: 0,
  numPerPage: 0,
  totalNum: 0,
  // normalizr
  region: {},
};

export function regionReducer(
  state: IRegionState = INITIAL_STATE,
  action: RegionActions
): IRegionState {
  switch (action.type) {
    case INIT_REGIONS:
      return {
        ...state,
        ...action.payload.entities,
        regionIds: action.payload.result.regions,
        country: action.payload.result.country,
        page: action.payload.result.page,
        numPerPage: action.payload.result.numPerPage,
        totalNum: action.payload.result.totalNum,
      };
    default:
      return state;
  }
}
