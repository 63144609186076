import { Action } from "redux";
import { INIT_COUNTRIES } from "main/javascripts/constants/actionType/CountryActionType";

export interface ICountryState {
  countryIds: number[];
  country: any;
}

interface IInitializingCountryAction extends Action {
  actionType: string;
  payload: any;
}

export type CountryActions = IInitializingCountryAction;

const INITIAL_STATE: ICountryState = {
  countryIds: [],

  // normalizr
  country: {},
};

export function countryReducer(
  state: ICountryState = INITIAL_STATE,
  action: CountryActions
): ICountryState {
  switch (action.type) {
    case INIT_COUNTRIES:
      return {
        ...state,
        ...action.payload.entities,
        countryIds: action.payload.result.countries,
      };
    default:
      return state;
  }
}
