import { Action } from "redux";
import { INIT_MESSAGES } from "main/javascripts/constants/actionType/MessageActionType";

export interface IMessageState {
  messageIds: number[];
  message: any;
}

interface IInitializingMessageAction extends Action {
  actionType: string;
  payload: any;
}

export type MessageActions = IInitializingMessageAction;

const INITIAL_STATE: IMessageState = {
  messageIds: [],

  // normalizr
  message: {},
};

export function messageReducer(
  state: IMessageState = INITIAL_STATE,
  action: MessageActions
): IMessageState {
  switch (action.type) {
    case INIT_MESSAGES:
      return {
        ...state,
        ...action.payload.entities,
        messageIds: action.payload.result.messages,
      };
    default:
      return state;
  }
}
