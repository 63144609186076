import { Action } from "redux";
import {
  INIT_FLIGHT_RESERVATIONS,
  INIT_FLIGHT_RESERVATION,
} from "main/javascripts/constants/actionType/FlightReservationActionType";

export interface IFlightReservationState {
  flightReservationId: number;
  flightReservationIds: number[];

  flightReservation: any;
  flightArrangementItinerary: any;
  traveler: any;
}

interface IInitializingFlightReservationAction extends Action {
  actionType: string;
  payload: any;
}

export type FlightReservationActions = IInitializingFlightReservationAction;

const INITIAL_STATE: IFlightReservationState = {
  flightReservationId: 0,
  flightReservationIds: [],

  // normalizr
  flightReservation: {},
  flightArrangementItinerary: {},
  traveler: {},
};

export function flightReservationReducer(
  state: IFlightReservationState = INITIAL_STATE,
  action: FlightReservationActions
): IFlightReservationState {
  switch (action.type) {
    case INIT_FLIGHT_RESERVATION:
      return {
        ...state,
        ...action.payload.entities,
        flightReservationId: action.payload.result,
      };
    case INIT_FLIGHT_RESERVATIONS:
      return {
        ...state,
        ...action.payload.entities,
        flightReservationIds: action.payload.result.flightReservations || [],
      };
    default:
      return state;
  }
}
