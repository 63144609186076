import { createAction } from "redux-actions";
import {
  CANCEL_HOTEL_ARRANGEMENT_PLAN_SUCCESS,
  CANCEL_HOTEL_ARRANGEMENT_PLAN_REQUEST,
  CANCEL_HOTEL_ARRANGEMENT_PLAN_FAILURE,
} from "main/javascripts/constants/actionType/HotelArrangementPlanActionType";

export const cancelHotelArrangementPlanRequest: any = createAction(
  CANCEL_HOTEL_ARRANGEMENT_PLAN_REQUEST,
  (reservationId: number, hotelArrangementPlanId: number, token: string) => ({
    reservationId,
    hotelArrangementPlanId,
    token,
  })
);
export const cancelHotelArrangementPlanFailure: any = createAction(
  CANCEL_HOTEL_ARRANGEMENT_PLAN_FAILURE
);
export const cancelHotelArrangementPlanSuccess: any = createAction(
  CANCEL_HOTEL_ARRANGEMENT_PLAN_SUCCESS
);
