import { schema } from "normalizr";

export const HotelArrangementPlanSchema: schema.Entity = new schema.Entity(
  "hotelArrangementPlan",
  {}
);

export const HotelArrangementPlansSchema: schema.Object = new schema.Object({
  hotelArrangementPlans: [HotelArrangementPlanSchema],
});
