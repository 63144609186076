// https://material.io/guidelines/layout/responsive-ui.html
// 上記を参照
const minSmall = 600;
const minMedium = 960;
const minLarge = 1280;

export interface IMinBreakpoint {
  small: string;
  medium: string;
  large: string;
  tablet: string;
  desktop: string;
  widescreen: string;
}

export const minBreakpoint: IMinBreakpoint = {
  small: `${minSmall}px`,
  medium: `${minMedium}px`,
  large: `${minLarge}px`,
  tablet: `${minSmall}px`,
  desktop: `${minMedium}px`,
  widescreen: `${minLarge}px`,
};

export interface IMaxBreakpoint {
  xsmall: string;
  small: string;
  medium: string;
  phone: string;
  tablet: string;
  desktop: string;
}

export const maxBreakpoint: IMaxBreakpoint = {
  xsmall: `${minSmall - 1}px`,
  small: `${minMedium - 1}px`,
  medium: `${minLarge - 1}px`,
  phone: `${minSmall - 1}px`,
  tablet: `${minMedium - 1}px`,
  desktop: `${minLarge - 1}px`,
};

export interface IBreakpointValue {
  phone: number;
  tablet: number;
  desktop: number;
  widescreen: number;
}

export const breakpointValue: Map<keyof IBreakpointValue, number> = new Map<
  keyof IBreakpointValue,
  number
>([
  ["tablet", minSmall],
  ["desktop", minMedium],
  ["widescreen", minLarge],
]);

export const moreThanBreakpoint: (currPoint: keyof IMinBreakpoint) => string = (
  currPoint: keyof IMinBreakpoint
): string => `@media screen and (min-width: ${minBreakpoint[currPoint]})`;
export const lessThanBreakpoint: (currPoint: keyof IMaxBreakpoint) => string = (
  currPoint: keyof IMaxBreakpoint
): string => `@media screen and (max-width: ${maxBreakpoint[currPoint]})`;

export interface IBreakpoint {
  xsmall: string;
  small: string;
  medium: string;
  large: string;
  phone: string;
  tablet: string;
  desktop: string;
  widescreen: string;
}

export const equalBreakpoint: any = (currPoint: keyof IBreakpoint): string => {
  if (currPoint === "phone" || currPoint === "xsmall") {
    return `@media screen and (max-width: ${maxBreakpoint[currPoint]})`;
  }
  if (currPoint === "tablet" || currPoint === "small") {
    return `@media screen and (min-width: ${minBreakpoint[currPoint]}) and (max-width: ${maxBreakpoint[currPoint]})`;
  }
  if (currPoint === "desktop" || currPoint === "medium") {
    return `@media screen and (min-width: ${minBreakpoint[currPoint]})`;
  }
  return "";
};

export interface IPixelRatio {
  scale2x: number;
  scale3x: number;
}

export const pixelRatio: IPixelRatio = {
  scale2x: 2,
  scale3x: 3,
};

export const minResolution: any = (
  ratio: keyof IPixelRatio
): string => `@media (-webkit-min-device-pixel-ratio: ${pixelRatio[ratio]}),
    (min-resolution: ${pixelRatio[ratio]}dppx))`;

export const isDesktop: any = (breakpoint: keyof IBreakpointValue): boolean => {
  if (breakpoint === "desktop" || breakpoint === "widescreen") {
    return true;
  } else {
    return false;
  }
};

export const isTablet: any = (breakpoint: keyof IBreakpointValue): boolean => {
  if (breakpoint === "tablet") {
    return true;
  } else {
    return false;
  }
};

export const isPhone: any = (breakpoint: keyof IBreakpointValue): boolean => {
  if (breakpoint === "phone") {
    return true;
  } else {
    return false;
  }
};
