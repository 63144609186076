import { Action } from "redux";
import {
  INIT_FLIGHT_PRE_RESERVATION,
  FETCH_FLIGHT_PRE_RESERVATION_SUCCESS,
  SET_DISPLAY_FLIGHT_PRE_RESERVATION_CONFIRMATION,
} from "main/javascripts/constants/actionType/FlightPreReservationActionType";

export interface IFlightPreReservationState {
  flightPreReservationId: number;
  isConfirm: boolean;

  flightPreReservation: any;
}

interface IInitFlightReservationAction extends Action {
  actionType: string;
  payload: any;
}

export type FlightPreReservationActions = IInitFlightReservationAction;

// const flightPreReservation: any = {
//   0: {
//     id: 0,
//     totalPrice: "99,999円",
//     flightPreArrangements: [{
//       flightDetails: [
//         {
//           airlineCode: "ANA",
//           airlineName: "全日空",
//           flightNumber: "1234",
//           bookingClass: "economy",
//           duration: "925",
//           departureDate: "2018/6/12",
//           departureTime: "17:10",
//           departureAirportCode: "NRT",
//           departureAirportName: "成田国際空港",
//           arrivalDate: "2018/6/12",
//           arrivalTime: "10:35",
//           arrivalAirportCode: "SFO",
//           arrivalAirportName: "サンフランシスコ国際空港",
//         },
//         {
//           airlineCode: "ANA",
//           airlineName: "全日空",
//           flightNumber: "4321",
//           bookingClass: "business",
//           duration: "1045",
//           departureDate: "2018/6/15",
//           departureTime: "11:50",
//           departureAirportCode: "SFO",
//           departureAirportName: "サンフランシスコ国際空港",
//           arrivalDate: "2018/6/16",
//           arrivalTime: "14:35",
//           arrivalAirportCode: "NRT",
//           arrivalAirportName: "成田国際空港",
//         },
//       ],
//     },
//     {
//       flightDetails: [
//         {
//           airlineCode: "ANA",
//           airlineName: "全日空",
//           flightNumber: "2345",
//           bookingClass: "business",
//           duration: "1045",
//           departureDate: "2018/6/15",
//           departureTime: "11:50",
//           departureAirportCode: "SFO",
//           departureAirportName: "サンフランシスコ国際空港",
//           arrivalDate: "2018/6/16",
//           arrivalTime: "14:35",
//           arrivalAirportCode: "NRT",
//           arrivalAirportName: "成田国際空港",
//         },
//       ],
//     }],
//     flightPreArrangementTravelers: [
//       {
//         price: "10,000円",
//         arrangePrice: "500円",
//         flightPreArrangementTravelerPrices: [
//           {
//             price: "15,380円",
//             priceType: "base_fare",
//           },
//           {
//             price: "1,110円",
//             priceType: "tax",
//           },
//           {
//             price: "1,110円",
//             priceType: "surcharge",
//           },
//         ],
//       },
//       {
//         price: "10,000円",
//         arrangePrice: "500円",
//         flightPreArrangementTravelerPrices: [
//           {
//             price: "15,380円",
//             priceType: "base_fare",
//           },
//           {
//             price: "1,110円",
//             priceType: "tax",
//           },
//           {
//             price: "1,110円",
//             priceType: "surcharge",
//           },
//         ],
//       },
//     ],
//   },
// };

const INITIAL_STATE: IFlightPreReservationState = {
  flightPreReservationId: 0,
  isConfirm: false,

  // normalizr
  flightPreReservation: {},
};

export function flightPreReservationReducer(
  state: IFlightPreReservationState = INITIAL_STATE,
  action: FlightPreReservationActions
): IFlightPreReservationState {
  switch (action.type) {
    case INIT_FLIGHT_PRE_RESERVATION:
    case FETCH_FLIGHT_PRE_RESERVATION_SUCCESS:
      return {
        ...state,
        ...action.payload.entities,
        flightPreReservationId: action.payload.result,
      };
    case SET_DISPLAY_FLIGHT_PRE_RESERVATION_CONFIRMATION:
      return {
        ...state,
        isConfirm: action.payload,
      };
    default:
      return state;
  }
}
