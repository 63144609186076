export interface IFlightOriginDestinationSuggestion {
  id: number;
  name: string;
  longName: string;
  destinationType: string;
  label: string;
}

const defaultFlightOriginDestinationSuggestion: IFlightOriginDestinationSuggestion =
  {
    id: 0,
    name: "",
    longName: "",
    destinationType: "",
    label: "",
  };

export class FlightOriginDestinationSuggestion
  implements IFlightOriginDestinationSuggestion
{
  public id: number;
  public name: string;
  public longName: string;
  public destinationType: string;
  public label: string;

  constructor(param: IFlightOriginDestinationSuggestion) {
    Object.assign(this, defaultFlightOriginDestinationSuggestion, param);
  }
}
