import { SagaIterator } from "redux-saga";
import { call, put, ForkEffect, takeLatest } from "redux-saga/effects";
import { IAction } from "main/javascripts/constants/type/ReduxActions";
import { CREATE_LINE_AUTO_SIGN_IN_RQUEST } from "main/javascripts/constants/actionType/LineAutoSignInActionType";
import { createLineUserSignIn } from "main/javascripts/api/LineAutoSignInApi";
import {
  createLineAutoSignInSuccess,
  createLineAutoSignInFailure,
} from "main/javascripts/actions/LineAutoSignIn";

function* runCreateLineAutoSignIn(action: IAction<string>): SagaIterator {
  try {
    const { data } = yield call(createLineUserSignIn, action.payload);
    yield put(createLineAutoSignInSuccess(data));
  } catch (e) {
    yield put(createLineAutoSignInFailure());
  }
}

export const lineAutoSignInSaga: ForkEffect[] = [
  takeLatest(CREATE_LINE_AUTO_SIGN_IN_RQUEST, runCreateLineAutoSignIn),
];
