import { SagaIterator } from "redux-saga";
import { call, put, ForkEffect, takeLatest, delay } from "redux-saga/effects";
import { IAction } from "main/javascripts/constants/type/ReduxActions";
import { FETCHING_FLIGHT_ORIGIN_DESTINATION_SUGGESTS_REQUEST } from "main/javascripts/constants/actionType/FlightOriginDestinationSuggestActionType";
import { fetchFlightOriginDestinationSuggestions } from "main/javascripts/api/FlightOriginDestinationSuggestApi";
import {
  fetchFlightOriginDestinationSuggestSuccess,
  fetchFlightOriginDestinationSuggestFailure,
} from "main/javascripts/actions/FlightOriginDestinationSuggest";

function* runFetchFlightSuggest(action: IAction<string>): SagaIterator {
  yield delay(100);
  try {
    const { data } = yield call(
      fetchFlightOriginDestinationSuggestions,
      action.payload
    );
    yield put(fetchFlightOriginDestinationSuggestSuccess(data));
  } catch (e) {
    yield put(
      fetchFlightOriginDestinationSuggestFailure({
        errorMessages: e.errorMessages,
      })
    );
  }
}

export const flightOriginDestinationSuggestSaga: ForkEffect[] = [
  takeLatest(
    FETCHING_FLIGHT_ORIGIN_DESTINATION_SUGGESTS_REQUEST,
    runFetchFlightSuggest
  ),
];
