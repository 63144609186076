import { createAction, ActionFunction1, ActionMeta } from "redux-actions";
import {
  ReservationsSchema,
  ReservationSchema,
} from "main/javascripts/schemas/ReservationSchema";
import { IReservation } from "main/javascripts/models/Reservation";
import { IReservation as IReservationTraveler } from "main/javascripts/models/FlightArrangementTraveler";
import {
  FETCH_RESERVATION_SUCCESS,
  FETCH_RESERVATION_REQUEST,
  FETCH_RESERVATION_FAILURE,
  INIT_RESERVATIONS,
  INIT_RESERVATION,
  UPDATE_RESERVATION_TRAVELER,
} from "main/javascripts/constants/actionType/ReservationActionType";

interface IInitReservationMeta {
  schema: typeof ReservationSchema;
}
export const initReservation: ActionFunction1<
  IReservation,
  ActionMeta<IReservation, IInitReservationMeta>
> = createAction<IReservation, IInitReservationMeta, IReservation>(
  INIT_RESERVATION,
  (reservation: IReservation): IReservation => {
    return reservation;
  },
  (): IInitReservationMeta => {
    return {
      schema: ReservationSchema,
    };
  }
);

interface IInitReservationsMeta {
  schema: typeof ReservationsSchema;
}

interface IInitReservations {
  reservations: IReservation[];
}
export const initReservations: ActionFunction1<
  IReservation[],
  ActionMeta<IInitReservations, IInitReservationsMeta>
> = createAction<IInitReservations, IInitReservationsMeta, IReservation[]>(
  INIT_RESERVATIONS,
  (reservations: IReservation[]): IInitReservations => {
    return {
      reservations,
    };
  },
  (): IInitReservationsMeta => {
    return {
      schema: ReservationsSchema,
    };
  }
);

export const fetchReservationRequest: any = createAction(
  FETCH_RESERVATION_REQUEST
);
export const fetchReservationFailure: any = createAction(
  FETCH_RESERVATION_FAILURE
);
interface IFetchReservationSuccessMeta {
  schema: typeof ReservationSchema;
}
interface IFetchReservationSuccessPayload {
  reservation: IReservation;
}

export const fetchReservationSuccess: ActionFunction1<
  IFetchReservationSuccessPayload,
  ActionMeta<IFetchReservationSuccessPayload, IFetchReservationSuccessMeta>
> = createAction<
  IFetchReservationSuccessPayload,
  IFetchReservationSuccessMeta,
  IFetchReservationSuccessPayload
>(
  FETCH_RESERVATION_SUCCESS,
  (param: IFetchReservationSuccessPayload): IFetchReservationSuccessPayload => {
    return param;
  },
  (): IFetchReservationSuccessMeta => {
    return {
      schema: ReservationSchema,
    };
  }
);

interface IUpdateReservationTraveler {
  traveler: IReservationTraveler;
}
export const updateReservationTraveler: ActionFunction1<IReservation[]> =
  createAction<IUpdateReservationTraveler, IReservationTraveler>(
    UPDATE_RESERVATION_TRAVELER,
    (traveler: IReservationTraveler): IUpdateReservationTraveler => {
      return {
        traveler,
      };
    }
  );
