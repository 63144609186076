import * as React from "react";
import { useFormContext } from "react-hook-form";

interface IProps extends React.AllHTMLAttributes<HTMLInputElement> {
  name: string;
  value?: string;
}

export const InputHidden: React.FC<IProps> = (
  props: IProps
): React.ReactElement<IProps> => {
  const { name, value } = props;
  const { register } = useFormContext();
  return <input type="hidden" value={value} name={name} {...register(name)} />;
};
