import React, { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { get, useFormContext, useWatch } from "react-hook-form";
import { RangeSliderBlock } from "main/javascripts/components/form/rangeSlider/RangeSliderBlock";
import { RangeSliderOnChangeLeft } from "main/javascripts/components/form/rangeSlider/RangeSlider";
import { FormNamespaces } from "main/javascripts/constants/FormConstants";
import { InputHidden } from "../../form/InputHidden";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { borderColor } from "main/javascripts/styles/base/colorStyle";

export interface IProps {
  initialPriceLeft: number | string;
  initialPriceRight: number | string;
  rangeMinPrice?: number;
  rangeMaxPrice?: number;
  onClickEnd?: any;
  errors?: any;
  styles?: any;
}

const defaultMinPrice = 5000;
const defaultMaxPrice = 30000;
const deltaPrice = 1000;
export const initialMinPrice: number | string = "min-unlimited";
export const initialMaxPrice: number | string = "max-unlimited";

const getSliderData: any = (min: number, max: number, delta: number): any => {
  const dataArray: any[] = [];
  let val: number = min;
  dataArray.push("min-unlimited");
  while (val < max) {
    dataArray.push(val);
    val += delta;
  }
  dataArray.push(max);
  dataArray.push("max-unlimited");
  return dataArray;
};

export const FlightPriceFilterBlock: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const {
    initialPriceLeft,
    initialPriceRight,
    rangeMinPrice,
    rangeMaxPrice,
    onClickEnd,
    errors,
  } = props;
  const { t } = useTranslation(["label"]);

  const minPriceFieldName = "min_price";
  const maxPriceFieldName = "max_price";

  const { control, setValue } = useFormContext();
  const [minPriceValue, maxPriceValue] = useWatch({
    control,
    name: [minPriceFieldName, maxPriceFieldName],
  });

  const [minPrice, setMinPrice] = useState(minPriceValue || initialPriceLeft);
  const [maxPrice, setMaxPrice] = useState(maxPriceValue || initialPriceRight);

  // コールバック内で使用するために保持
  const minPriceRef = useRef(minPrice);
  minPriceRef.current = minPrice;
  const maxPriceRef = useRef(maxPrice);
  maxPriceRef.current = maxPrice;

  const dataRef = useRef([]);

  useEffect(() => {
    setMinPrice(minPriceValue || initialPriceLeft);
  }, [minPriceValue]);

  useEffect(() => {
    setMaxPrice(maxPriceValue || initialPriceRight);
  }, [maxPriceValue]);

  const formattedPrice = (price: number | string): string => {
    return price && typeof price === "number"
      ? t("label:common.price", {
          price: price.toString().replace(/(\d)(?=(\d{3})+$)/g, "$1,"),
        })
      : t("label:common.unspecified");
  };

  const onChangePrice: (direction: string, posRatio: any) => any = (
    direction: string,
    posRatio: any
  ) => {
    const price: number | string =
      dataRef.current[Math.floor(posRatio / (1 / dataRef.current.length))];
    if (direction === RangeSliderOnChangeLeft) {
      setMinPrice(price);
    } else {
      setMaxPrice(price);
    }
  };

  const onClickEndPrice: (direction: string) => any = (direction: string) => {
    if (direction === RangeSliderOnChangeLeft) {
      setValue(minPriceFieldName, minPriceRef.current);
    } else {
      setValue(maxPriceFieldName, maxPriceRef.current);
    }
    if (onClickEnd) {
      onClickEnd();
    }
  };

  const getMinPriceIndex: (data: any[], value: number | string) => number = (
    data: any[],
    value: number | string
  ) => {
    let index: number = data.indexOf(value);
    if (index === -1) {
      index =
        data.findIndex(
          (price: number | string) =>
            typeof price !== "string" && Number(value) < price
        ) - 1;
      if (index < 0) {
        index = 0;
      }
    }
    return index;
  };

  const getMaxPriceIndex: (data: any[], value: number | string) => number = (
    data: any[],
    value: number | string
  ) => {
    let index: number = data.indexOf(value);
    if (index === -1) {
      index = data.findIndex(
        (price: number | string) =>
          typeof price !== "string" && Number(value) < price
      );
      if (index < 0) {
        index = data.length - 1;
      }
    }
    return index;
  };

  const renderRangeSlider: () => JSX.Element = () => {
    const dataMinPrice: number = rangeMinPrice || defaultMinPrice;
    const dataMaxPrice: number = rangeMaxPrice || defaultMaxPrice;
    dataRef.current = getSliderData(dataMinPrice, dataMaxPrice, deltaPrice);
    const data: any = dataRef.current;

    let initialLeftValue: number | string = minPriceValue
      ? minPriceValue
      : initialPriceLeft;
    let initialRightValue: number | string = maxPriceValue
      ? maxPriceValue
      : initialPriceRight;
    initialLeftValue = Number(initialLeftValue)
      ? Number(initialLeftValue)
      : initialLeftValue;
    initialRightValue = Number(initialRightValue)
      ? Number(initialRightValue)
      : initialRightValue;
    const initialLeft: number =
      getMinPriceIndex(data, initialLeftValue) / data.length;
    const initialRight: number =
      getMaxPriceIndex(data, initialRightValue) / data.length +
      0.999 / data.length;
    const labelText: string = t("label:common.totalPrice");

    const minPriceError = get(errors, minPriceFieldName);
    const maxPriceError = get(errors, maxPriceFieldName);
    const error = minPriceError || maxPriceError;
    const name = maxPriceError ? maxPriceFieldName : minPriceFieldName;
    return (
      <RangeSliderBlock
        namespace={FormNamespaces.flight}
        name={name}
        label={{
          namespace: FormNamespaces.flight,
          label: labelText,
        }}
        initialLeft={initialLeft}
        initialRight={initialRight}
        dataNum={data.length}
        error={error}
        valLeft={formattedPrice(minPrice || initialLeftValue)}
        valRight={formattedPrice(maxPrice || initialRightValue)}
        onChange={onChangePrice}
        onClickEnd={onClickEndPrice}
      />
    );
  };

  return (
    <div css={blockStyle}>
      <InputHidden name={minPriceFieldName} />
      <InputHidden name={maxPriceFieldName} />
      {renderRangeSlider()}
    </div>
  );
};

const blockStyle = css`
  padding: ${space.atom1_5x} 0;
  &:after {
    content: "";
    width: calc(100% - ${space.atom2x});
    display: block;
    margin: 0 ${space.atom} -${space.atom1_5x};
    border-bottom: 1px solid ${borderColor.secondaryLightColor};
  }
`;
