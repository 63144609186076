import { decamelizeKeys } from "humps";
import { getCookie } from "./cookieUtil";

export const getUtmValues = () => {
  const utmCampaign = getCookie("utm_campaign");
  const utmMedium = getCookie("utm_medium");
  const utmSource = getCookie("utm_source");
  const utmContent = getCookie("utm_content");
  const utmTerm = getCookie("utm_term");

  const values = {
    ...(utmCampaign && { utmCampaign }),
    ...(utmMedium && { utmMedium }),
    ...(utmSource && { utmSource }),
    ...(utmContent && { utmContent }),
    ...(utmTerm && { utmTerm }),
  };

  return decamelizeKeys(values);
};

export const getLineEntryValues = (params = {}) => {
  const acType = params["ac_type"] || getCookie("ac_type");
  const utmCampaign = params["utm_campaign"] || getCookie("utm_campaign");
  const utmSource = params["utm_source"] || getCookie("utm_source");
  const utmMedium = params["utm_medium"] || getCookie("utm_medium");
  const utmContent = params["utm_content"] || getCookie("utm_content");
  const utmTerm = params["utm_term"] || getCookie("utm_term");
  const gclid = params["gclid"] || getCookie("gclid");
  const wbraid = params["wbraid"] || getCookie("wbraid");
  const gbraid = params["gbraid"] || getCookie("gbraid");
  const yclid = params["yclid"] || getCookie("yclid");
  const tSessionId = params["t_session_id"] || getCookie("t_session_id");

  const values = {
    ...(acType && { acType }),
    ...(utmCampaign && { utmCampaign }),
    ...(utmSource && { utmSource }),
    ...(utmMedium && { utmMedium }),
    ...(utmContent && { utmContent }),
    ...(utmTerm && { utmTerm }),
    ...(gclid && { gclid }),
    ...(wbraid && { wbraid }),
    ...(gbraid && { gbraid }),
    ...(yclid && { yclid }),
    ...(tSessionId && { tSessionId }),
  };

  return decamelizeKeys(values);
};
