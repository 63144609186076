import * as React from "react";
import { css } from "aphrodite";
import { Label, ILabelProps } from "main/javascripts/components/atoms/Label";
import { RangeSlider } from "main/javascripts/components/atoms/Form/RangeSlider";
import {
  InputError,
  IInputErrorProps,
} from "main/javascripts/components/atoms/Form/InputError";
import { RangeSliderBlockStyle } from "./RangeSliderBlockStyle";

export interface IProps {
  label: ILabelProps;
  input: any;
  dataNum: number;
  initialLeft: number; // 位置(割合)
  initialRight: number; // 位置(割合)
  valLeft: any;
  valRight: any;
  onChange(direction: string, value: any): void;
  onClickEnd?: any;
  inputError: IInputErrorProps;
  styles?: any;
}

export const RangeSliderBlock: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const { styles = {} } = props;
  const { block, label, handleLeft, handleRight } = styles;
  return (
    <div className={css(RangeSliderBlockStyle.block, block)}>
      <Label {...props.label} styles={label}>
        {props.label.children}
      </Label>
      <div className={css(RangeSliderBlockStyle.value)}>
        {props.valLeft} - {props.valRight}
      </div>
      <RangeSlider
        initialLeft={props.initialLeft}
        initialRight={props.initialRight}
        dataNum={props.dataNum}
        {...props.input}
        onChange={props.onChange}
        styles={{
          handleLeft: handleLeft,
          handleRight: handleRight,
        }}
        onClickEnd={props.onClickEnd}
      />
      <InputError isIndependent={true} {...props.inputError} />
    </div>
  );
};
