import { Action } from "redux";
import {
  INIT_DYNAMIC_PACKAGE_PRE_RESERVATION,
  FETCH_DYNAMIC_PACKAGE_PRE_RESERVATION_SUCCESS,
  SET_DISPLAY_DYNAMIC_PACKAGE_PRE_RESERVATION_CONFIRMATION,
} from "main/javascripts/constants/actionType/DynamicPackagePreReservationActionType";

export interface IDynamicPackagePreReservationState {
  dynamicPackagePreReservationId: number;
  isConfirmed: boolean;
  dynamicPackagePreReservation: any;
}

interface IInitDynamicPackageReservationAction extends Action {
  actionType: string;
  payload: any;
}

export type DynamicPackagePreReservationActions =
  IInitDynamicPackageReservationAction;

// const dynamicPackagePreReservation: any = {
//   0: {
//     flightPreReservation: {
//       id: '5ba85a02e13823594747bc2b',
//       itineraries: [
//         {
//           flightDetails: [
//             {
//               marketingAirlineCode: 'OZ',
//               marketingAirlineName: 'アシアナ航空',
//               operatingAirlineCode: 'OZ',
//               operatingAirlineName: 'アシアナ航空',
//               flightNumber: '177',
//               bookingClass: 'W',
//               cabinClass: 'economy_standard',
//               layover: null,
//               duration: 145,
//               departureDate: '2018-09-29',
//               departureTime: '06:10',
//               departureAirportCode: 'HND',
//               departureAirportName: '東京 (HND-羽田空港)',
//               departureAirportShortName: '羽田空港',
//               arrivalDate: '2018-09-29',
//               arrivalTime: '08:35',
//               arrivalAirportCode: 'ICN',
//               arrivalAirportName: 'ソウル (ICN-仁川国際空港)',
//               arrivalAirportShortName: '仁川国際空港',
//               technicalStops: [],
//               departureAirportId: 3,
//               departureAirportTerminal: null,
//               arrivalAirportId: 4921,
//               arrivalAirportTerminal: null,
//               operatingAirlineId: 8,
//               marketingAirlineId: 8,
//             },
//             {
//               marketingAirlineCode: 'OZ',
//               marketingAirlineName: 'アシアナ航空',
//               operatingAirlineCode: 'OZ',
//               operatingAirlineName: 'アシアナ航空',
//               flightNumber: '363',
//               bookingClass: 'W',
//               cabinClass: 'economy_standard',
//               layover: 130,
//               duration: 120,
//               departureDate: '2018-09-29',
//               departureTime: '10:45',
//               departureAirportCode: 'ICN',
//               departureAirportName: 'ソウル (ICN-仁川国際空港)',
//               departureAirportShortName: '仁川国際空港',
//               arrivalDate: '2018-09-29',
//               arrivalTime: '11:45',
//               arrivalAirportCode: 'PVG',
//               arrivalAirportName: '上海 (PVG-浦東空港)',
//               arrivalAirportShortName: '上海浦東国際空港',
//               technicalStops: [],
//               departureAirportId: 4921,
//               departureAirportTerminal: null,
//               arrivalAirportId: 4368,
//               arrivalAirportTerminal: null,
//               operatingAirlineId: 8,
//               marketingAirlineId: 8,
//             },
//           ],
//           duration: 395,
//           stopCount: 1,
//           freeBaggage: null,
//         },
//         {
//           flightDetails: [
//             {
//               marketingAirlineCode: 'OZ',
//               marketingAirlineName: 'アシアナ航空',
//               operatingAirlineCode: 'OZ',
//               operatingAirlineName: 'アシアナ航空',
//               flightNumber: '366',
//               bookingClass: 'K',
//               cabinClass: 'economy_standard',
//               layover: null,
//               duration: 120,
//               departureDate: '2018-09-30',
//               departureTime: '16:20',
//               departureAirportCode: 'PVG',
//               departureAirportName: '上海 (PVG-浦東空港)',
//               departureAirportShortName: '上海浦東国際空港',
//               arrivalDate: '2018-09-30',
//               arrivalTime: '19:20',
//               arrivalAirportCode: 'ICN',
//               arrivalAirportName: 'ソウル (ICN-仁川国際空港)',
//               arrivalAirportShortName: '仁川国際空港',
//               technicalStops: [],
//               departureAirportId: 4368,
//               departureAirportTerminal: null,
//               arrivalAirportId: 4921,
//               arrivalAirportTerminal: null,
//               operatingAirlineId: 8,
//               marketingAirlineId: 8,
//             },
//             {
//               marketingAirlineCode: 'OZ',
//               marketingAirlineName: 'アシアナ航空',
//               operatingAirlineCode: 'OZ',
//               operatingAirlineName: 'アシアナ航空',
//               flightNumber: '178',
//               bookingClass: 'K',
//               cabinClass: 'economy_standard',
//               layover: 110,
//               duration: 140,
//               departureDate: '2018-09-30',
//               departureTime: '21:10',
//               departureAirportCode: 'ICN',
//               departureAirportName: 'ソウル (ICN-仁川国際空港)',
//               departureAirportShortName: '仁川国際空港',
//               arrivalDate: '2018-09-30',
//               arrivalTime: '23:30',
//               arrivalAirportCode: 'HND',
//               arrivalAirportName: '東京 (HND-羽田空港)',
//               arrivalAirportShortName: '羽田空港',
//               technicalStops: [],
//               departureAirportId: 4921,
//               departureAirportTerminal: null,
//               arrivalAirportId: 3,
//               arrivalAirportTerminal: null,
//               operatingAirlineId: 8,
//               marketingAirlineId: 8,
//             },
//           ],
//           duration: 370,
//           stopCount: 1,
//           freeBaggage: null,
//         },
//       ],
//       flightPreArrangementTravelers: [
//         {
//           passengerType: 'adult',
//           totalPrice: '54,520円',
//           price: '49,520円',
//           arrangeFee: '5,000円',
//           arrangeFeeCents: 5000,
//           flightArrangementTravelerPrices: [
//             {
//               priceType: 'base_fare',
//               price: '36,000円',
//             },
//             {
//               priceType: 'tax_and_surcharge',
//               price: '13,520円',
//             },
//           ],
//         },
//         {
//           passengerType: 'adult',
//           totalPrice: '54,520円',
//           price: '49,520円',
//           arrangeFee: '5,000円',
//           arrangeFeeCents: 5000,
//           flightArrangementTravelerPrices: [
//             {
//               priceType: 'base_fare',
//               price: '36,000円',
//             },
//             {
//               priceType: 'tax_and_surcharge',
//               price: '13,520円',
//             },
//           ],
//         },
//       ],
//       totalPrice: '109,040円',
//     },
//     hotelPreReservation: {
//       id: '5ba84ae2e13823594747bc2a',
//       checkinDate: '2018/09/27',
//       checkoutDate: '2018/09/28',
//       checkin: {
//         beginTime: '3:00 PM',
//         endTime: '10:00 PM',
//         instructions: '施設のポリシーにしたがって、追加ゲスト料金がかかる場合があります。<br />' +
//           'チェックイン時に政府発行の写真付き身分証明書、および現地での付随費用精算のためのクレジットカードが必要です。<br />' +
//           'スペシャルリクエストは、チェックイン時の状況によりご希望に添えない場合があります。' +
//           'また、リクエストによっては追加料金が発生する場合があります。スペシャルリクエストは確約ではございませんのでご了承ください。<br />',
//         specialInstructions: '',
//         minAge: '',
//       },
//       checkout: '11:00 AM',
//       arrangementType: 'expedia',
//       hotelArrangementPlans: [
//         {
//           roomName: 'スタンダード ツインルーム シングルベッド 2 台',
//           numberOfAdult: 2,
//           agesOfChildren: [],
//           price: '18,860円',
//           arrangeFee: '0円',
//           isDeposit: false,
//           isRefundable: true,
//           payType: 'pre_payment',
//           hotelArrangementPlanAmenities: [],
//           hotelArrangementPlanFees: [
//             {
//               feeType: 'mandatory_tax',
//               price: '300円',
//             },
//           ],
//           hotelArrangementPlanPrices: [
//             {
//               priceType: 'base_rate',
//               price: '15,875円',
//             },
//             {
//               priceType: 'tax_and_service_fee',
//               price: '2,985円',
//             },
//           ],
//           hotelArrangementCancellations: [
//             {
//               fromAt: '2018-09-26T23:59:00.000+09:00',
//               toAt: '2018-09-27T23:59:00.000+09:00',
//               fee: '',
//               feeCents: 18860,
//               feeCurrency: 'JPY',
//               text: 'ご予約を2018-09-26 23:59:00 +0900以降にキャンセルした場合には、『1泊分(税込)』のキャンセル手数料が請求されます。',
//             },
//           ],
//           priceCents: 18860,
//           priceCurrency: 'JPY',
//           arrangeFeeCents: 0,
//           arrangeFeeCurrency: 'JPY',
//           bedGroups: null,
//           hotelArrangementPlanDailyPrices: [
//             {
//               date: '2018-09-27',
//               priceCents: 15875,
//               priceCurrency: 'JPY',
//             },
//           ],
//         },
//       ],
//       totalPrice: '18,860円',
//       hotel: {
//         id: 18,
//         name: 'ひだ ホテル プラザ',
//         address: '岐阜県高山市花岡町2-60',
//       },
//       defaultHotelImage: 'https://i.travelapi.com/hotels/4000000/3900000/3892600/3892581/3892581_100_z.jpg',
//       hotelId: '18',
//     },
//   },
// };

const INITIAL_STATE: IDynamicPackagePreReservationState = {
  dynamicPackagePreReservationId: 0,
  isConfirmed: false,
  // normalizr
  dynamicPackagePreReservation: {},
};

export function dynamicPackagePreReservationReducer(
  state: IDynamicPackagePreReservationState = INITIAL_STATE,
  action: DynamicPackagePreReservationActions
): IDynamicPackagePreReservationState {
  switch (action.type) {
    case INIT_DYNAMIC_PACKAGE_PRE_RESERVATION:
      return {
        ...state,
        ...action.payload.entities,
        dynamicPackagePreReservationId: action.payload.result,
      };
    case FETCH_DYNAMIC_PACKAGE_PRE_RESERVATION_SUCCESS:
      return {
        ...state,
        ...action.payload.entities,
        dynamicPackagePreReservationId: action.payload.result,
      };
    case SET_DISPLAY_DYNAMIC_PACKAGE_PRE_RESERVATION_CONFIRMATION:
      return {
        ...state,
        isConfirmed: action.payload,
      };
    default:
      return state;
  }
}
