import { AsyncThunk, AnyAction } from "@reduxjs/toolkit";
import { debounce } from "underscore";
import { createSelectorCreator, defaultMemoize } from "reselect";
import isEqual from "react-fast-compare";

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;

type PendingAction = ReturnType<GenericAsyncThunk["pending"]>;
type RejectedAction = ReturnType<GenericAsyncThunk["rejected"]>;
type FulfilledAction = ReturnType<GenericAsyncThunk["fulfilled"]>;

export function isPendingAction(key: string) {
  return (action: AnyAction): action is PendingAction =>
    new RegExp(`${key}/.+/pending`).test(action.type);
}

export function isRejectedAction(key: string) {
  return (action: AnyAction): action is RejectedAction =>
    new RegExp(`${key}/.+/rejected`).test(action.type);
}

export function isFulfilledAction(key: string) {
  return (action: AnyAction): action is FulfilledAction =>
    new RegExp(`${key}/.+/fulfilled`).test(action.type);
}

// debounceを直接返す必要があるためwaitを変数化できない
// 異なる間隔が必要な場合別途定義する
export const debounced = debounce(
  (action, arg, dispatch) => dispatch(action(arg)),
  300
);

export const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  isEqual
);
