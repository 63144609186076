import parseISO from "date-fns/parseISO";

export function parseDate(date: string | Date): Date {
  let newDate: string | Date = date;
  if (typeof newDate === "string") {
    newDate = newDate.replace(/\//g, "-");
    return parseISO(newDate);
  }
  return newDate;
}
