import { handleActions } from "redux-actions";
import {
  SET_LIFF_INITIALIZED,
  SET_FACEBOOK_MESSENGER_INITIALIZED,
} from "main/javascripts/constants/actionType/SocialMediaStatusActionType";

export interface ISocialMediaStatusState {
  initializedLIFF: boolean;
  initializedFacebookMessenger: boolean;
}

const INITIAL_STATE: ISocialMediaStatusState = {
  initializedLIFF: false,
  initializedFacebookMessenger: false,
};

export const socialMediaStatusReducer: any = handleActions(
  {
    [SET_LIFF_INITIALIZED]: (
      state: ISocialMediaStatusState,
      action: any
    ): ISocialMediaStatusState => {
      return {
        ...state,
        initializedLIFF: action.payload,
      };
    },
    [SET_FACEBOOK_MESSENGER_INITIALIZED]: (
      state: ISocialMediaStatusState,
      action: any
    ): ISocialMediaStatusState => {
      return {
        ...state,
        initializedFacebookMessenger: action.payload,
      };
    },
  },
  INITIAL_STATE
);
