export interface ICurrentUser {
  id: number;
  name: string;
  chatPlatformType: string;
}

const defaultCurrentUser: ICurrentUser = {
  id: 0,
  name: "",
  chatPlatformType: "",
};

export class CurrentUser implements ICurrentUser {
  public id: number;
  public name: string;
  public chatPlatformType: string;

  constructor(param: ICurrentUser) {
    Object.assign(this, defaultCurrentUser, param);
  }
}
