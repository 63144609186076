import {
  createSlice,
  // createSelector,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { camelizeKeys } from "humps";
import { createWrapper } from "main/javascripts/api/AxiosWrapper";
import { ErrorResponse } from "main/javascripts/types/errorResponse";
import { MasterData } from "main/javascripts/types/masterData";
import { RootState } from "main/javascripts/store";
import { PATH_SCOPE } from "main/javascripts/constants/Constants";

const key = "masterData";

const countryAdapter = createEntityAdapter<MasterData.Country>();

const countriesInitialStateValues: MasterData.CountryState = {
  errors: null,
  loading: false,
};

const initialState = {
  success: false,
  countries: countryAdapter.getInitialState(countriesInitialStateValues),
};

/** Async **/
export const fetchMasterData = createAsyncThunk<
  {
    countries: MasterData.Country[];
  },
  {
    param?: string;
    isServerSide?: boolean;
    headers?: any;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/fetchMasterData`, async (args, { rejectWithValue }) => {
  try {
    const { isServerSide = false, headers } = args;
    const result = await createWrapper({ isServerSide }).get(
      `${PATH_SCOPE}/api/master_data.json`,
      {
        headers,
      }
    );
    return result.data;
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

export const masterDataSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    initCountries: (state, action) => {
      countryAdapter.removeAll(state.countries);
      if (action.payload && Array.isArray(action.payload)) {
        countryAdapter.setAll(state.countries, action.payload);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMasterData.fulfilled, (state, action) => {
      countryAdapter.setAll(state.countries, action.payload.countries);
    });
  },
});

/** selector * */
export const { selectById: countryByIdSelector, selectAll: countriesSelector } =
  countryAdapter.getSelectors((state: RootState) => state.masterData.countries);

// const stateSelector = (state: { [key]: IMasterDataState }) => state[key];

/** action export **/
export const { initCountries } = masterDataSlice.actions;
