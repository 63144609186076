import { handleActions } from "redux-actions";
import { INIT_CURRENT_USER } from "main/javascripts/constants/actionType/CurrentUserActionType";
import { CurrentUser } from "main/javascripts/models/CurrentUser";
import { CREATE_FACEBOOK_MESSENGER_AUTO_SIGN_IN_SUCCESS } from "main/javascripts/constants/actionType/FacebookMessengerAutoSignInActionType";
import { CREATE_LINE_AUTO_SIGN_IN_SUCCESS } from "main/javascripts/constants/actionType/LineAutoSignInActionType";

export interface ICurrentUserState {
  currentUser: CurrentUser | null;
}

const INITIAL_STATE: ICurrentUserState = {
  currentUser: null,
};

export const currentUserReducer: any = handleActions(
  {
    [INIT_CURRENT_USER]: (
      state: ICurrentUserState,
      action: any
    ): ICurrentUserState => {
      return {
        ...state,
        currentUser: action.payload,
      };
    },
    [CREATE_FACEBOOK_MESSENGER_AUTO_SIGN_IN_SUCCESS]: (
      state: ICurrentUserState,
      action: any
    ): ICurrentUserState => {
      return {
        ...state,
        currentUser: new CurrentUser(action.payload.user),
      };
    },
    [CREATE_LINE_AUTO_SIGN_IN_SUCCESS]: (
      state: ICurrentUserState,
      action: any
    ): ICurrentUserState => {
      return {
        ...state,
        currentUser: action.payload.user
          ? new CurrentUser(action.payload.user)
          : null,
      };
    },
  },
  INITIAL_STATE
);
