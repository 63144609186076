import { StyleSheet } from "aphrodite";
import { space } from "main/javascripts/styles/base/spaceStyle";
import {
  fontSize,
  lineHeight,
} from "main/javascripts/styles/base/typographyStyle";

export interface IStyle {
  block: any;
  value: any;
}

export const RangeSliderBlockStyle: IStyle = StyleSheet.create({
  block: {
    width: "100%",
    padding: `calc(${space.atom} - (${lineHeight.body1} - ${fontSize.body})/2) ${space.atom} ${space.atom2x}`,
    boxSizing: "border-box",
  },
  value: {
    paddingBottom: space.atom0_5x,
    fontSize: fontSize.body,
    minHeight: "28px",
  },
});
