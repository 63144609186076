interface IFlightTypes {
  oneWay: string;
  roundTrip: string;
  multiCity: string;
}

export const FlightTypes: IFlightTypes = {
  oneWay: "one_way",
  roundTrip: "round_trip",
  multiCity: "multi_city",
};
