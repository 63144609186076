export const INIT_DYNAMIC_PACKAGE_PRE_RESERVATION =
  "INIT_DYNAMIC_PACKAGE_PRE_RESERVATION";
export const FETCH_DYNAMIC_PACKAGE_PRE_RESERVATION_FAILURE =
  "FETCH_DYNAMIC_PACKAGE_PRE_RESERVATION_FAILURE";
export const FETCH_DYNAMIC_PACKAGE_PRE_RESERVATION_REQUEST =
  "FETCH_DYNAMIC_PACKAGE_PRE_RESERVATION_REQUEST";
export const FETCH_DYNAMIC_PACKAGE_PRE_RESERVATION_SUCCESS =
  "FETCH_DYNAMIC_PACKAGE_PRE_RESERVATION_SUCCESS";
export const SET_DISPLAY_DYNAMIC_PACKAGE_PRE_RESERVATION_CONFIRMATION =
  "SET_DISPLAY_DYNAMIC_PACKAGE_PRE_RESERVATION_CONFIRMATION";
export const UPDATE_DYNAMIC_PACKAGE_PRE_RESERVATION_PARAMETERS_REQUEST =
  "UPDATE_DYNAMIC_PACKAGE_PRE_RESERVATION_PARAMETERS_REQUEST";
