import { Action } from "redux";
import {
  INIT_RESERVATIONS,
  INIT_RESERVATION,
  FETCH_RESERVATION_SUCCESS,
  UPDATE_RESERVATION_TRAVELER,
} from "main/javascripts/constants/actionType/ReservationActionType";

export interface IReservationState {
  reservationId: number;
  reservationIds: number[];

  reservation: any;
  flightArrangementItinerary: any;
  traveler: any;
  hotelArrangement: any;
  hotelArrangementPlan: any;
}

interface IInitializingReservationAction extends Action {
  actionType: string;
  payload: any;
}

export type ReservationActions = IInitializingReservationAction;

const INITIAL_STATE: IReservationState = {
  reservationId: 0,
  reservationIds: [],

  // normalizr
  reservation: {},
  flightArrangementItinerary: {},
  traveler: {},
  hotelArrangement: {},
  hotelArrangementPlan: {},
};

export function reservationReducer(
  state: IReservationState = INITIAL_STATE,
  action: ReservationActions
): IReservationState {
  switch (action.type) {
    case INIT_RESERVATION:
      return {
        ...state,
        ...action.payload.entities,
        reservationId: action.payload.result,
      };
    case INIT_RESERVATIONS:
      return {
        ...state,
        ...action.payload.entities,
        reservationIds: action.payload.result.reservations || [],
      };
    case FETCH_RESERVATION_SUCCESS:
      return {
        ...state,
        ...action.payload.entities,
        reservationId: action.payload.result,
      };
    case UPDATE_RESERVATION_TRAVELER: {
      const traveler = action.payload.traveler;
      const travelerId = traveler.id;
      const newTraveler = {
        ...state.traveler,
        [travelerId]: traveler,
      };
      return {
        ...state,
        ...action.payload.entities,
        traveler: newTraveler,
      };
    }
    default:
      return state;
  }
}
