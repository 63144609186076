import {
  createSlice,
  createSelector,
  createAsyncThunk,
  PayloadAction,
} from "@reduxjs/toolkit";
import { stringify } from "qs";
import { camelizeKeys } from "humps";
import { createWrapper } from "main/javascripts/api/AxiosWrapper";
import { ErrorResponse } from "main/javascripts/types/errorResponse";
import {
  debounced,
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from "main/javascripts/utils/sliceUtil";
// import { RootState } from "main/javascripts/store";
import { HotelSuggestion } from "main/javascripts/types/hotelSuggestion";
import { PATH_SCOPE } from "main/javascripts/constants/Constants";
import { Suggestion } from "../../types/suggestion";

const key = "hotelSuggestion";

const initialState: HotelSuggestion.HotelSuggestionEntityState = {
  hotelDestinationSuggestions: [],
  hotelDestinationInputData: null,
  loading: false,
  errors: null,
};

/** Async **/
export const fetchHotelDestinationSuggestions = createAsyncThunk<
  {
    suggestions: Suggestion[];
  },
  {
    params: any;
  },
  {
    rejectValue: ErrorResponse;
  }
>(
  `${key}/fetchHotelDestinationSuggestions`,
  async (args, { rejectWithValue }) => {
    try {
      const { params } = args;
      const { text, included_code, excluded_code } = params;
      const paramString = stringify(
        { q: text, included_code, excluded_code },
        { arrayFormat: "repeat" }
      );
      const url = `${PATH_SCOPE}/api/hotels/destinations/suggests.json?${paramString}`;
      const result = await createWrapper().get(url);
      return {
        suggestions: result.data,
      };
    } catch (err) {
      return rejectWithValue(camelizeKeys(err.response.data));
    }
  }
);
export const debouncedFetchHotelDestinationSuggestions = (arg) => (dispatch) =>
  debounced(fetchHotelDestinationSuggestions, arg, dispatch);

/** slice **/
export const hotelSuggestionSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    setHotelDestinationInputData: (
      state,
      action: PayloadAction<HotelSuggestion.InputData>
    ) => {
      state.hotelDestinationInputData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHotelDestinationSuggestions.fulfilled, (state, action) => {
        state.hotelDestinationSuggestions = action.payload.suggestions;
      })
      // 一旦slice単位で共通化
      .addMatcher(isPendingAction(key), (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addMatcher(isFulfilledAction(key), (state) => {
        state.loading = false;
      })
      .addMatcher(isRejectedAction(key), (state, action) => {
        state.errors = action.payload;
        state.loading = false;
      });
  },
});

/** selector **/
const stateSelector = (state: {
  [key]: HotelSuggestion.HotelSuggestionEntityState;
}) => state[key];

export const hotelDestinationSuggestionsSelector = createSelector(
  stateSelector,
  (state) => state.hotelDestinationSuggestions
);

export const hotelDestinationInputDataSelector = createSelector(
  stateSelector,
  (state) => state.hotelDestinationInputData
);

export const errorsSelector = createSelector(
  stateSelector,
  (state) => state.errors
);

export const loadingSelector = createSelector(
  stateSelector,
  (state) => state.loading
);

/** action export **/
export const { setHotelDestinationInputData } = hotelSuggestionSlice.actions;
