import { stringify } from "qs";
import { decamelizeKeys } from "humps";
import { getPropertyObject } from "main/javascripts/utils/ObjectUtil";

export interface IHotelShowParam {
  checkinDate?: string | null;
  checkoutDate?: string | null;
  valid?: boolean;
  cacheId?: string;
  roomCacheId?: string;
  hotelRoomId?: string;
  hotelPlanId?: string;
  guests?: { numberOfAdult: number; agesOfChildren: number[] }[];
  searchType?: string;
  dynamicPackageId?: string | null;
  searchGroupId?: string | null;
  lId?: string | null;
  qCId?: string | null;
  dynamicPackagePreReservationId?: string;
}

// showの部屋検索のformで使ってる
const defaultHotelShowParam: IHotelShowParam = {
  checkinDate: null,
  checkoutDate: null,
  valid: false,
  cacheId: "",
  roomCacheId: "",
  hotelRoomId: "",
  hotelPlanId: "",
  guests: [{ numberOfAdult: 2, agesOfChildren: [] }],
  searchType: "",
  dynamicPackageId: null,
  searchGroupId: null,
  lId: null,
  qCId: null,
  dynamicPackagePreReservationId: "",
};

export class HotelShowParam implements IHotelShowParam {
  public checkinDate: string;
  public checkoutDate: string;
  public guests: { numberOfAdult: number; agesOfChildren: number[] }[];
  public valid: boolean;
  public cacheId: string;
  public roomCacheId: string;
  public hotelRoomId: string;
  public hotelPlanId: string;
  public searchType: string;
  public dynamicPackageId: string;
  public searchGroupId: string;
  public lId: string | null;
  public qCId: string;
  public dynamicPackagePreReservationId: string;

  public toUrlParam(): string {
    return stringify(decamelizeKeys(this.toParam()), {
      arrayFormat: "indices",
    });
  }
  public toApiQueryParam(): string {
    return stringify(decamelizeKeys(this.toParam()), {
      arrayFormat: "brackets",
    });
  }
  constructor(param: IHotelShowParam) {
    Object.assign(this, defaultHotelShowParam, param);
  }
  public update(param: any): IHotelShowParam {
    return new HotelShowParam({
      ...getPropertyObject(this),
      ...param,
    });
  }
  public toParam(): any {
    const guestsParam: any = this.toGuestsParam();
    return decamelizeKeys({
      ...getPropertyObject(this),
      guests: guestsParam,
      numberOfRooms: guestsParam.length,
    });
  }
  private toGuestsParam(): any {
    const guests: any =
      this.guests.length > 0
        ? this.guests
        : [{ numberOfAdult: 2, agesOfChildren: [] }];
    return guests.map((guest: any) => {
      return {
        ...guest,
        numberOfChildren:
          guest.agesOfChildren.length > 0 ? guest.agesOfChildren.length : 0,
      };
    });
  }
  public totalNumberOfAdult(): number {
    let sum = 0;
    this.guests.forEach((guest: any) => {
      sum += Number(guest.numberOfAdult);
    });
    return sum;
  }
  public totalNumberOfChildren(): number {
    let sum = 0;
    this.guests.forEach((guest: any) => {
      sum += Number(guest.agesOfChildren ? guest.agesOfChildren.length : 0);
    });
    return sum;
  }
  public isSelectionMode(): boolean {
    return !!this.lId;
  }
}
