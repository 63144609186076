import { Airport } from "main/javascripts/models/Airport";

export interface IRegion {
  id: number;
  code: string;
  name: string;
  recommendedType: string;
  bestSeason: string;
  mainAirline: string;
  comment: string;
  description: string;
  airports: Airport[];
}

const defaultParam: IRegion = {
  id: 0,
  code: "",
  name: "",
  recommendedType: "",
  bestSeason: "",
  mainAirline: "",
  comment: "",
  description: "",
  airports: [],
};

export class Region implements IRegion {
  public id: number;
  public code: string;
  public name: string;
  public recommendedType: string;
  public bestSeason: string;
  public mainAirline: string;
  public comment: string;
  public description: string;
  public airports: Airport[];

  constructor(param: IRegion | null) {
    Object.assign(this, defaultParam, param);
  }

  public static FROM_JS(param: IRegion): Region {
    const airports: Airport[] = (param.airports || []).map(
      (airport: Airport) => {
        return new Airport(airport);
      }
    );
    return new Region({
      ...param,
      airports,
    });
  }
}
