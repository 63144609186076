import { createWrapper } from "./AxiosWrapper";

export async function cancelHotelArrangementPlan(
  hotelArrangementPlanId: number,
  param: any
): Promise<any> {
  // return createWrapper({ isCsrf: true })
  return createWrapper()
    .put(
      `/api/hotel_arrangement_plans/${hotelArrangementPlanId}/cancel.json`,
      param
    )
    .then(async (response: any): Promise<any> => {
      return Promise.resolve({
        data: response.data,
      });
    })
    .catch(async (error: any): Promise<any> => {
      return Promise.reject({
        errors: error.response.data.errors,
      });
    });
}
