import * as React from "react";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { camelize } from "humps";
import { nl2br } from "main/javascripts/utils/StringUtil";
import {
  fontSize,
  fontWeight,
  lineHeight,
} from "main/javascripts/styles/base/typographyStyle";
import {
  accentColor,
  hex2rgba,
  shadeColorLog,
} from "main/javascripts/styles/base/colorStyle";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { borderRadius } from "main/javascripts/styles/base/borderStyle";

export interface IInputErrorProps {
  namespace?: string;
  errorMessage: string | { key: string; options: any };
  label?: string;
  styles?: any;
}

const InputErrorComponent: React.FC<IInputErrorProps> = (
  props: IInputErrorProps
): React.ReactElement<IInputErrorProps> | null => {
  const { namespace, errorMessage, label } = props;
  const { t } = useTranslation(["label"]);

  let localizedErrorMessage;
  if (typeof errorMessage === "object") {
    // 引数付きのエラーメッセージを変換
    localizedErrorMessage = t(
      `form.errors.messages.${errorMessage.key}`,
      errorMessage.options
    );
  } else {
    localizedErrorMessage = t(`form.errors.messages.${errorMessage}`);
  }
  let labeledErrorMessage;
  if (localizedErrorMessage.indexOf("form.errors.") === 0 || !namespace) {
    // サーバーから受け取ったメッセージはそのまま表示する
    labeledErrorMessage =
      typeof errorMessage === "string" ? nl2br(errorMessage) : "";
  } else {
    const individualLabel = label.split(".").slice(-1)[0];
    let localizedLabel = t(`${namespace}.${camelize(individualLabel)}`);
    if (
      localizedLabel.indexOf(`${namespace}.${camelize(individualLabel)}`) === 0
    ) {
      localizedLabel = label;
    }
    labeledErrorMessage = t("form.errors.format", {
      label: localizedLabel,
      message: localizedErrorMessage,
    });
  }
  return (
    <span css={[inputErrorStyle, props.styles]}>{labeledErrorMessage}</span>
  );
};
export const InputError: any = React.memo(InputErrorComponent);

const inputErrorStyle = css`
  font-size: ${fontSize.smallest};
  line-height: ${lineHeight.caption3};
  font-weight: ${fontWeight.medium};
  color: ${accentColor.alertColor};
  display: block;
  padding: ${space.atom0_5x} ${space.atom};
  margin: ${space.atom0_5x} 0 0;
  background: ${shadeColorLog(hex2rgba(accentColor.alertColor), 0.8)};
  border-radius: ${borderRadius.normal};
`;
