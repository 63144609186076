import { all } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
// import { hotelForMapSaga, hotelSaga } from "./HotelSaga";
// import { flightSaga, flightInfoSaga } from "./FlightSaga";
import { flightPreReservationSaga } from "./FlightPreReservationSaga";
import { dynamicPackagePreReservationSaga } from "./DynamicPackagePreReservationSaga";
import { hotelArrangementPlanSaga } from "./HotelArrangementPlanSaga";
// import { destinationSuggestSaga } from "./DestinationSuggestSaga";
import { flightOriginDestinationSuggestSaga } from "./FlightOriginDestinationSuggestSaga";
import { facebookMessengerAutoSignInSaga } from "./FacebookMessengerAutoSignInSaga";
import { lineAutoSignInSaga } from "./LineAutoSignInSaga";
// import { postSaga } from "./PostSaga";

export default function* rootSaga(): SagaIterator {
  yield all([
    // ...hotelSaga,
    // ...hotelForMapSaga,
    ...hotelArrangementPlanSaga,
    // ...destinationSuggestSaga,
    ...flightOriginDestinationSuggestSaga,
    // ...flightSaga,
    // ...flightInfoSaga,
    ...flightPreReservationSaga,
    ...dynamicPackagePreReservationSaga,
    ...facebookMessengerAutoSignInSaga,
    ...lineAutoSignInSaga,
    // ...postSaga,
  ]);
}
