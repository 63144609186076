import { SagaIterator } from "redux-saga";
import { call, put, ForkEffect, takeLatest } from "redux-saga/effects";
import { IAction } from "main/javascripts/constants/type/ReduxActions";
import { CREATE_FACEBOOK_MESSENGER_AUTO_SIGN_IN_RQUEST } from "main/javascripts/constants/actionType/FacebookMessengerAutoSignInActionType";
import { createFacebookUserSignIn } from "main/javascripts/api/FacebookMessengerAutoSignInApi";
import {
  createFacebookMessengerAutoSignInSuccess,
  createFacebookMessengerAutoSignInFailure,
} from "main/javascripts/actions/FacebookMessengerAutoSignIn";

function* runCreateFacebookMessengerAutoSignInsaga(
  action: IAction<string>
): SagaIterator {
  try {
    const { data } = yield call(createFacebookUserSignIn, {
      result: action.payload,
    });
    yield put(createFacebookMessengerAutoSignInSuccess(data));
  } catch (e) {
    yield put(createFacebookMessengerAutoSignInFailure());
  }
}

export const facebookMessengerAutoSignInSaga: ForkEffect[] = [
  takeLatest(
    CREATE_FACEBOOK_MESSENGER_AUTO_SIGN_IN_RQUEST,
    runCreateFacebookMessengerAutoSignInsaga
  ),
];
