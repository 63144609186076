import { StyleSheet } from "aphrodite";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { moreThanBreakpoint } from "main/javascripts/styles/base/responsiveStyle";
import { borderColor } from "main/javascripts/styles/base/colorStyle";

export interface IStyle {
  block: any;
  handleLeft: any;
  handleRight: any;
}

export const HotelPriceFilterBlockStyle: IStyle = StyleSheet.create({
  block: {
    padding: `${space.atom1_5x} 0`,
    ":after": {
      content: '""',
      width: `calc(100% - ${space.atom2x})`,
      display: "block",
      margin: `0 ${space.atom} -${space.atom1_5x}`,
      borderBottom: `1px solid ${borderColor.secondaryLightColor}`,
    },
  },
  handleLeft: {
    [moreThanBreakpoint("desktop")]: {
      width: "30px",
      height: "30px",
      marginLeft: "-18px",
    },
  },
  handleRight: {
    [moreThanBreakpoint("desktop")]: {
      width: "30px",
      height: "30px",
      marginLeft: "-12px",
    },
  },
});
