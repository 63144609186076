import { Action } from "redux";
import {
  INIT_HOTEL_PRE_RESERVATION,
  SET_DISPLAY_HOTEL_PRE_RESERVATION_CONFIRMATION,
} from "main/javascripts/constants/actionType/HotelPreReservationActionType";

export interface IHotelPreReservationState {
  hotelPreReservationId: number;
  isConfirmed: boolean;
  hotelPreReservation: any;
}

interface IInitHotelReservationAction extends Action {
  actionType: string;
  payload: any;
}

export type HotelPreReservationActions = IInitHotelReservationAction;

const INITIAL_STATE: IHotelPreReservationState = {
  hotelPreReservationId: 0,
  isConfirmed: false,
  // normalizr
  hotelPreReservation: {},
};

export function hotelPreReservationReducer(
  state: IHotelPreReservationState = INITIAL_STATE,
  action: HotelPreReservationActions
): IHotelPreReservationState {
  switch (action.type) {
    case INIT_HOTEL_PRE_RESERVATION:
      return {
        ...state,
        ...action.payload.entities,
        hotelPreReservationId: action.payload.result,
      };
    case SET_DISPLAY_HOTEL_PRE_RESERVATION_CONFIRMATION:
      return {
        ...state,
        isConfirmed: action.payload,
      };
    default:
      return state;
  }
}
