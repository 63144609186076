import { Action } from "redux";
import {
  SET_SEARCH_FLIGHT_CONDITION_VALUES,
  SET_SEARCH_FLIGHT_ADVANCED_CONDITION_VALUES,
  SET_SEARCH_FLIGHT_SORT_CONDITION_VALUES,
} from "main/javascripts/constants/actionType/SearchFlightActionType";

export interface ISearchFlightState {
  conditionValues: any;
  advancedConditionValues: any;
  sortConditionValues: any;
}

interface IInitializingSearchFlightAction extends Action {
  actionType: string;
  payload: any;
}

export type SearchFlightActions = IInitializingSearchFlightAction;

const INITIAL_STATE: ISearchFlightState = {
  conditionValues: {},
  advancedConditionValues: {},
  sortConditionValues: {},
};

export function searchFlightReducer(
  state: ISearchFlightState = INITIAL_STATE,
  action: SearchFlightActions
): ISearchFlightState {
  switch (action.type) {
    case SET_SEARCH_FLIGHT_CONDITION_VALUES:
      return {
        ...state,
        conditionValues: action.payload,
      };
    case SET_SEARCH_FLIGHT_ADVANCED_CONDITION_VALUES:
      return {
        ...state,
        advancedConditionValues: action.payload,
      };
    case SET_SEARCH_FLIGHT_SORT_CONDITION_VALUES:
      return {
        ...state,
        sortConditionValues: action.payload,
      };
    default:
      return state;
  }
}
