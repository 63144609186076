export const INIT_HOTEL = "INIT_HOTEL";
export const INIT_HOTELS = "INIT_HOTELS";
export const POLLING_HOTELS_REQUEST = "POLLING_HOTELS_REQUEST";
export const POLLING_HOTELS_FAILURE = "POLLING_HOTELS_FAILURE";
export const POLLING_HOTELS_SUCCESS = "POLLING_HOTELS_SUCCESS";
export const POLLING_HOTELS_FOR_MAP_REQUEST = "POLLING_HOTELS_FOR_MAP_REQUEST";
export const POLLING_HOTELS_FOR_MAP_FAILURE = "POLLING_HOTELS_FOR_MAP_FAILURE";
export const POLLING_HOTELS_FOR_MAP_SUCCESS = "POLLING_HOTELS_FOR_MAP_SUCCESS";
export const SET_HOTEL_ROOMS = "SET_HOTEL_ROOMS";
export const SET_HOTEL_IMAGE = "SET_HOTEL_IMAGE";
export const SET_IS_HOTEL_SHOW_MAP_VIEW = "SET_IS_HOTEL_SHOW_MAP_VIEW";
export const SET_HOTEL_MAP_COORDINATES = "SET_HOTEL_MAP_COORDINATES";
export const SET_DISPLAY_HOTEL_SEARCH_FORM = "SET_DISPLAY_HOTEL_SEARCH_FORM";
export const SET_DISPLAY_HOTEL_IMAGE_MODAL = "SET_DISPLAY_HOTEL_IMAGE_MODAL";
export const SET_DISPLAY_HOTEL_ROOM_IMAGE_MODAL =
  "SET_DISPLAY_HOTEL_ROOM_IMAGE_MODAL";
export const SET_HOTEL_ROOM_MODAL_IMAGES = "SET_HOTEL_ROOM_MODAL_IMAGES";
export const SET_DISPLAY_HOTEL_ROOM_DETAIL_MODAL =
  "SET_DISPLAY_HOTEL_ROOM_DETAIL_MODAL";
export const SET_HOTEL_ROOM_MODAL_DETAILS = "SET_HOTEL_ROOM_MODAL_DETAILS";
export const SET_IS_LOADING_ROOM = "SET_IS_LOADING_ROOM";
export const SET_REQUEST_ROOM_ERROR = "SET_REQUEST_ROOM_ERROR";
