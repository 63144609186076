import { StyleSheet } from "aphrodite";
import {
  fontSize,
  lineHeight,
  fontWeight,
} from "main/javascripts/styles/base/typographyStyle";
import {
  accentColor,
  textColor,
} from "main/javascripts/styles/base/colorStyle";
import { space } from "main/javascripts/styles/base/spaceStyle";

export interface IStyle {
  label: any;
  required: any;
}

export const LabelStyle: IStyle = StyleSheet.create({
  label: {
    fontSize: fontSize.caption,
    lineHeight: lineHeight.caption1,
    fontWeight: fontWeight.bold,
    color: textColor.primaryDarkColor,
    display: "block",
    whiteSpace: "nowrap",
    margin: 0,
  },
  required: {
    display: "inline-block",
    color: accentColor.alertColor,
    fontSize: "10px",
    fontWeight: fontWeight.regular,
    borderLeft: "1px solid",
    margin: `0.125rem 0 0 ${space.atom1_5x}`,
    lineHeight: 1,
    padding: `0.1875rem 0 0.1875rem ${space.atom1_5x}`,
    opacity: 1,
    whiteSpace: "nowrap",
  },
});
