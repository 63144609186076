import { handleActions } from "redux-actions";
import { FETCHING_FLIGHT_ORIGIN_DESTINATION_SUGGESTS_SUCCESS } from "main/javascripts/constants/actionType/FlightOriginDestinationSuggestActionType";
import { FlightOriginDestinationSuggestion } from "main/javascripts/models/FlightOriginDestinationSuggestion";

export interface IFlightOriginDestinationSuggestState {
  flightOriginDestinationSuggestions: FlightOriginDestinationSuggestion[];
}

const INITIAL_STATE: IFlightOriginDestinationSuggestState = {
  flightOriginDestinationSuggestions: [],
};

export const flightOriginDestinationSuggestReducer: any = handleActions(
  {
    [FETCHING_FLIGHT_ORIGIN_DESTINATION_SUGGESTS_SUCCESS]: (
      _: IFlightOriginDestinationSuggestState,
      action: any
    ): IFlightOriginDestinationSuggestState => {
      const flightOriginDestinationSuggestions: FlightOriginDestinationSuggestion[] =
        action.payload.map((suggestion: any) => {
          return new FlightOriginDestinationSuggestion(suggestion);
        });
      return { flightOriginDestinationSuggestions };
    },
  },
  INITIAL_STATE
);
